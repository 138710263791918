import * as React from "react";
import i18next from "i18next";

export const ErrorScreen = () => (
  <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
    <div className="box narrow-box">
      <div className="box-header box-header-danger">{i18next.t("error.screen.message")}</div>
      <div className="box-body">
        <p>
          {i18next.t("error.screen.error")}
        </p>
        <button className="btn btn-link p-0" onClick={() => window.location.reload()}>
          {i18next.t("error.screen.reloadApplication")}
        </button>
      </div>
    </div>
  </div>
);
