import {
  AsyncOperation,
  DropDownButton,
  IssueModal,
  Modal,
  getRoleDisplayNames
} from "@ist-group-private-scope/skolid-client-components";
import { History } from "history";
import * as _ from "lodash";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ErrorScreen } from "../../App/ErrorScreen";
import { LoadingScreen } from "../../App/LoadingScreen";
import { UnauthorizedScreen } from "../../App/UnauthorizedScreen";
import { InputControl } from "../../Common/InputControl";
import { useUserQuery } from "../../Common/queries";
import { isOrganizationIssuer } from "../../permissions";
import * as routes from "../../routes";
import { AppContext } from "../../types";
import { FormUser } from "../../types/formUser";
import * as gqlTypes from "../../types/graphql";
import { isFormUserValid } from "../../Utils/validation";
import { UserForm } from "../Common/UserForm";
import { convertToUpdateInput, updateUser } from "./api";
import { BlockModal } from "./BlockModal";
import { Trans} from "react-i18next";
import i18next from 'i18next';
import { settings } from "../../settings";
import { environmentSettings } from "@ist-group-private-scope/web-skolid";
import {
  DeleteUserByOrganizationAdmin,
  DeleteUserByOrganizationAdminVariables
} from "../../types/graphql";
import { useMutation} from "react-apollo";
import { Spinner } from "../../Common/Spinner";
import gql from "graphql-tag";

export const UserScreen = (
  props: RouteComponentProps<routes.UserRouteParams> & {
    appContext: AppContext;
  }
) => {
  const result = useUserQuery(props.match.params.userId);
  if (result.loading) {
    return <LoadingScreen />;
  }

  if (result.data && result.data.user && !result.data.user.issuableByUser) {
    return <UnauthorizedScreen />;
  }

  if (result.error) {
    return <ErrorScreen />;
  }

  if (!result.data!.user) {
    return <h1><Trans i18nKey="user.screen.accountDoesNotExist" /></h1>;
  }

  return (
    <EditUserForm
      apiUser={result.data!.user!}
      appContext={props.appContext}
      history={props.history}
      onRefresh={result.refetch}
    />
  );
};

interface EditUserFormProps {
  apiUser: gqlTypes.UserFragment;
  appContext: AppContext;
  history: History;
  onRefresh: () => void;
}

interface EditUserFormState {
  editing?: boolean;
  lastApiUser: gqlTypes.UserFragment;
  user: FormUser & { id: string };
  forceShowValidationErrors?: boolean;
  updateOperation: AsyncOperation<gqlTypes.UpdateUser>;
  showIssueModal?: boolean;
  showBlockModal?: boolean;
  showIssueLinkModal?: boolean;
  showDeleteModal?:boolean;
}

// tslint:disable-next-line:max-classes-per-file
export class EditUserForm extends React.PureComponent<EditUserFormProps, EditUserFormState> {
  public static getDerivedStateFromProps(
    nextProps: EditUserFormProps,
    prevState: EditUserFormState
  ): Partial<EditUserFormState> | null {
    if (nextProps.apiUser !== prevState.lastApiUser) {
      return {
        lastApiUser: nextProps.apiUser,
        user: convertToFormUser(nextProps.apiUser, nextProps.appContext)
      };
    } else {
      return null;
    }
  }

  constructor(props: EditUserFormProps) {
    super(props);
    this.state = {
      user: convertToFormUser(props.apiUser, props.appContext),
      lastApiUser: props.apiUser,
      updateOperation: {}
    };
  }

  public render() {
    const props = this.props;

    return (
      <>
        <div className="flex-fill">
          {this.renderHeader()}

          <div className="box box-body">
            {props.apiUser.blocked ? (
              <div className="alert alert-warning">
                <Trans i18nKey="user.screen.accountBlocked" />
              </div>
            ) : null}

            {props.apiUser.locked ? (
              <div className="alert alert-warning"><Trans i18nKey="user.screen.temporarilyLocked låst" /></div>
            ) : null}

            {props.apiUser.role !== gqlTypes.OrganizationRole.USER ? (
              <div className="alert alert-info">
                <Trans i18nKey="user.screen.userIs" /> {getRoleDisplayNames(props.apiUser.role)}
              </div>
            ) : null}

            <UserForm
              appContext={this.props.appContext}
              readonly={!this.state.editing}
              existingUserId={this.props.apiUser.id}
              user={this.state.user}
              forceShowValidationErrors={this.state.forceShowValidationErrors}
              disabled={this.state.updateOperation.running}
              onUpdate={partialUpdate =>
                this.setState(prevState => ({
                  user: { ...prevState.user, ...partialUpdate, id: prevState.user.id }
                }))
              }
            />

            {this.state.updateOperation.error ? (
              <div className="form-row">
                <div className="col">
                  <div className="alert alert-danger" role="alert">
                  <Trans i18nKey="user.screen.updateFailed" />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="form-row">
              <div className="col">
                <EditButtons
                  user={this.state.user}
                  appContext={this.props.appContext}
                  initialUser={this.props.apiUser}
                  disabled={this.state.updateOperation.running}
                  updating={this.state.updateOperation.running}
                  onUpdateClick={this.handleUpdateClick}
                  onIssueClick={this.handleIssueClick}
                  onReset={this.handleResetClick}
                  onBack={this.handleBackClick}
                  editing={this.state.editing}
                  onEdit={() => this.setState({ editing: true })}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.showIssueModal ? (
          <IssueModal
            loaLimit={this.props.appContext.organization.issueLoa}
            defaultLoa={this.props.appContext.organization.issueLoa}
            context={this.props.appContext}
            user={this.state.lastApiUser}
            onClose={() => {
              this.setState({ showIssueModal: false });
              this.props.onRefresh();
            }}
            skolidUrl={environmentSettings[settings.skolidEnvironment].url}
          />
        ) : null}

        {this.state.showBlockModal ? (
          <BlockModal
            appContext={this.props.appContext}
            apiUser={this.props.apiUser}
            onClose={() => {
              this.setState({ showBlockModal: false });
              this.props.onRefresh();
            }}
          />
        ) : null}

        {this.state.showIssueLinkModal ? (
          <IssueLinkModal
            userId={this.props.apiUser.id}
            onClose={() => this.setState({ showIssueLinkModal: false })}
          />
        ) : null}
        
        {this.state.showDeleteModal ? (
          <DeleteUserModal
            onCancel={() => this.setState({ showDeleteModal: false })}
            onDeleted={() => {this.props.history.push(routes.userLookupRoute )}}
            userId={this.props.apiUser.id}
          ></DeleteUserModal>
      ) : null}
      </>
    );
  }

  private renderHeader() {
    const disabled = this.state.updateOperation.running || this.state.editing;
    return (
      <div className="row no-gutters align-items-center">
        <h1 className="col m-0 mb-sm-content">
          {`${this.props.apiUser.firstName} ${this.props.apiUser.lastName}`.trim() ||
            i18next.t("user.screen.accountInformation")}
        </h1>
        <div className={"col-sm d-flex justify-content-md-end my-content"}>
          <button
            className="btn btn-primary"
            type="button"
            disabled={disabled}
            title={
              disabled
                ? "Spara eller återställ ändringarna i formuläret för att kunna utfärda kontot"
                : "Använd för att ge kontot till användaren."
            }
            onClick={this.handleIssueClick}
          >
            <Trans i18nKey="user.screen.issue" />
          </button>
          &nbsp; &nbsp;
          <div
            className="dropdown"
            title={
              disabled
                ? "Spara eller återställ ändringarna i formuläret för att kunna hantera kontot"
                : undefined
            }
          >
            <DropDownButton label={i18next.t("user.screen.more")} disabled={disabled}>
              {() => (
                <>
                  <button className="dropdown-item" type="button" onClick={this.handleBlockClick}>
                    <Trans i18nKey="user.screen.lock"/>
                  </button>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => this.setState({ showIssueLinkModal: true })}
                  >
                    <Trans i18nKey="user.screen.createIssueLink" />
                  </button>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => this.setState({ showDeleteModal: true })}
                  >
                    <Trans i18nKey="user.screen.deleteUser" />
                  </button>
                </>
              )}
            </DropDownButton>
          </div>
        </div>
      </div>
    );
  }

  public componentDidUpdate(prev: EditUserFormProps) {
    if (prev.apiUser.id !== this.props.apiUser.id) {
      this.setState({ editing: false });
    }
  }

  private handleBackClick = () => {
    this.props.history.push(routes.home);
  };

  private handleResetClick = () => {
    this.setState({
      user: convertToFormUser(this.state.lastApiUser, this.props.appContext),
      editing: false
    });
  };

  private handleBlockClick = async () => {
    this.setState(prevState => ({ showBlockModal: !prevState.showBlockModal }));
  };

  private handleIssueClick = async () => {
    this.setState(prevState => ({ showIssueModal: !prevState.showIssueModal }));
  };

  private handleUpdateClick = async () => {
    if (await isFormUserValid(this.state.user)) {
      this.setState({ updateOperation: { running: true } });
      try {
        const result = await updateUser(this.props.apiUser, this.state.user, this.props.appContext);
        this.setState({ updateOperation: { data: result.data! }, editing: false });
      } catch (error) {
        console.error("Failed to update user: ", error);
        this.setState({ updateOperation: { error } });
      }
    } else {
      this.setState({ forceShowValidationErrors: true });
    }
  };
}

const IssueLinkModal = ({ userId, onClose }: { userId: string; onClose: () => void }) => {
  const url = window.location.origin + routes.generateMinimalUserPath({ userId });
  const urlElRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal header={i18next.t("user.screen.issuerLink")} onClose={onClose}>
      {closeModal => (
        <div className="modal-body">
          <p>
            <Trans i18nKey="user.screen.linkInformation" />
          </p>
          <InputControl value={url} readonly inputRef={urlElRef} />
          <br />
          <button
            className="btn btn-primary"
            onClick={() => {
              const input = urlElRef.current!;
              input.select();
              input.setSelectionRange(0, 9999);
              document.execCommand("copy");
            }}
          >
            <Trans i18nKey="user.screen.copy" />
          </button>
        </div>
      )}
    </Modal>
  );
};

const EditButtons = (props: {
  user: FormUser;
  initialUser: gqlTypes.UserFragment;
  disabled?: boolean;
  editing?: boolean;
  updating?: boolean;
  appContext: AppContext;
  onUpdateClick: () => void;
  onIssueClick: () => void;
  onReset: () => void;
  onBack: () => void;
  onEdit: () => void;
}) => {
  return (
    <div className="d-flex mt-4 justify-content-end">
      {!props.editing ? (
        isOrganizationIssuer(props.appContext) ? (
          <button
            className="btn btn-primary"
            type="submit"
            disabled={props.disabled}
            onClick={props.onEdit}
          >
            <Trans i18nKey="user.screen.edit" />
          </button>
        ) : null
      ) : (
        <div>
          <button
            className="btn btn-secondary"
            type="submit"
            disabled={props.disabled}
            onClick={props.onReset}
            title={i18next.t("user.screen.undoAllChangesToTheForm")}
          >
            <Trans i18nKey="user.screen.cancel" />
          </button>
          &nbsp; &nbsp;
          <button
            className="btn btn-primary"
            type="submit"
            disabled={
              props.disabled || !hasUserChanged(props.user, props.initialUser, props.appContext)
            }
            onClick={props.onUpdateClick}
          >
            {props.updating ? i18next.t("user.screen.saves") : i18next.t("user.screen.save")}
          </button>
        </div>
      )}
    </div>
  );
};

function hasUserChanged(
  user: FormUser,
  initialUser: gqlTypes.UserFragment,
  appContext: AppContext
) {
  const initialFormUser = convertToFormUser(initialUser, appContext);
  const initialUpdateUser = convertToUpdateInput(initialFormUser, appContext.organization.id);
  return !_.isEqual(initialUpdateUser, convertToUpdateInput(user, appContext.organization.id));
}

export function convertToFormUser(
  user: gqlTypes.UserFragment,
  appContext: AppContext
): FormUser & { id: string } {
  return {
    id: user.id,
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    loa: user.loa,
    nationalId: user.nationalId,
    tfNumber: user.tfNumber,
    username: user.username,
    phoneNumbers: user.phoneNumbers
      .concat([])
      .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)),
    emails: user.emails,
    lastActivity: user.lastActivity,
    addresses: user.addresses
      .concat([])
      .sort(a => (a.type === gqlTypes.AddressType.OFFICIAL ? -1 : a.primary ? -1 : 0)),
    externalLogins: user.externalLogins
  };
}

const DeleteUserModal = (props: {
  userId: string;
  onCancel: () => void;
  onDeleted: () => void;
}) => {
  const [deleteUserByOrganizationAdmin, deleteUserByOrganizationAdminMutation] = useMutation<DeleteUserByOrganizationAdmin, DeleteUserByOrganizationAdminVariables > (
  gql`
      mutation DeleteUserByOrganizationAdmin($userId: String!) {
        deleteUserByOrganizationAdmin(id: $userId)
      }
    `,
    {
      variables: {
        userId: props.userId,
      },
      onCompleted: props.onDeleted,
    }
  );

  const disabled = deleteUserByOrganizationAdminMutation.loading;
  return (
    <Modal onClose={props.onCancel} header={i18next.t("user.screen.deleteUserHeader")} small>
      {(close) => (
        <>
          <div className="modal-body">
            {deleteUserByOrganizationAdminMutation.error ? (
              <div className="alert alert-danger">Failed to delete user</div>
            ) : (
              <Trans i18nKey="user.screen.areYouSure" /> 
            )}
          </div>
          <div className="modal-footer d-flex justify-content-between align-items-center">
            <button
              className="btn btn-secondary"
              onClick={close}
              disabled={disabled}
            >
              <Trans i18nKey="user.screen.cancel" />
            </button>

            {deleteUserByOrganizationAdminMutation.loading ? <Spinner /> : null}

            <button
              className="btn btn-dangyarn starter"
              onClick={() => deleteUserByOrganizationAdmin()}
              disabled={disabled}
            >
              <Trans i18nKey="user.screen.delete" />
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};
