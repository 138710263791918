import {
  removeTypename,
  toAddressInput,
  toEmailInput,
  toPhoneNumberInput
} from "@ist-group-private-scope/skolid-client-components";
import { ExecutionResult } from "graphql";
import gql from "graphql-tag";
import { UserFragmentGql } from "../../fragments";
import { AppContext } from "../../types";
import { FormUser } from "../../types/formUser";
import {
  UpdateUser,
  UpdateUserInput,
  UpdateUserVariables,
  UserFragment
} from "../../types/graphql";

const updateUserGql = gql`
  mutation UpdateUser(
    $userId: String!
    $user: UpdateUserInput!
    $options: UpdateUserOptionsInput!
  ) {
    updateUser(userId: $userId, user: $user, options: $options) {
      ...UserFragment
    }
  }

  ${UserFragmentGql}
`;

export async function updateUser(
  originalUser: UserFragment,
  user: FormUser,
  appContext: AppContext
) {
  const updateVariables: UpdateUserVariables = {
    userId: originalUser.id,
    user: convertToUpdateInput(user, appContext.organization.id),
    options: {}
  };

  return appContext.client.mutate({
    mutation: updateUserGql,
    variables: updateVariables
  }) as ExecutionResult<UpdateUser>;
}

export function convertToUpdateInput(user: FormUser, organizationId: string): UpdateUserInput {
  return removeTypename<UpdateUserInput>({
    nationalId: user.nationalId,
    emails: user.emails.map(toEmailInput),
    tfNumber: user.tfNumber,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumbers: user.phoneNumbers.map(toPhoneNumberInput),
    addresses: user.addresses.map(toAddressInput),
  });
}
