import {
  ActivationRequestQrReader,
  AsyncOperation,
  issueUserToActivationRequest
} from "@ist-group-private-scope/skolid-client-components";
import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { NationalIdOrTfNumber } from "../../Common/NationalIdOrOrg";
import { Spinner } from "../../Common/Spinner";
import { AppContext } from "../../types";
import { Loa } from "../../types/graphql";
import { Trans} from "react-i18next";

interface IssueTarget {
  id: string;
  name: string | null;
  nationalId: string | null;
  organizationUserId?: { value: string } | null;
}

interface QrIssueModalProps {
  target?: IssueTarget;
  appContext: AppContext;
  onCancel: () => void;
  onComplete: () => void;
  loa: Loa;
  refetchQueries?: string[];
}

export interface QrIssueModalState {
  qrCode?: string;
  issueOperation: AsyncOperation<any>;
}

export class QrIssueModal extends React.PureComponent<QrIssueModalProps, QrIssueModalState> {
  public state: QrIssueModalState = { issueOperation: {} };

  public render() {
    const props = this.props;

    if (!props.target) {
      return <Modal isOpen={false} />;
    }

    const target = props.target;

    return (
      <Modal isOpen onOpened={() => this.setState({ qrCode: undefined })} centered>
        <ModalHeader toggle={props.onCancel}><Trans i18nKey="qr.issue.modal.issueAccount" /></ModalHeader>
        <ModalBody className="justify-content-center">
          <p>
            <strong>{target.name}</strong>
            <br />
            <strong>
              <NationalIdOrTfNumber {...target} country={this.props.appContext.country} />
            </strong>
          </p>
          {this.state.qrCode ? (
            <>
              <p>
                <Trans i18nKey="qr.issue.modal.qrCodeScanned" />{" "}
              </p>
            </>
          ) : (
            <ActivationRequestQrReader
              disabled={this.state.issueOperation.error}
              onActivationRequestId={id => this.setState({ qrCode: id })}
            />
          )}
          {this.state.issueOperation.error ? (
            <div className="alert alert-danger"><Trans i18nKey="qr.issue.modal.failedToIssue" /></div>
          ) : null}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-secondary"
            disabled={this.state.issueOperation.running}
            onClick={
              this.state.qrCode
                ? () =>
                    this.setState({
                      qrCode: undefined,
                      issueOperation: {}
                    })
                : props.onCancel
            }
          >
            {this.state.qrCode ? <Trans i18nKey="qr.issue.modal.start" /> : <Trans i18nKey="qr.issue.modal.cancel" />}
          </button>

          {this.state.issueOperation.running ? <Spinner /> : null}

          <button
            className="btn btn-primary"
            disabled={!this.state.qrCode || this.state.issueOperation.running}
            onClick={async () => {
              this.setState({ issueOperation: { running: true } });
              try {
                await issueUserToActivationRequest(
                  this.props.target!.id,
                  this.props.loa,
                  this.state.qrCode!,
                  this.props.appContext,
                  this.props.refetchQueries
                );
                this.setState({ issueOperation: { data: true } });
                this.props.onComplete();
              } catch (error) {
                this.setState({
                  issueOperation: { error: <Trans i18nKey="qr.issue.modal.failedToIssueAccount" /> }
                });
              }
            }}
          >
            <Trans i18nKey="qr.issue.modal.issue" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
