import * as gqlTypes from "../../types/graphql";
import { settings } from "../../settings";
import i18next from "i18next";

export interface ImportUser {
  importIndex: number;
  nationalId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password?: string;
}

export interface ImportUserTestResult extends gqlTypes.ImportUserResultFragment {
  originalImportUser: ImportUser;
}

export interface ImportUserResult extends gqlTypes.ImportAndIssueUserResultFragment {
  originalTestResult: ImportUserTestResult;
}

export const columnConfiguration = {
  nationalId: { name: "types.socialSecurityNumber", optional: false },
  firstName: { name: "types.firstName", optional: false },
  lastName: { name: "types.surname", optional: false },
  email: { name: "types.email", optional: true },
  phone: { name: "types.phoneNumber", optional: true },
  ...(settings.demoFeatures ? { password: { name: "types.password", optional: true } } : null)
};

const importActionMessages = {
  [gqlTypes.ImportUserAction.CREATE]: "types.created",
  [gqlTypes.ImportUserAction.NOT_MODIFIED]: "types.unchanged",
  [gqlTypes.ImportUserAction.ERROR]: "types.inaccurate",
  [gqlTypes.ImportUserAction.UPDATE]: "types.updated",
  [gqlTypes.ImportUserAction.IGNORED]: "types.ignored"
};

const importedActionMessages = {
  [gqlTypes.ImportUserAction.CREATE]: "types.create",
  [gqlTypes.ImportUserAction.NOT_MODIFIED]: "types.unchanged",
  [gqlTypes.ImportUserAction.ERROR]: "types.inaccurate",
  [gqlTypes.ImportUserAction.UPDATE]: "types.revised",
  [gqlTypes.ImportUserAction.IGNORED]: "types.ignore"
};

const fieldValidationErrorMessage = {
  [gqlTypes.ImportUserFieldStatus.CONFLICT]: "types.conflict",
  [gqlTypes.ImportUserFieldStatus.MISSING]: "types.missing",
  [gqlTypes.ImportUserFieldStatus.INVALID]: "types.invalid",
  [gqlTypes.ImportUserFieldStatus.VALID]: null
};

export function isValidFieldResult(result: gqlTypes.ImportUserFieldFragment) {
  return result.status === gqlTypes.ImportUserFieldStatus.VALID;
}

export function getImportActionMessages(action:gqlTypes.ImportUserAction) {
  return i18next.t(importActionMessages[action] as string);
}

export function getImportedActionMessages(action:gqlTypes.ImportUserAction) {
  return i18next.t(importedActionMessages[action] as string);
}

export function getFieldValidationErrorMessage(status:gqlTypes.ImportUserFieldStatus) {
  return i18next.t(fieldValidationErrorMessage[status] as string);
}