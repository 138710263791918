import * as React from "react";
import { debounceTimeInMs } from "../constants";

export const useDebounced = (val: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(val);

  React.useEffect(() => {
    const timoutId = setTimeout(() => {
      setDebouncedValue(val);
    }, delay);

    return () => {
      clearTimeout(timoutId);
    };
  }, [val, delay]);

  return debouncedValue;
};

export const useSearchString = () => {
  const [searchString, setSearchString] = React.useState(
    (window.history.state && window.history.state.search) || ""
  );

  const debouncedSearchString = useDebounced(searchString, debounceTimeInMs);

  React.useEffect(() => {
    window.history.replaceState({ search: searchString }, "", null);
  }, [searchString]);

  return { searchString, debouncedSearchString, setSearchString };
};

export interface PaginationState {
  before?: string | null;
  after?: string | null;
}

export interface UpdatePaginationStateActions {
  after: (cursor: string) => void;
  before: (cursor: string) => void;
  reset: () => void;
}

export const usePagination = (resetWhenChanged?: any[]) => {
  const [state, setPagination] = React.useState<PaginationState>(() => ({
    after: null,
    before: null
  }));
  const actions: UpdatePaginationStateActions = {
    after: (cursor: string) => setPagination({ after: cursor }),
    before: (cursor: string) => setPagination({ before: cursor }),
    reset: () => setPagination({})
  };

  React.useEffect(actions.reset, resetWhenChanged);

  return { state, actions };
};

export function useDirectEffect<TState>(current: TState, effect: (prev: TState) => void) {
  const [prev, setPrev] = React.useState(current);

  if (prev !== current) {
    setPrev(current);
    effect(prev);
  }
}
