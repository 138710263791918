import {
  AsyncOperation,
  IssueByQrCode,
  IssueResult
} from "@ist-group-private-scope/skolid-client-components";
import { ApolloError } from "apollo-client";
import * as React from "react";
import { Spinner } from "../../Common/Spinner";
import { CreateAndIssueUser } from "../../types/graphql";
import { issuedWithActivationCode } from "../types";
import { Trans} from "react-i18next";
import { settings } from "../../settings";
import { environmentSettings } from "@ist-group-private-scope/web-skolid";

interface IssuingUserStatusProps {
  operation: AsyncOperation<CreateAndIssueUser | IssueByQrCode, ApolloError | undefined>;
  onDone: () => void;
  onIssueNext: () => void;
  onBack: () => void;
}

export class IssuingUserStatus extends React.PureComponent<IssuingUserStatusProps> {
  public render() {
    const props = this.props;
    const operation = this.props.operation;

    return (
      <>
        <div className="modal-body">
          {operation.running ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : null}

          {operation.data ? (
            issuedWithActivationCode(operation.data) ? (
              <IssueResult
                activationCode={operation.data!.createAndIssueUser!.activationCode}
                activationCodeExpiresAt={
                  operation.data!.createAndIssueUser!.activationCodeExpiresAt
                }
                user={operation.data!.createAndIssueUser!.user!}
                skolidUrl={environmentSettings[settings.skolidEnvironment].url}
              />
            ) : (
              <p><Trans i18nKey="issue.user.status.accountIssued" /></p>
            )
          ) : null}

          {operation.error ? (
            <>
              <div className="alert alert-danger" role="alert">
                {operation.error.graphQLErrors &&
                operation.error.graphQLErrors.some(x => (x as any).code === "not_authorized")
                  ? <Trans i18nKey="issue.user.status.doNotHavePermissions" />
                  : <Trans i18nKey="issue.user.status.failed" />}
              </div>
            </>
          ) : null}
        </div>

        <div className="modal-footer text-right">
          {operation.error ? (
            <button className="btn btn-secondary" onClick={props.onBack}>
              <Trans i18nKey="issue.user.status.back" />
            </button>
          ) : null}
          <button
            className="btn btn-secondary"
            onClick={props.onIssueNext}
            disabled={operation.running}
          >
            <Trans i18nKey="issue.user.status.issueAnother" />
          </button>
          <button className="btn btn-primary" onClick={props.onDone} disabled={operation.running}>
          <Trans i18nKey="issue.user.status.okay" />
          </button>
        </div>
      </>
    );
  }
}
