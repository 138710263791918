import * as React from "react";
import gql from "graphql-tag";
import {
  UserFragment,
  BlockUserVariables,
  BlockUser,
  GenerateBlockUserContract
} from "../../types/graphql";
import { UserFragmentGql } from "../../fragments";
import { AppContext } from "../../types";
import { ExecutionResult } from "graphql";
import { AsyncOperation, Modal } from "@ist-group-private-scope/skolid-client-components";
import { Trans} from "react-i18next";
import i18next from 'i18next';

interface ChangeLoaModalProps {
  onClose: () => void;
  apiUser: UserFragment;
  appContext: AppContext;
}

interface ChangeLoaModalState {
  reason: string;
  blockOperation: AsyncOperation<void>;
}

export class BlockModal extends React.PureComponent<ChangeLoaModalProps, ChangeLoaModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      reason: "",
      blockOperation: {}
    };
  }

  public render() {
    const props = this.props;

    return (
      <Modal small header={i18next.t("block.modal.blockAccount")} onClose={props.onClose}>
        {closeModal => (
          <>
            {!this.state.blockOperation.data && !this.state.blockOperation.error
              ? this.renderBlockPanel(closeModal)
              : null}

            {this.state.blockOperation.data ? this.renderSuccessResult(closeModal) : null}

            {this.state.blockOperation.error ? this.renderErrorResult(closeModal) : null}
          </>
        )}
      </Modal>
    );
  }

  private renderBlockPanel(closeModal: () => void) {
    return (
      <>
        <div className="modal-body">
          <p>
            <Trans i18nKey="block.modal.accountIsLockedInformation" />
          </p>
          <div className="form-group">
            <textarea
              placeholder={i18next.t("block.modal.indicateReason")}
              className="form-control"
              value={this.state.reason}
              disabled={this.state.blockOperation.running}
              onChange={ev => this.setState({ reason: ev.currentTarget.value })}
            />
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <button
            className="btn btn-secondary"
            disabled={this.state.blockOperation.running}
            onClick={closeModal}
          >
            <Trans i18nKey="block.modal.cancel" />
          </button>
          &nbsp;&nbsp;
          <button
            className="btn btn-primary"
            onClick={() => this.handleBlockClick(closeModal)}
            disabled={!this.state.reason || this.state.blockOperation.running}
          >
            {this.state.blockOperation.running ? i18next.t("block.modal.blocks") : i18next.t("block.modal.lock")}
          </button>
        </div>
      </>
    );
  }

  private renderSuccessResult(closeModal: () => void) {
    return (
      <>
        <div className="modal-body"><Trans i18nKey="block.modal.accountIsBlocked" /></div>
        <div className="modal-footer text-right">
          <button
            className="btn btn-primary"
            disabled={this.state.blockOperation.running}
            onClick={closeModal}
          >
            <Trans i18nKey="block.modal.okay" />
          </button>
        </div>
      </>
    );
  }

  private renderErrorResult(closeModal: () => void) {
    return (
      <>
        <div className="modal-body">
          <div className="alert alert-danger">Misslyckades att spärra kontot.</div>
        </div>
        <div className="modal-footer text-right">
          <button
            className="btn btn-primary"
            disabled={this.state.blockOperation.running}
            onClick={closeModal}
          >
            <Trans i18nKey="block.modal.okay" />
          </button>
        </div>
      </>
    );
  }

  private handleBlockClick = async (closeModal: () => void) => {
    this.setState(() => ({
      blockOperation: { running: true }
    }));

    try {
      const minDelayPromise = new Promise(resolve => setTimeout(resolve, 1000));

      await blockUser(this.props.apiUser, this.state.reason, this.props.appContext);

      await minDelayPromise;
      closeModal();
    } catch (error) {
      this.setState(() => ({ blockOperation: { error } }));
      console.error("Issue user error", error);
    }
  };
}

const blockUserGql = gql`
  mutation BlockUser($userId: String!, $reason: String!, $signature: String!) {
    blockUser(userId: $userId, reason: $reason, signature: $signature) {
      ...UserFragment
    }
  }

  ${UserFragmentGql}
`;

const generateBlockUserContractGql = gql`
  mutation GenerateBlockUserContract($userId: String!, $reason: String!) {
    generateBlockUserContract(userId: $userId, reason: $reason) {
      data
      text
    }
  }
`;

export async function blockUser(
  apiUser: UserFragment,
  reason: string,
  { signManager, client, organization }: AppContext
): Promise<void> {
  const args = {
    userId: apiUser.id,
    reason
  };

  const contractResponse: ExecutionResult<GenerateBlockUserContract> = await client.mutate({
    mutation: generateBlockUserContractGql,
    variables: args
  });

  const contract = contractResponse.data!.generateBlockUserContract!;
  const signature = await signManager.signJson(contract.text, contract.data);

  const variables: BlockUserVariables = {
    ...args,
    signature
  };

  return client.mutate<BlockUser>({
    mutation: blockUserGql,
    variables
  }) as any;
}
