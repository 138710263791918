import * as React from "react";
import { UserForm } from "../Users/Common/UserForm";
import { useAppContext } from "./GlobalContext";
import { useUserQuery } from "../Common/queries";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import * as routes from "../routes";
import { LoadingScreen } from "./LoadingScreen";
import { ErrorScreen } from "./ErrorScreen";
import { convertToFormUser } from "../Users/Edit/UserScreen";
import { IssueModal } from "@ist-group-private-scope/skolid-client-components";
import { UnauthorizedScreen } from "../App/UnauthorizedScreen";
import i18next from "i18next";
import { settings } from "../settings";
import { environmentSettings } from "@ist-group-private-scope/web-skolid";

export const MinimalApp = () => {
  return (
    <Switch>
      <Route path={routes.minimalUserRoute} component={MinimalUserView} />
    </Switch>
  );
};

const MinimalUserView = (props: RouteComponentProps<routes.MinimalUserRouteParams>) => {
  const appContext = useAppContext();
  const userQuery = useUserQuery(props.match.params.userId);
  const [showIssueModal, setShowIssueModal] = React.useState(false);

  if (userQuery.loading) {
    return <LoadingScreen />;
  }

  if (userQuery.data && userQuery.data.user && !userQuery.data.user.issuableByUser) {
    return <UnauthorizedScreen />;
  }

  if (userQuery.error) {
    return <ErrorScreen />;
  }

  const user = userQuery.data && userQuery.data.user;
  if (!user) {
    return <p>{i18next.t("minimal.app.userNotFound")}</p>;
  }

  const formUser = convertToFormUser(user, appContext);

  const disabled = false;
  return (
    <>
      <div className="flex-fill">
        <div className="row no-gutters align-items-center">
          <h1 className="col m-0 mb-sm-content">
            {`${formUser.firstName} ${formUser.lastName}`.trim() || i18next.t("minimal.app.accountInformation")}
          </h1>
          <div className={"col-sm d-flex justify-content-md-end my-content"}>
            <button
              className="btn btn-primary"
              type="button"
              disabled={disabled || !user.issuableByUser}
              title={i18next.t("minimal.app.used")}
              onClick={() => setShowIssueModal(true)}
            >
              {i18next.t("minimal.app.issue")}
            </button>
          </div>
        </div>
        {!user.issuableByUser ? (
          <div className="alert alert-warning">
            {i18next.t("minimal.app.youLackAuthority")}
          </div>
        ) : null}
        <div className="box box-body">
          <UserForm appContext={appContext} readonly existingUserId={user.id} user={formUser} />
        </div>
      </div>
      {showIssueModal ? (
        <IssueModal
          loaLimit={appContext.organization.issueLoa}
          defaultLoa={appContext.organization.issueLoa}
          context={appContext}
          user={user}
          onClose={() => setShowIssueModal(false)}
          skolidUrl={environmentSettings[settings.skolidEnvironment].url}
        />
      ) : null}
    </>
  );
};
