import * as React from "react";
import SpinkitSpinner from "react-spinkit";
import { blue } from "../constants";

export const Spinner = (props: { fadeIn?: boolean; className?: string }) => (
  <SpinkitSpinner
    fadeIn={props.fadeIn ? undefined : "none"}
    color={blue}
    className={props.className}
  />
);
