import {
  ActivationRequestIssueMethod,
  AsyncOperation,
  IssueMethodSelect,
  isValidIssueMethodForUser
} from "@ist-group-private-scope/skolid-client-components";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Spinner } from "../../Common/Spinner";
import { AppContext } from "../../types";
import { convertFormUserToBasicUserWithoutId, FormUser } from "../../types/formUser";
import * as gqlTypes from "../../types/graphql";
import { Trans} from "react-i18next";

export interface IssueUserFormProps {
  appContext: AppContext;
  issueMethod: gqlTypes.IssueMethod | null;
  user: FormUser;
  loa: gqlTypes.Loa;
  onIssueMethodChanged: (newMethod: gqlTypes.IssueMethod) => void;
  onBack: () => void;
  routing: RouteComponentProps<any>;
  onIssue: () => void;
  onCreate: () => void;
  issuingOperation: AsyncOperation<any>;
}

export class IssueUserForm extends React.PureComponent<IssueUserFormProps> {
  public render() {
    const props = this.props;
    const disabled = this.props.issuingOperation.running;

    const basicUserWithoutId = convertFormUserToBasicUserWithoutId(
      this.props.user,
      this.props.appContext
    );
    return (
      <>
        <div className="modal-body">
          <h4><Trans i18nKey="issue.user.form.selectIssuanceMethod" /></h4>
          <IssueMethodSelect
            disabledPredicate={method =>
              disabled || !isValidIssueMethodForUser(method, this.props.loa, basicUserWithoutId)
            }
            issueMethod={this.props.issueMethod}
            onIssueMethodChanged={this.props.onIssueMethodChanged}
          />
        </div>
        <div className="modal-footer d-flex justify-content-between align-items-center">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            disabled={disabled}
            onClick={props.onBack}
          >
            <Trans i18nKey="issue.user.form.start" />
          </button>

          {props.issuingOperation.running ? <Spinner /> : null}

          <div>
            <button
              type="button"
              className="btn btn-secondary"
              disabled={disabled}
              onClick={props.onCreate}
            >
              <Trans i18nKey="issue.user.form.createWithoutIssuing" />
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-primary"
              disabled={props.issueMethod === null || disabled}
              onClick={props.onIssue}
            >
              {this.props.issueMethod === ActivationRequestIssueMethod ? <Trans i18nKey="issue.user.next" /> : <Trans i18nKey="issue.user.issue" />}
            </button>
          </div>
        </div>
      </>
    );
  }
}
