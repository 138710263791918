import * as React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface ErrorModalProps {
  show?: boolean;
  errorMessage?: React.ReactNode;
  errorTitle?: string;
  onClose: () => void;
}

export const ErrorModal = (props: ErrorModalProps) => {
  if (!props.show) {
    return <Modal isOpen={false} />;
  }

  return (
    <>
      <Modal isOpen centered>
        <ModalHeader toggle={props.onClose} className="modal-header-danger">
          {props.errorTitle}
        </ModalHeader>
        <ModalBody className="justify-content-center">{props.errorMessage}</ModalBody>
      </Modal>
    </>
  );
};
