import gql from "graphql-tag";

export const removeGroupMembersGql = gql`
  mutation RemoveGroupMembers(
    $organizationId: String!
    $edges: [GroupMemberEdgeIdentifierInput!]!
  ) {
    removeGroupMembers(organizationId: $organizationId, edges: $edges) {
      dummy
    }
  }
`;
