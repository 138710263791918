import * as React from "react";
import { AppContext } from "../types";

// Must always be initialized with a state
export const ReactAppContext = React.createContext<AppContext>(null as any);

export const useAppContext = () => {
  return React.useContext(ReactAppContext);
};

export type WithAppContext<TProps> = { context: AppContext } & TProps;

export function withGlobalContext<TProps extends {}>(
  component: React.ComponentType<WithAppContext<TProps>>
): React.ComponentType<TProps> {
  const Component: any = component;

  return (props: TProps) => (
    <ReactAppContext.Consumer>
      {globalContext => <Component {...{ context: globalContext, ...(props as any) }} />}
    </ReactAppContext.Consumer>
  );
}
