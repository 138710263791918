import i18next from 'i18next';

export interface ImportParseResult<T> {
  error?: string;
  results: T[];
}

export interface HeaderValues {
  name: string;
  optional?: boolean;
}

export type ImportParseResultOf<T> = ImportParseResult<
  { [P in keyof T]: string } & { importIndex: number }
>;

export function parseImport<T extends { [key: string]: HeaderValues }>(
  input: string,
  headerNames: T
): ImportParseResultOf<T> {
  const results: ({ [P in keyof T]: string } & { importIndex: number })[] = [];

  const rows = input.split("\n");

  // Calculate column mappings
  const headerColumns = rows[0].split("\t").map(colname => colname.replace("*", ""));
  const colnameMappings: { [index: string]: number } = {};
  for (let i = 0; i < headerColumns.length; i++) {
    colnameMappings[headerColumns[i]] = i;
  }

  // Test if any columns are missing
  const missingFields: string[] = [];
  for (const header of Object.values(headerNames)) {
    if (colnameMappings[i18next.t(header.name)] === undefined) {
      if (!header.optional) {
        missingFields.push(i18next.t(header.name));
      }
    }
  }

  if (missingFields.length > 0) {
    return { error: i18next.t("parse.import.columnsAreMissing") + " " + missingFields.join(", "), results: [] };
  }

  for (let index = 1; index < rows.length; index++) {
    const row = rows[index].trim();
    if (row) {
      const rowValues = rows[index].split("\t");
      const result: { [P in keyof T]: string } & { importIndex: number } = Object.keys(
        headerNames
      ).reduce<any>((dic, x) => {
        dic[x] = rowValues[colnameMappings[i18next.t(headerNames[x].name)]];
        return dic;
      }, {});

      result.importIndex = index;

      results.push(result);
    }
  }

  return { results };
}
