import { NationalId, OptionUser, SelectUser } from "@ist-group-private-scope/skolid-client-components";
import gql from "graphql-tag";
import React from "react";
import { Mutation } from "react-apollo";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Spinner } from "../../Common/Spinner";
import { AppContext } from "../../types";
import { AddGroupMembers, AddGroupMembersVariables, GroupMemberRole } from "../../types/graphql";
import { Group } from "../types";
import i18next from 'i18next';
import { Trans } from "react-i18next"

interface AddGroupMembersModalProps {
  appContext: AppContext;
  group: Group;
  isOpen?: boolean;
  onToggle: () => void;
  refetchQueries: string[];
}

interface AddGroupMembersModalState {
  searchText?: string;
  selectedUsers: OptionUser[];
  selectedRole: GroupMemberRole | "";
}

export class AddGroupMembersModal extends React.PureComponent<
  AddGroupMembersModalProps,
  AddGroupMembersModalState
> {
  private static cleanState: AddGroupMembersModalState = {
    selectedUsers: [],
    selectedRole: "",
    searchText: ""
  };

  public state: AddGroupMembersModalState = AddGroupMembersModal.cleanState;

  public render() {
    const props = this.props;
    const organizationId = this.props.appContext.organization.id;

    return (
      <Mutation<AddGroupMembers, AddGroupMembersVariables> mutation={addUsersGql}>
        {(mutate, result) => (
          <Modal
            isOpen={props.isOpen}
            toggle={props.onToggle}
            onOpened={() => this.setState(AddGroupMembersModal.cleanState)}
            backdrop="static"
          >
            <ModalHeader>{i18next.t("add.group.members.modal.addUsersToGroup")}</ModalHeader>
            <ModalBody>
              <p>
                <Trans i18nKey="add.group.members.modal.addPermission" values={{name:props.group.name}} />
              </p>

              <div className="form-group">
                <select
                  className="form-control"
                  disabled={result.loading}
                  value={this.state.selectedRole}
                  onChange={ev =>
                    this.setState({
                      selectedRole: ev.target.value as GroupMemberRole
                    })
                  }
                >
                  <option value="">{i18next.t('add.group.members.modal.select.role')}</option>
                  <option value={GroupMemberRole.ADMIN}>{i18next.t('add.group.members.modal.select.groupAdministrator')}</option>
                  <option value={GroupMemberRole.ISSUER}>{i18next.t('add.group.members.modal.select.groupIssuer')}</option>
                </select>
              </div>
              <dl className="alert alert-info">
                <dt>{i18next.t("add.group.members.modal.groupAdministrators")}</dt>
                <dd>{i18next.t("add.group.members.modal.issueAccountsAddIssuersToGroup")}</dd>
                <dt>{i18next.t("add.group.members.modal.groupIssuer")}</dt>
                <dd>{i18next.t("add.group.members.modal.canIssueAccountToUserInGroup")}</dd>
              </dl>
              <div className="form-group">
                <SelectUser
                  organizationId={organizationId}
                  context={this.props.appContext}
                  disabled={result.loading}
                  placeholder={i18next.t('add.group.members.modal.socialSecurityNumber')}
                  searchText={this.state.searchText || ""}
                  onSelected={user =>
                    this.setState(prevState => ({
                      selectedUsers: prevState.selectedUsers.concat([user])
                    }))
                  }
                  onSearchTextChange={text => this.setState({ searchText: text })}
                />
              </div>
              <table className="table my-2 mx-1">
                <tbody>
                  {this.state.selectedUsers.map(user => (
                    <tr key={user.id} className="py-2">
                      <>
                        <td>
                          {user.name}
                          <br />
                          <span className="text-black-50">
                            <NationalId
                              nationalId={user.nationalId}
                              country={this.props.appContext.country}
                            />
                          </span>
                        </td>
                        <td className="text-right align-middle">
                          <button
                            disabled={result.loading}
                            className="btn btn-link p-0"
                            onClick={() =>
                              this.setState({
                                selectedUsers: this.state.selectedUsers.filter(
                                  x => x.id !== user.id
                                )
                              })
                            }
                          >
                            {i18next.t("add.group.members.modal.remove")}
                          </button>
                        </td>
                      </>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between align-items-center">
              <button
                className="btn btn-secondary"
                onClick={props.onToggle}
                disabled={result.loading}
              >
                {i18next.t("add.group.members.modal.cancel")}
              </button>
              {result.loading && <Spinner />}
              <button
                className="btn btn-primary"
                disabled={
                  result.loading ||
                  this.state.selectedUsers.length === 0 ||
                  this.state.selectedRole === ""
                }
                onClick={async () => {
                  await mutate({
                    variables: {
                      organizationId,
                      userEdges: this.state.selectedUsers.map(user => ({
                        role: this.state.selectedRole as GroupMemberRole,
                        userId: user.id,
                        groupId: props.group.id,
                        confirmedAt: new Date()
                      }))
                    },
                    refetchQueries: props.refetchQueries,
                    awaitRefetchQueries: true
                  });

                  props.onToggle();
                }}
              >
                {i18next.t("add.group.members.modal.add")}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </Mutation>
    );
  }
}

const addUsersGql = gql`
  mutation AddGroupMembers($organizationId: String!, $userEdges: [GroupMemberEdgeInput!]!) {
    addGroupMembers(organizationId: $organizationId, edges: $userEdges) {
      dummy
    }
  }
`;
