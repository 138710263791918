import { ExecutionResult } from "graphql";
import gql from "graphql-tag";
import { AppContext } from "../../types";
import {
  GenerateImportAndIssueUsersContract,
  ImportAndIssueUsers,
  ImportAndIssueUsersVariables,
  ImportUserInput,
  IssueMethod,
  Loa,
  TestImportUsers,
  TestImportUsersVariables
} from "../../types/graphql";
import { ImportUser, ImportUserTestResult } from "./types";

const testImportGql = gql`
  mutation TestImportUsers(
    $organizationId: String!
    $users: [ImportUserInput!]!
    $options: ImportAndIssueUsersOptionsInput!
  ) {
    testImportAndIssueUsers(organizationId: $organizationId, users: $users, options: $options) {
      entries {
        ...ImportUserResultFragment
      }
    }
  }

  fragment ImportUserFieldFragment on ImportUserField {
    normalizedValue
    status
  }

  fragment ImportUserFragment on ImportUser {
    valid
    nationalId {
      ...ImportUserFieldFragment
    }
    email {
      ...ImportUserFieldFragment
    }
    firstName {
      ...ImportUserFieldFragment
    }
    lastName {
      ...ImportUserFieldFragment
    }
    phoneNumber {
      ...ImportUserFieldFragment
    }
  }

  fragment ImportUserResultFragment on ImportUserResult {
    action
    importUser {
      ...ImportUserFragment
    }
  }
`;

export async function importUsersTest(
  organizationId: string,
  users: ImportUser[],
  issueMethod: IssueMethod,
  loa: Loa,
  { client, organization, country }: AppContext
) {
  const variables: TestImportUsersVariables = {
    organizationId,
    users: users.map<ImportUserInput>(convertUserToImportUserInput),
    options: {
      issueMethod,
      loa
    }
  };

  const minDelayPromise = new Promise(resolve => setTimeout(resolve, 1000));

  const importTestResult = (await client.mutate({
    fetchPolicy: "no-cache",
    mutation: testImportGql,
    variables
  })) as ExecutionResult<TestImportUsers>;

  await minDelayPromise;

  return importTestResult;
}

export async function importUsers(
  users: ImportUserTestResult[],
  issueMethod: IssueMethod,
  loa: Loa,
  { signManager, client, organization, country, locale }: AppContext
) {
  const popup = signManager.openPopup();

  try {
    const usersToImport = users.map<ImportUserInput>(x =>
      convertUserToImportUserInput(x.originalImportUser)
    );

    const variables: Partial<ImportAndIssueUsersVariables> = {
      organizationId: organization.id,
      users: usersToImport,
      options: {
        issueMethod,
        loa
      }
    };

    const contractResponse = (await client.mutate({
      mutation: importAndIssueUsersContractGql,
      variables
    })) as ExecutionResult<GenerateImportAndIssueUsersContract>;
    const contract = contractResponse.data!.generateImportAndIssueUsersContract!;

    const signatures = await signManager.signManyJson(contract.text, contract.data, popup);

    const result = (await client.mutate<ImportAndIssueUsers>({
      fetchPolicy: "no-cache",
      mutation: gql`
        mutation ImportAndIssueUsers(
          $organizationId: String!
          $users: [ImportUserInput!]!
          $options: ImportAndIssueUsersOptionsInput!
          $signatures: [String!]!
        ) {
          importAndIssueUsers(
            organizationId: $organizationId
            users: $users
            options: $options
            signatures: $signatures
          ) {
            entries {
              ...ImportAndIssueUserResultFragment
            }
            activationCodesExpiresAt
          }
        }

        fragment ImportAndIssueUserResultFragment on ImportAndIssueUserResult {
          errors
          activationCode
          activationCodeExpiresAt
        }
      `,
      variables: { ...variables, signatures }
    })) as ExecutionResult<ImportAndIssueUsers>;

    return result;
  } catch (error) {
    popup.close();
    throw error;
  }
}

function convertUserToImportUserInput(user: ImportUser): ImportUserInput {
  return {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    nationalId: user.nationalId,
    phoneNumber: user.phone,
    password: user.password
  };
}

const importAndIssueUsersContractGql = gql`
  mutation GenerateImportAndIssueUsersContract(
    $organizationId: String!
    $users: [ImportUserInput!]!
    $options: ImportAndIssueUsersOptionsInput!
  ) {
    generateImportAndIssueUsersContract(
      organizationId: $organizationId
      users: $users
      options: $options
    ) {
      text
      data
    }
  }
`;
