import { SessionContext } from "@ist-group-private-scope/web-skolid";
import * as React from "react";
import { useAppContext } from "./GlobalContext";
import i18next from "i18next";

export const NoOrganizationScreen = () => {
  const sessionContext = React.useContext(SessionContext);
  return (
    <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
      <div className="box box-narrow">
        <div className="box-header">{i18next.t("no.organisation.screen.lackAuthorization")}</div>
        <div className="box-body">
          <p>
            {i18next.t("no.organisation.screen.lacksPermissionInformation")}
          </p>
          <button
            className="btn btn-primary"
            onClick={() => sessionContext.login({ prompt: "login" })}
          >
            {i18next.t("no.organisation.screen.switchUsers")}
          </button>
        </div>
      </div>
    </div>
  );
};
