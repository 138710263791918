import { NationalId } from "@ist-group-private-scope/skolid-client-components";
import React from "react";
import { CountryCode } from "../types/graphql";

export const NationalIdOrTfNumber = (props: {
  nationalId?: string | null;
  tfNumber?: string | null;
  country: CountryCode;
}) => (
  <span className="text-nowrap">
    {props.nationalId ? (
      <NationalId nationalId={props.nationalId} country={props.country} />
    ) : props.tfNumber ? (
      "TF: " + props.tfNumber
    ) : null}
  </span>
);
