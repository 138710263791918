import * as React from "react";
import { settings } from "../settings";
import * as oidc from "oidc-client";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { ApiProvider } from "./ApiProvider";
import { Admin2ClientContext } from "@ist-group-private-scope/skolid-client-components";

export interface ClientProviderProps {
  accessToken?: string | null;
  onNotAuthorized?: () => void;
  onNotAuthenticated?: () => void;
  children: ((client: ApolloClient<any>) => React.ReactNode) | React.ReactNode;
}

export class ClientProvider extends React.PureComponent<ClientProviderProps> {
  public render() {
    return (
      <ApiProvider
        accessToken={this.props.accessToken}
        onNotAuthorized={this.props.onNotAuthorized}
        onNotAuthenticated={this.props.onNotAuthenticated}
        environment={settings.skolidEnvironment}
      >
        {apis => (
          <Admin2ClientContext.Provider value={apis.admin2Client}>
            <ApolloProvider client={apis.adminClient as any}>
              {typeof this.props.children === "function"
                ? (this.props.children as any)(apis.adminClient as any)
                : this.props.children}
            </ApolloProvider>
          </Admin2ClientContext.Provider>
        )}
      </ApiProvider>
    );
  }
}
