import * as React from "react";
import { Switch, Route } from "react-router";
import * as routes from "../routes";
import { GroupIssueDetailsScreen } from "./GroupIssueDetailsScreen";
import { GroupIssueSelectScreen } from "./GroupIssueSelectScreen";

export const GroupIssueScreen = () => {
  return (
    <Switch>
      <Route exact path={routes.groupIssueRoute} component={GroupIssueSelectScreen} />
      <Route exact path={routes.groupIssueQrRoute} component={GroupIssueDetailsScreen} />
    </Switch>
  );
};
