import * as React from "react";
import istLogoUrl from "../images/ist-logo.png";
import { Trans } from "react-i18next";
import i18next from "i18next";

export const Footer = ( props:{baseUrl: string} ) => (
  <footer className="footer">
    <img className="mx-1 footer-logo" src={istLogoUrl} alt="Logo" />
    <div className="footer-body">
      <span className="mx-1 d-none d-md-inline">
        <Trans i18nKey="footer.skolID"/>
      </span>
      <span className="mx-1">{i18next.t("footer.ist")}</span>
      <span className="mx-1 d-none d-md-inline">
        <a href={props.baseUrl + "/om-tjansten"}>{i18next.t("footer.moreAboutService")}</a> •{" "}
        <a href={props.baseUrl + "/cookie-info"}>{i18next.t("footer.informationAboutCookies")}</a>
      </span>
      <span className="mx-1 d-inline d-md-none">
        <a href={props.baseUrl + "/om-tjansten"}>{i18next.t("footer.aboutService")}</a> •{" "}
        <a href={props.baseUrl + "/cookie-info"}>{i18next.t("footer.aboutCookies")}</a>
      </span>
    </div>
  </footer>
);
