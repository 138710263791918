import { ConfirmModal } from "@ist-group-private-scope/skolid-client-components";
import gql from "graphql-tag";
import { debounce } from "lodash";
import React from "react";
import { Mutation, Query } from "react-apollo";
import { Spinner } from "../../Common/Spinner";
import { AppContext } from "../../types";
import {
  GroupMemberEdgeIdentifierInput,
  GroupMemberRole,
  GroupMembers,
  GroupMembersVariables,
  GroupMembers_group_members_edges,
  Loa,
  RemoveGroupMembers,
  RemoveGroupMembersVariables
} from "../../types/graphql";
import { groupMembersConnectionFragmentGql } from "../fragments";
import { memberQueries } from "../queries";
import { Group } from "../types";
import { AddGroupMembersModal } from "./AddGroupMembersModal";
import { calcGroupMemberEdgeId, MembersTable } from "./MembersTable";
import { removeGroupMembersGql } from "./mutations";
import { QrIssueModal } from "./QrIssueModal";
import i18next from 'i18next';

export interface GroupAdminDetailsProps {
  group: Group;
  isRootGroup?: boolean;
  appContext: AppContext;
  onSelectGroup: (groupId: string) => void;
}

export interface GroupAdminDetailsState {
  showQrIssueForMember?: GroupMembers_group_members_edges;
  showAddUsers?: boolean;
  showRemoveUsers?: boolean;
  selectedEdges: { [key: string]: boolean };
  includeDescendantGroups: boolean;
  textFilter: string;
  textFilterDebounced: string;
  after: string | null;
  before: string | null;
}

export enum RoleFilter {
  AdminsAndIssuers,
  OnlyUsers,
  All
}

export class GroupAdminDetails extends React.PureComponent<
  GroupAdminDetailsProps,
  GroupAdminDetailsState
> {
  public state: GroupAdminDetailsState = {
    selectedEdges: {},
    includeDescendantGroups: true,
    textFilter: "",
    textFilterDebounced: "",
    before: null,
    after: null
  };
  private deboucedFilter = debounce(func => func(), 500);
  public render() {
    const {
      group,
      appContext: {
        organization: { id: organizationId }
      }
    } = this.props;
    return (
      <>
        <div className="row align-items-end mb-content">
          <h1 className="col-sm-8 mb-0">{group.name}</h1>
        </div>
        <div className="box">
          <div className="box-header">{i18next.t("group.admin.details.administratorsAndIssuers")}</div>
          <div className="box-body">
            <div className="form-group">
              <button
                className="btn btn-primary my-1"
                onClick={() => this.setState({ showAddUsers: true })}
              >
                {i18next.t("group.admin.details.addUser")}
              </button>
              &nbsp;
              <button
                className="btn my-1"
                disabled={!Object.values(this.state.selectedEdges).some(x => x)}
                onClick={() => this.setState({ showRemoveUsers: true })}
              >
                {i18next.t("group.admin.details.deleteUsers")}
              </button>
            </div>
            <div className="form-row">
              <div className="form-group col-md-7">
                <input
                  type="text"
                  placeholder={i18next.t("group.admin.details.filter")}
                  className="form-control"
                  value={this.state.textFilter}
                  onChange={ev => this.setState({ textFilter: ev.target.value })}
                />
              </div>

              <div className="form-group col-md-5">
                <select
                  className="form-control"
                  value={this.state.includeDescendantGroups ? "true" : "false"}
                  onChange={ev =>
                    this.setState({ includeDescendantGroups: ev.target.value === "true" })
                  }
                >
                  <option value="true">{i18next.t("group.admin.details.includeSubgroups")}</option>
                  <option value="false">{i18next.t("group.admin.details.showCurrentGroup")}</option>
                </select>
              </div>
            </div>
          </div>
          <Query<GroupMembers, GroupMembersVariables>
            query={groupMembersGql}
            variables={{
              groupId: group.id,
              organizationId,
              includeDescendants: this.state.includeDescendantGroups,
              roles: [GroupMemberRole.ADMIN, GroupMemberRole.ISSUER],
              filter: this.state.textFilterDebounced,
              after: this.state.after,
              before: this.state.before,
            }}
            fetchPolicy="cache-and-network"
          >
            {membersQueryResult => {
              if (membersQueryResult.loading) {
                return (
                  <div className="text-center box-body">
                    <Spinner />
                  </div>
                );
              }

              if (
                membersQueryResult.error ||
                !membersQueryResult.data ||
                !membersQueryResult.data.group
              ) {
                return (
                  <div className="alert alert-danger">{i18next.t("group.admin.details.failedToLoadMembers")}</div>
                );
              }
              const membersConnection = membersQueryResult.data.group.members;
              const membershipEdges = membersConnection.edges;
              return (
                <>
                  {membershipEdges.length > 0 ? (
                    <MembersTable
                      showIssueAction
                      membersConnection={membersConnection}
                      onAfter={after => this.setState({ before: null, after })}
                      onBefore={before => this.setState({ before, after: null })}
                      selectedMemberEdges={this.state.selectedEdges}
                      onToggledMemberEdge={edge =>
                        this.setState(prevState => ({
                          selectedEdges: {
                            ...prevState.selectedEdges,
                            [calcGroupMemberEdgeId(edge)]: !prevState.selectedEdges[
                              calcGroupMemberEdgeId(edge)
                            ]
                          }
                        }))
                      }
                      onToggleAll={selected =>
                        this.setState({
                          selectedEdges: membershipEdges.reduce(
                            (acc, x) => ({ ...acc, [calcGroupMemberEdgeId(x)]: selected }),
                            {}
                          )
                        })
                      }
                      onQrIssue={member => this.setState({ showQrIssueForMember: member })}
                    />
                  ) : (
                    <p className="text-center">
                      <em>{i18next.t("group.admin.details.noMembersMatchingTheFilter")}</em>
                    </p>
                  )}
                  <RemoveGroupMembersModal
                    organizationId={organizationId}
                    isOpen={this.state.showRemoveUsers}
                    onToggle={() =>
                      this.setState(prevState => ({
                        showRemoveUsers: !prevState.showRemoveUsers
                      }))
                    }
                    edgesToRemove={membershipEdges
                      .filter(x => !!this.state.selectedEdges[calcGroupMemberEdgeId(x)])
                      .map(x => ({
                        userId: x.user.id,
                        groupId: x.group.id,
                        role: x.role
                      }))}
                  />
                </>
              );
            }}
          </Query>
        </div>

        <QrIssueModal
          target={
            this.state.showQrIssueForMember ? this.state.showQrIssueForMember.user : undefined
          }
          appContext={this.props.appContext}
          loa={this.props.appContext.organization.issueLoa || Loa.TWO}
          onCancel={() => this.setState({ showQrIssueForMember: undefined })}
          onComplete={() => this.setState({ showQrIssueForMember: undefined })}
        />
        <AddGroupMembersModal
          appContext={this.props.appContext}
          group={group}
          isOpen={this.state.showAddUsers}
          onToggle={() => this.setState(prevState => ({ showAddUsers: !prevState.showAddUsers }))}
          refetchQueries={memberQueries}
        />
      </>
    );
  }

  public componentWillReceiveProps(nextProps: GroupAdminDetailsProps) {
    if (nextProps.group.id !== this.props.group.id) {
      this.setState({ after: null, before: null });
    }
  }

  public componentDidUpdate(prevProps: GroupAdminDetailsProps, prevState: GroupAdminDetailsState) {
    if (prevState.textFilter !== this.state.textFilter) {
      this.deboucedFilter(() =>
        this.setState({ textFilterDebounced: this.state.textFilter, after: null, before: null })
      );
    }
  }
}

const RemoveGroupMembersModal = (props: {
  onToggle: () => void;
  isOpen?: boolean;
  organizationId: string;
  edgesToRemove: GroupMemberEdgeIdentifierInput[];
}) => (
  <Mutation<RemoveGroupMembers, RemoveGroupMembersVariables>
    mutation={removeGroupMembersGql}
    refetchQueries={memberQueries}
    awaitRefetchQueries
  >
    {(mutate, mutateStatus) => (
      <ConfirmModal
        header={i18next.t("group.admin.details.removeUserFromGroup")}
        confirm={i18next.t("group.admin.details.delete")}
        type="danger"
        onConfirm={async () => {
          await mutate({
            variables: {
              organizationId: props.organizationId,
              edges: props.edgesToRemove
            }
          });
        }}
        isOpen={props.isOpen}
        onToggle={props.onToggle}
      >
        {i18next.t("group.admin.details.areYouSureToRemoveSelectedUsersFromGroups")}
        {mutateStatus.error ? (
          <div className="alert alert-danger">{i18next.t("group.admin.details.failedToDeleteSelectedUsers")}</div>
        ) : null}
      </ConfirmModal>
    )}
  </Mutation>
);

export const groupMembersGql = gql`
  query GroupMembers(
    $organizationId: String!
    $groupId: String!
    $includeDescendants: Boolean!
    $roles: [GroupMemberRole!]
    $filter: String
    $after: String
    $before: String
  ) {
    group(organizationId: $organizationId, id: $groupId) {
      id
      members(
        includeDescendants: $includeDescendants
        roles: $roles
        filter: $filter
        after: $after
        before: $before
      ) {
        ...GroupMemberUsers
      }
    }
  }

  ${groupMembersConnectionFragmentGql}
`;
