import gql from "graphql-tag";

export const UserFragmentGql = gql`
  fragment UserFragment on User {
    id
    nationalId
    tfNumber
    username
    loa
    name
    firstName
    lastName
    organization {
      id
      name
      country
    }
    role
    emails {
      ...EmailFragment
    }
    phoneNumbers {
      ...PhoneNumberFragment
    }
    blocked
    locked
    lastActivity
    addresses {
      ...AddressFragment
    }

    externalLogins {
      loginProvider
    }

    issuableByUser
  }

  fragment EmailFragment on Email {
    value
    createdAt
    use
    confirmed
  }

  fragment AddressFragment on UserAddress {
    id
    streetAddress
    postalCode
    country
    city
    apartmentNumber
    co
    type
    primary
    createdAt
  }

  fragment PhoneNumberFragment on PhoneNumber {
    id
    value
    canBeUsedForSmsCodes
    use
    createdAt
  }
`;
