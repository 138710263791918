import * as React from "react";
import { AppContext } from "../types";
import i18next from "i18next";

export const InsufficientLoaScreen = (props: { appContext: AppContext }) => (
  <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
    <div className="box box-narrow">
      <div className="box-header">{i18next.t("insufficient.loa.screen.insufficientLevelOfTrust")}</div>
      <div className="box-body">
        <p>{i18next.t("insufficient.loa.screen.requestLogInWithHigherLevelTrust")}</p>
        <div className="text-right mt-3">
          <button
            className="btn btn-link p-0"
            onClick={() => props.appContext.login({ acr_values: "https://skolid.se/loa3" })}
          >
            {i18next.t("insufficient.loa.screen.logInWithHigherLevelTrust")}
          </button>
        </div>
      </div>
    </div>
  </div>
);
