import * as React from "react";
import i18next from "i18next";

export const HelpScreen = () => (
  <div className="flex-fill">
    <h1>{i18next.t("help.screen.help")}</h1>
    <div className="box">
      <div className="box-body">
        <h3>{i18next.t("help.screen.guideForUser")}</h3>
        <ul>
          <li>
            <a href="/docs/aktivera-skolid.pdf" target="_blank">
              {i18next.t("help.screen.activateSchoolidAccount")}
            </a>
          </li>
        </ul>

        <h3>{i18next.t("help.screen.guidesForAdministratorsAndIssuers" )}</h3>
        <ul>
          <li>
            <a href="/docs/ge-ut-till-grupp.pdf" target="_blank">
              {i18next.t("help.screen.issueToGroup")}
            </a>
          </li>
          <li>
            <a href="/docs/ge-ut-skolid.pdf" target="_blank">
              {i18next.t("help.screen.account")}
            </a>
          </li>
          <li>
            <a href="/docs/ge-ut-befintligt-skolid.pdf" target="_blank">
              {i18next.t("help.screen.issueAndChangeExistingAccount")}
            </a>
          </li>
          <li>
            <a href="/docs/ge-ut-flera.pdf" target="_blank">
              {i18next.t("help.screen.importAndIssueMoreAccounts")}
            </a>
          </li>
        </ul>

        <h3>{i18next.t("help.screen.posters" )}</h3>
        <ul>
          <li>
            <a href="/docs/qr-affisch.pdf" target="_blank">
              {i18next.t("help.screen.activateWithQRCode")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);
