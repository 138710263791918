import * as React from "react";
import { LoggedOutReasons } from "@ist-group-private-scope/web-skolid";
import * as welcomeImage from "../images/welcome.jpeg";
import i18next from "i18next";

export const NoUserScreen = (props: { onLogin: () => void; loggedOutReason: any }) => (
  <div className="flex-fill d-flex justify-content-center align-items-center">
    <div className="box flex-fill">
      <div
        style={{
          minHeight: 150,
          height: "24vw",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: `url(${welcomeImage})`
        }}
      />
      <div className="box-body">
        <div className="row p-content">
          <div className="col-12 col-md-7 pr-md-5">
          <h2>{i18next.t("logged.out.screen.welcomeToSkolid")}</h2>
            <p>
              {i18next.t("logged.out.screen.informationAboutSkolid")}{" "}
            </p>
          </div>

          <div className="col-12 col-md-5">
            <h2>{i18next.t("logged.out.screen.signIn")}</h2>
            <p>{i18next.t("logged.out.screen.logInInformation")}</p>
            <button className="btn btn-primary" onClick={() => props.onLogin()}>
              {i18next.t("logged.out.screen.signIn")}
            </button>
          </div>
        </div>
        {props.loggedOutReason === LoggedOutReasons.Timeout ? (
          <div className="alert alert-warning m-content">
            {i18next.t("logged.out.screen.loggedOutInformation")}
          </div>
        ) : null}
      </div>
    </div>
  </div>
);
