import "@ist-group/bootstrap-theme-home/dist/index.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./App/App";
import "./index.css";
import * as Sentry from "@sentry/react";
import { settings } from "./settings";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";

Sentry.init({
    dsn: "https://e30b6d3a915240feb3cefdf9fa69e4cb@sentry.ist.com/24",
    environment: settings.skolidEnvironment,
});

ReactDOM.render(
  <React.Suspense fallback="Loading..." >  
    <Router>
      <App />
    </Router>
  </React.Suspense>,
  document.getElementById("root")
);

// Enable service worker when they are more stable: https://github.com/facebookincubator/create-react-app/issues/2554
// Currently the browser will render the old app after it has been updated which we can't tolerate.
// More stuff that needs to be considered when enabling: https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#offline-first-considerations
// registerServiceWorker();
