import {
  AsyncOperation,
  IssueMethodSelect,
  isValidIssueMethod,
  LoaSelect
} from "@ist-group-private-scope/skolid-client-components";
import { SignConsumer, SignManager } from "@ist-group-private-scope/web-skolid";
import Clipboard from "clipboard";
import _ from "lodash";
import * as React from "react";
import { withApollo } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";
import { Spinner } from "../../Common/Spinner";
import * as routes from "../../routes";
import { AppContext } from "../../types";
import { ImportUserAction, ImportUserResultFragment, IssueMethod, Loa } from "../../types/graphql";
import { ImportInstructions } from "../../Utils/importInstructions";
import { parseImport } from "../../Utils/parseImport";
import { importUsers, importUsersTest } from "./api";
import { ImportResultTable } from "./ImportResultTable";
import { ImportTestTable } from "./ImportTestTable";
import { columnConfiguration, ImportUser, ImportUserResult, ImportUserTestResult } from "./types";
import { Trans} from "react-i18next";
import i18next from 'i18next';

export interface ImportViewProps {
  appContext: AppContext;
}

interface ImportViewState {
  showImportHelp?: boolean;
  importText?: string;
  importParseError?: string;
  importUsers: ImportUser[];
  issueMethod?: IssueMethod;
  importTestOperation: AsyncOperation<ImportUserTestResult[]>;
  importOperation: AsyncOperation<{
    entries: ImportUserResult[];
    activationCodesExpiresAt: string | null;
  }>;
  step: GuideStep;
}

enum GuideStep {
  Options,
  Input,
  Result
}

class ImportViewDependencies extends React.PureComponent<
  ImportViewProps & RouteComponentProps<any>,
  ImportViewState
> {
  constructor(props: never) {
    super(props);
    this.state = {
      step: GuideStep.Options,
      importUsers: [],
      importTestOperation: {},
      importOperation: {}
    };
  }

  public render() {
    return (
      <div className="flex-fill">
        <h1><Trans i18nKey="import.view.issueMultipleAccounts" /></h1>
        <div className="box">{this.renderStep()}</div>
      </div>
    );
  }

  public componentDidMount() {
    // tslint:disable-next-line:no-unused-expression
    new Clipboard(".btn");
  }

  private getColumnConfiguration() {
    return this.props.appContext.organization.issueLoa === Loa.ONE
      ? columnConfiguration
      : _.omit(columnConfiguration, "password");
  }

  private renderStep() {
    switch (this.state.step) {
      case GuideStep.Options:
        return this.renderOptionsStep();
      case GuideStep.Input:
        return this.renderInputStep();
      case GuideStep.Result:
        return this.renderResultStep();
      default:
        return <></>;
    }
  }

  private renderOptionsStep() {
    return (
      <>
        <div className="box-header">
          <Trans i18nKey="import.view.methodOfIssue" />
          <div className="ml-auto mr-content"><Trans i18nKey="import.view.firstStep" /></div>
        </div>
        <div className="box-body">
          <IssueMethodSelect
            disabledPredicate={method =>
              !isValidIssueMethod(
                method as IssueMethod,
                this.props.appContext.organization.issueLoa
              ) || method === "ACTIVATION_REQUEST"
            }
            issueMethod={this.state.issueMethod}
            onIssueMethodChanged={newMethod =>
              this.setState({ issueMethod: newMethod as IssueMethod })
            }
          />
          <br />
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-secondary"
              onClick={() => this.props.history.push(routes.userLookupRoute)}
            >
              <Trans i18nKey="import.view.cancel" />
            </button>
            <button
              className="btn btn-primary"
              disabled={
                this.state.issueMethod === undefined ||
                this.props.appContext.organization.issueLoa === undefined
              }
              onClick={() => this.setState({ step: GuideStep.Input })}
            >
              <Trans i18nKey="import.view.next" />
            </button>
          </div>
        </div>
      </>
    );
  }

  private renderInputStep() {
    return (
      <>
        <div className="box-header">
          <Trans i18nKey="import.view.import" />
          <div className="ml-auto mr-content"><Trans i18nKey="import.view.secondStep" /></div>
        </div>
        <div className="box-body">
          <h4 className="mb-1"><Trans i18nKey="import.view.pasteAccounts" /></h4>
          <div className="align-items-end d-flex justify-content-between mb-2">
            <button
              className="btn btn-link btn-sm p-0 my-1"
              onClick={() =>
                this.setState(prevState => ({ showImportHelp: !prevState.showImportHelp }))
              }
            >
              {!this.state.showImportHelp ? <Trans i18nKey="import.view.viewInstructions" /> : <Trans i18nKey="import.view.hideInstructions" />} 
            </button>
            <button
              disabled={
                !this.state.importText ||
                this.state.importOperation.running ||
                this.state.importTestOperation.running
              }
              className="btn btn-secondary"
              onClick={this.handleClearImportTextClick}
            >
              <Trans i18nKey="import.view.clear" />
            </button>
          </div>
          <Collapse isOpen={this.state.showImportHelp}>
            <ImportInstructions columnsConfig={Object.values(this.getColumnConfiguration())} />
          </Collapse>

          <div className="form-group">
            <textarea
              className="form-control"
              rows={6}
              value={this.state.importText || ""}
              onChange={this.handleImportTextChange}
              placeholder={i18next.t("import.view.pastHere")}
              disabled={!!this.state.importText || !this.state.issueMethod}
            />
          </div>

          {this.state.importParseError && this.state.importParseError ? (
            <div className="alert alert-danger">{this.state.importParseError}</div>
          ) : null}
        </div>

        {this.state.importTestOperation.data ? (
          <ImportTestTable
            importUsers={this.state.importUsers}
            importResults={this.state.importTestOperation.data}
          />
        ) : null}

        <div className="box-body pt-0">
          {this.state.importTestOperation.error ? (
            <div className="alert alert-danger">{this.state.importTestOperation.error}</div>
          ) : null}

          <div className="row">
            <div className="col">
              <button
                disabled={
                  this.state.importTestOperation.running || this.state.importOperation.running
                }
                className="btn btn-secondary"
                onClick={this.handleBackFromImportInput}
              >
                <Trans i18nKey="import.view.back" />
              </button>
            </div>
            {this.state.importTestOperation.running || this.state.importOperation.running ? (
              <div className="col d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            ) : null}
            <SignConsumer>
              {signManager => (
                <div className="col text-right">
                  <button
                    disabled={
                      !this.state.importTestOperation.data ||
                      this.state.importOperation.running ||
                      this.state.importTestOperation.data.every(
                        x => x.action === ImportUserAction.ERROR
                      )
                    }
                    className="btn btn-primary"
                    onClick={() => this.handleImportClick(signManager!)}
                  >
                    <Trans i18nKey="import.view.importAndIssue" />
                  </button>
                </div>
              )}
            </SignConsumer>
          </div>
        </div>
      </>
    );
  }

  private renderResultStep() {
    return (
      <>
        <div className="box-header">
          <Trans i18nKey="import.view.issued" />
          <div className="ml-auto mr-content"><Trans i18nKey="import.view.thirdStep" /></div>
        </div>

        {this.state.importOperation.data ? (
          <ImportResultTable
            importResults={this.state.importOperation.data}
            issueMethod={this.state.issueMethod!}
            appContext={this.props.appContext}
          />
        ) : null}

        <div className="box-body">
          {this.state.importOperation.running ? (
            <div className="text-center mb-content">
              <Spinner />
            </div>
          ) : null}

          {this.state.importOperation.error ? (
            <div className="alert alert-danger">{this.state.importOperation.error}</div>
          ) : null}

          <div className="text-right">
            <button
              disabled={this.state.importOperation.running}
              className="btn btn-primary"
              onClick={() => this.props.history.push(routes.home)}
            >
              <Trans i18nKey="import.view.limpid" />
            </button>
          </div>
        </div>
      </>
    );
  }

  private handleBackFromImportInput = () => {
    this.setState({
      importText: undefined,
      importTestOperation: {},
      importParseError: undefined,
      step: GuideStep.Options
    });
  };

  private handleImportTextChange = async (ev: React.FormEvent<HTMLTextAreaElement>) => {
    const parseResult = parseImport(ev.currentTarget.value, this.getColumnConfiguration());
    if (!parseResult.error) {
      try {
        this.setState({
          importText: ev.currentTarget.value,
          importUsers: parseResult.results,
          importTestOperation: { running: true }
        });

        const importTestResult = await importUsersTest(
          this.props.appContext.organization.id,
          parseResult.results,
          this.state.issueMethod as IssueMethod,
          this.props.appContext.organization.issueLoa,
          this.props.appContext
        );

        const imporTestResultData = importTestResult.data!.testImportAndIssueUsers.entries.map<
          ImportUserTestResult
        >((x, index) => ({
          ...x,
          originalImportUser: this.state.importUsers[index]
        }));

        imporTestResultData.sort(compareImportTestUsers);

        this.setState({
          importTestOperation: {
            data: imporTestResultData
          }
        });
      } catch (error) {
        this.setState({
          importTestOperation: { error: <Trans i18nKey="import.view.failedToValidate" /> }
        });
        console.error("Test import failed: ", error);
      }
    } else {
      this.setState({
        importText: ev.currentTarget.value,
        importParseError: parseResult.error,
        importUsers: parseResult.results
      });
    }
  };

  private handleImportClick = async (signManager: SignManager) => {
    const validImportUsers = this.state.importTestOperation.data!.filter(
      x => x.importUser.valid && x.action !== ImportUserAction.ERROR
    );

    try {
      this.setState({ importOperation: { running: true } });

      const result = await importUsers(
        validImportUsers,
        this.state.issueMethod as IssueMethod,
        this.props.appContext.organization.issueLoa,
        this.props.appContext
      );

      const importUserResults = result.data!.importAndIssueUsers.entries.map<ImportUserResult>(
        (x, index) => ({
          ...x,
          originalTestResult: validImportUsers[index]
        })
      );

      importUserResults.sort((left, right) => {
        if (left.errors.length !== right.errors.length) {
          return left.errors.length < right.errors.length ? 1 : -1;
        }

        if (left.originalTestResult.action !== right.originalTestResult.action) {
          return actionSortOrder[left.originalTestResult.action] >
            actionSortOrder[right.originalTestResult.action]
            ? 1
            : -1;
        }

        return (
          (left.originalTestResult.importUser.lastName.normalizedValue || "").localeCompare(
            right.originalTestResult.importUser.lastName.normalizedValue || ""
          ) ||
          (left.originalTestResult.importUser.firstName.normalizedValue || "").localeCompare(
            right.originalTestResult.importUser.firstName.normalizedValue || ""
          )
        );
      });

      this.setState({
        importOperation: {
          data: {
            entries: importUserResults,
            activationCodesExpiresAt: result.data!.importAndIssueUsers.activationCodesExpiresAt
          }
        },
        step: GuideStep.Result
      });
    } catch (error) {
      this.setState({
        importOperation: { error: <Trans i18nKey="import.view.failedToImportAccounts" /> },
        step: GuideStep.Result
      });
    }
  };

  private handleClearImportTextClick = () => {
    this.setState({
      importText: undefined,
      importParseError: undefined,
      importUsers: [],
      importTestOperation: {},
      importOperation: {}
    });
  };
}

const actionSortOrder = {
  [ImportUserAction.ERROR]: 0,
  [ImportUserAction.CREATE]: 1,
  [ImportUserAction.UPDATE]: 2,
  [ImportUserAction.NOT_MODIFIED]: 2,
  [ImportUserAction.IGNORED]: 3
};

function compareImportTestUsers(left: ImportUserResultFragment, right: ImportUserResultFragment) {
  if (left.importUser.valid !== right.importUser.valid) {
    return left.importUser.valid > right.importUser.valid ? 1 : -1;
  }

  if (left.action !== right.action) {
    return actionSortOrder[left.action] > actionSortOrder[right.action] ? 1 : -1;
  }

  return (
    (left.importUser.lastName.normalizedValue || "").localeCompare(
      right.importUser.lastName.normalizedValue || ""
    ) ||
    (left.importUser.firstName.normalizedValue || "").localeCompare(
      right.importUser.firstName.normalizedValue || ""
    )
  );
}

export const ImportView = (withRouter(
  withApollo(ImportViewDependencies as any) as any
) as any) as React.ComponentClass<ImportViewProps>;
