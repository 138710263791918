import * as oidc from "oidc-client";
import * as React from "react";
import { Link, NavLink, RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import skolidLogo from "../images/skolid.png";
import en from "../images/en.png";
import sv from "../images/sv.png";
import de from "../images/de.png";
import no from "../images/no.png";
import { isGroupIssuer, isIssuer, isOrganizationAdmin } from "../permissions";
import * as routes from "../routes";
import { AppContext, AppSession } from "../types";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface HeaderProps extends RouteComponentProps<any> {
  session?: AppSession | null;
  onLogout?: () => void;
  appContext?: AppContext | null;
  onChangeOrganization?: () => void;
}


const languageLogo: {[key: string]: any} = {
  "en": {logo:en},
  "sv": {logo:sv},
  "de": {logo:de},
  "nb": {logo:no}
}

class HeaderWithRouter extends React.PureComponent<HeaderProps, { menuOpen?: boolean }> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const props = this.props;

    const minimal = props.location.pathname.startsWith(routes.minimalRoute);

    const logo = (
      <>
        <img src={skolidLogo} style={{ height: 35 }} alt="SkolID Logo" />{" "}
        <h3
          style={{
            display: "inline",
            verticalAlign: "middle",
            margin: "0 0 0 15px"
          }}
        >
          {i18next.t("header.admin")}
        </h3>
      </>
    );
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white mb-5">
        <div className="container">
          {minimal ? (
            logo
          ) : (
            <Link className="navbar-brand p-0" to="/">
              {logo}
            </Link>
          )}
          {
            // Hack to get hight right
          }
          <ul className="navbar-nav mr-auto" style={{ width: 1 }}>
            <li className="nav-item">
              <a className="nav-link" href="/">
                &nbsp;
              </a>
            </li>

            <li role="separator" className="nav-item dropdown-divider d-lg-none" />
          </ul>
          {props.session ? (
            <>
              <button
                className="navbar-toggler mb-3 mt-3"
                type="button"
                data-toggle="collapse"
                data-target="#mainMenu"
                aria-controls="mainMenu"
                aria-expanded={this.state.menuOpen ? "true" : "false"}
                aria-label="Toggle navigation"
                onClick={this.toggleMenu}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <Collapse id="mainMenu" isOpen={this.state.menuOpen} navbar>
                {props.appContext && !minimal ? (
                  <ul className="navbar-nav mr-auto">
                    {isIssuer(props.appContext) ? (
                      <NavItem
                        text={i18next.t("header.user")}
                        route={routes.userLookupRoute}
                        onClick={this.hideMenu}
                      />
                    ) : null}

                    {isGroupIssuer(props.appContext) ? (
                      <NavItem
                        text={i18next.t("header.groups")}
                        route={routes.groupIssueRoute}
                        onClick={this.hideMenu}
                      />
                    ) : null}

                    {isOrganizationAdmin(props.appContext) ? (
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          {i18next.t("header.administer")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItemLink
                            to={routes.organizationAdminRoute}
                            onClick={this.hideMenu}
                          >
                            {i18next.t("header.organization")}
                          </DropdownItemLink>
                          <DropdownItemLink to={routes.groupAdminRoute} onClick={this.hideMenu}>
                            {i18next.t("header.groupPermissions")}
                          </DropdownItemLink>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : null}

                    <NavItem 
                      text={i18next.t("header.help")}
                      route={routes.helpRoute}
                      onClick={this.hideMenu}
                    />
                  </ul>
                ) : null}

                <ul className="navbar-nav ml-auto">

                  <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <LanguageSwitcher />
                  </div>

                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav className="py-3">
                      <div>
                        <div>{props.session.user.name}</div>
                        <div className="small">
                          {props.appContext ? props.appContext.organization.name : null}
                          {props.appContext && !props.appContext.organization.country
                            ? ` (${props.appContext.country})`
                            : ""}
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu right>
                      {props.appContext && props.appContext.canChangeOrganization ? (
                        <DropdownItem onClick={props.onChangeOrganization}>
                          {i18next.t("header.changeOrganization")}
                        </DropdownItem>
                      ) : null}

                      <DropdownItem onClick={props.onLogout}>
                        {i18next.t("header.logOut")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ul>
              </Collapse>
            </>
          ) : 
            <ul className="navbar-nav ml-auto" >
              <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                <LanguageSwitcher />
              </div>
            </ul>
          }
        </div>
      </nav>
    );
  }

  private toggleMenu = () => {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
  };

  private hideMenu = () => {
    this.setState({ menuOpen: false });
  };
}

const NavItem = (props: { text: string; route: string; onClick: () => void }) => (
  <li className="nav-item">
    <NavLink className="nav-link" activeClassName="active" to={props.route} onClick={props.onClick}>
      {props.text}
    </NavLink>
  </li>
);

const DropdownItemLink = (props: { to: string; children: string; onClick: () => void }) => {
  const history = useHistory();
  return (
    <DropdownItem
      onClick={() => {
        props.onClick();
        return history.push(props.to);
      }}
    >
      {props.children}
    </DropdownItem>
  );
};

const DropdownItemAction = (props: { children: any; onClick: () => void }) => {
  return (
    <DropdownItem onClick={() => {props.onClick()}}>
      {props.children}
    </DropdownItem>
  );
};

const LanguageSwitcher  = () => {

  const {i18n} = useTranslation();
  var currentLng = i18n.resolvedLanguage;
 
  return (
    <UncontrolledDropdown nav inNavbar  >
      <DropdownToggle nav caret>
        <img src={languageLogo[currentLng]?.logo} 
          style={{ height: 11, marginBottom: 2, marginRight:5 }} 
          alt={currentLng} />  
        {i18next.t("header." + currentLng)}
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(languageLogo).map((lng, index) =>
          (
            <DropdownItemAction key={index} 
            onClick={() => i18n.changeLanguage(lng)}
             >
               <img src={languageLogo[lng]?.logo} 
                 style={{ height: 11, marginBottom: 2, marginRight:5 }} 
                 alt={lng} />
               {i18next.t("header." + lng)}
            </DropdownItemAction>
          )
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export const Header = withRouter(HeaderWithRouter);
