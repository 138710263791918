import * as gqlTypes from "./graphql";
import {
  FormUserAddress,
  ValidationResult,
  WithValidation,
  BasicUser,
  FormUserEmail
} from "@ist-group-private-scope/skolid-client-components";
import { AppContext } from ".";

export interface FormUser {
  id?: string | null;
  nationalId: string | null;
  loa: gqlTypes.Loa;
  firstName: string;
  lastName: string;
  emails: FormUserEmail[];
  addresses: FormUserAddress[];
  tfNumber: string | null;
  username: string | null;
  phoneNumbers: WithValidation<gqlTypes.PhoneNumberInput>[];
  lastActivity: string | null;
  externalLogins: Array<{ loginProvider: string }>;

  nationalIdValidationPromise?: Promise<ValidationResult>;
  nationalIdValidationError?: ValidationResult;

  tfNumberValidationPromise?: Promise<ValidationResult>;
  tfNumberValidationError?: ValidationResult;

  usernameValidationPromise?: Promise<ValidationResult>;
  usernameValidationError?: ValidationResult;
}

export function convertFormUserToBasicUserWithoutId(
  formUser: FormUser,
  appContext: AppContext
): Omit<BasicUser, "id"> {
  return {
    ...formUser,
    organization: appContext.organization
  };
}
