import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { LoadingScreen } from "../App/LoadingScreen";
import { GlobalProgress } from "../Common/GlobalProgress";
import { isOrganizationAdmin } from "../permissions";
import { AppContext } from "../types";
import {
  GroupsForUser,
  GroupsForUserVariables,
  GroupsInOrg,
  GroupsInOrgVariables
} from "../types/graphql";
import { GroupsContent } from "./Components/GroupsContent";
import { groupsForAdministratorFragmentGql } from "./fragments";
import i18next from 'i18next';

export class GroupsAdminScreen extends React.PureComponent<{ appContext: AppContext }> {
  public render() {
    const { organization } = this.props.appContext;
    return (
      <>
        {isOrganizationAdmin(this.props.appContext) ? (
          <Query<GroupsInOrg, GroupsInOrgVariables>
            query={groupsInOrgGql}
            variables={{ organizationId: organization.id }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading) {
                return <LoadingScreen />;
              }

              if (error || !data || !data.organization) {
                return <div className="alert alert-danger">{i18next.t("group.admin.screen.failedToFetchGroup")}</div>;
              }

              const rootGroup = data.organization.group;
              return (
                <GroupsContent
                  appContext={this.props.appContext}
                  groups={[rootGroup]}
                  onGroupsChanged={() => refetch()}
                />
              );
            }}
          </Query>
        ) : (
          <Query<GroupsForUser, GroupsForUserVariables>
            query={groupsForUserGql}
            variables={{
              organizationId: organization.id,
              userId: this.props.appContext.session.user.id
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading) {
                return <GlobalProgress />;
              }

              if (error || !data || !data.user) {
                return <div className="alert alert-danger">{i18next.t("group.admin.screen.failedToFetchGroup")}</div>;
              }

              const groups = data.user.groups.edges.map(x => x.node);
              return (
                <GroupsContent
                  appContext={this.props.appContext}
                  groups={groups}
                  onGroupsChanged={() => refetch()}
                />
              );
            }}
          </Query>
        )}
      </>
    );
  }
}

const groupsForUserGql = gql`
  query GroupsForUser($organizationId: String!, $userId: String!) {
    user(id: $userId) {
      groups(organizationId: $organizationId) {
        edges {
          node {
            ...GroupsForAdministration
          }
        }
      }
    }
  }

  ${groupsForAdministratorFragmentGql}
`;

const groupsInOrgGql = gql`
  query GroupsInOrg($organizationId: String!) {
    organization(id: $organizationId) {
      id
      group {
        ...GroupsForOrgAdmin
      }
    }
  }

  fragment GroupsForOrgAdmin on Group {
    ...GroupForOrgAdmin
    children {
      nodes {
        ...GroupForOrgAdmin
        children {
          nodes {
            ...GroupForOrgAdmin
            children {
              nodes {
                ...GroupForOrgAdmin
                children {
                  nodes {
                    ...GroupForOrgAdmin
                    children {
                      nodes {
                        ...GroupForOrgAdmin
                        children {
                          nodes {
                            ...GroupForOrgAdmin
                            children {
                              nodes {
                                ...GroupForOrgAdmin
                                children {
                                  nodes {
                                    ...GroupForOrgAdmin
                                    children {
                                      nodes {
                                        ...GroupForOrgAdmin
                                        children {
                                          nodes {
                                            ...GroupForOrgAdmin
                                            children {
                                              nodes {
                                                ...GroupForOrgAdmin
                                                children {
                                                  nodes {
                                                    ...GroupForOrgAdmin
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment GroupForOrgAdmin on Group {
    id
    name
  }
`;
