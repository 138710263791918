import * as React from "react";
import * as gqlTypes from "../../types/graphql";
import { isValidFieldResult , getFieldValidationErrorMessage} from "./types";

import classNames from "classnames";

export const FieldCell = (props: {
  originalValue: string;
  field: gqlTypes.ImportUserFieldFragment;
  showOriginal?: boolean;
  transform?: (val: string) => string;
  className?: string;
}) => {
  const errorMessage = getFieldValidationErrorMessage(props.field.status);
  const value =
    !props.showOriginal && props.field.normalizedValue
      ? props.field.normalizedValue
      : props.originalValue;
  const transformedValue =
    props.transform && isValidFieldResult(props.field) ? props.transform(value) : value;

  return (
    <td
      className={classNames("truncate", props.className)}
      title={(errorMessage || "") + " " + transformedValue}
    >
      {errorMessage ? <strong className="text-danger">({errorMessage}) </strong> : null}
      {transformedValue}
    </td>
  );
};
