import { FormUser } from "../../types/formUser";
import { ApolloQueryResult } from "apollo-client";
import {
  CreateUserInput,
  IssueMethod,
  GenerateCreateAndIssueUserContract,
  CreateAndIssueUser,
  Loa,
  GenerateCreateAndIssueUserContractVariables,
  CreateAndIssueUserVariables,
  CreateUser,
  CreateUserVariables
} from "../../types/graphql";
import { UserFragmentGql } from "../../fragments";
import gql from "graphql-tag";
import {
  removeTypename,
  toPhoneNumberInput,
  toAddressInput,
  toEmailInput
} from "@ist-group-private-scope/skolid-client-components";
import { AppContext } from "../../types";

export async function createIssueUser(
  user: FormUser,
  issueMethod: IssueMethod,
  loa: Loa,
  { organization, signManager, client, country, locale }: AppContext
) {
  // Need to open popup before any async calls
  const signPopup = signManager.openPopup();

  try {
    const mutationArguments: GenerateCreateAndIssueUserContractVariables = {
      organizationId: organization.id,
      user: convertToCreateUserInput(user, organization.id),
      options: {
        method: issueMethod,
        loa,
        silent: true
      }
    };

    const contractResponse: ApolloQueryResult<GenerateCreateAndIssueUserContract> = (await client.mutate<
      GenerateCreateAndIssueUserContract
    >({
      mutation: genereateContratGql,
      variables: mutationArguments
    })) as any;
    const contract = contractResponse.data.generateCreateAndIssueUserContract;
    const signature = await signManager.sign(contract.text, contract.data, signPopup);

    const issueVariables: CreateAndIssueUserVariables = { ...mutationArguments, signature };
    return client.mutate({
      mutation: issueUserGql,
      variables: issueVariables
    }) as Promise<ApolloQueryResult<CreateAndIssueUser>>;
  } catch (error) {
    signPopup.close();
    throw error;
  }
}

const genereateContratGql = gql`
  mutation GenerateCreateAndIssueUserContract(
    $organizationId: String!
    $user: CreateUserInput!
    $options: CreateAndIssueUserOptionsInput!
  ) {
    generateCreateAndIssueUserContract(
      organizationId: $organizationId
      user: $user
      options: $options
    ) {
      text
      data
    }
  }
`;

const issueUserGql = gql`
  mutation CreateAndIssueUser(
    $organizationId: String!
    $user: CreateUserInput!
    $options: CreateAndIssueUserOptionsInput!
    $signature: String!
  ) {
    createAndIssueUser(
      organizationId: $organizationId
      user: $user
      options: $options
      signature: $signature
    ) {
      user {
        ...UserFragment
      }
      activationCode
      activationCodeExpiresAt
    }
  }

  ${UserFragmentGql}
`;

function convertToCreateUserInput(user: FormUser, organizationId: string): CreateUserInput {
  return removeTypename<CreateUserInput>({
    nationalId: user.nationalId,
    emails: user.emails.map(toEmailInput),
    firstName: user.firstName,
    lastName: user.lastName,
    tfNumber: user.tfNumber,
    username: user.username,
    phoneNumbers: user.phoneNumbers.map(toPhoneNumberInput),
    addresses: user.addresses.map(toAddressInput),
  });
}

export async function createUser(user: FormUser, appContext: AppContext) {
  const response = await appContext.client.mutate<CreateUser, CreateUserVariables>({
    mutation: gql`
      mutation CreateUser(
        $organizationId: String!
        $user: CreateUserInput!
        $options: CreateUserOptionsInput!
      ) {
        createUser(organizationId: $organizationId, user: $user, options: $options) {
          id
        }
      }
    `,
    variables: {
      user: convertToCreateUserInput(user, appContext.organization.id),
      organizationId: appContext.organization.id,
      options: {
        silent: true
      }
    }
  });

  return response.data?.createUser;
}
