import { Environment, environmentSettings } from "@ist-group-private-scope/web-skolid";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
// tslint:disable-next-line:no-submodule-imports
import { defaultDataIdFromObject } from "apollo-cache-inmemory/lib/inMemoryCache";
import { ApolloClient } from "apollo-client";
// tslint:disable-next-line:no-submodule-imports
import { DefaultOptions } from "apollo-client/ApolloClient";
import { ApolloLink, concat } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import * as oidc from "oidc-client";
import * as React from "react";
import admin2FragmentTypes from "../generated/admin-schema-2-fragments-types.json";

export interface ApiHookProps {
  environment: Environment;
  accessToken?: string | null;
  onNotAuthorized?: () => void;
  onNeedsReauthentication?: () => void;
  onNotAuthenticated?: () => void;
  defaultOptions?: DefaultOptions;
}

export interface ApiProviderProps extends ApiHookProps {
  children: (apis: Apis) => React.ReactElement;
}

export interface ApiProviderState {
  adminClient: ApolloClient<any>;
  permissionClient: ApolloClient<any>;
  admin2Client: ApolloClient<any>;
}

export type Apis = ApiProviderState;

export const useApis = (props: ApiHookProps) => {
  const propsRef = React.useRef(props);

  React.useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const [apis] = React.useState(() => {
    const authLink = new ApolloLink((operation, next) => {
      operation.setContext((context: any) => {
        const at = propsRef.current.accessToken;
        return {
          headers: {
            ...context.headers,
            authorization: at ? "Bearer " + at : null
          }
        };
      });

      return next!(operation);
    });

    const logoutLink = onError(error => {
      console.log(error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((gqlError: any) => {
          if (gqlError.code) {
            switch (gqlError.code) {
              case "not_authorized":
                if (propsRef.current.onNotAuthorized) {
                  propsRef.current.onNotAuthorized();
                }
                break;

              case "not_authenticated":
                if (propsRef.current.onNotAuthenticated) {
                  propsRef.current.onNotAuthenticated();
                }
                break;

              case "needs_reauthentication":
                if (propsRef.current.onNeedsReauthentication) {
                  propsRef.current.onNeedsReauthentication();
                }
                break;
            }
          }
        });
      }
    });

    const permissionClient = new ApolloClient({
      link: logoutLink.concat(
        concat(
          authLink,
          new HttpLink({
            uri: environmentSettings[propsRef.current.environment].apiUrl + "/graphql-permission"
          })
        )
      ),
      cache: new InMemoryCache({
        dataIdFromObject: (object: any) => {
          if (object.globalId) {
            return object.globalId;
          } else {
            if (permissionEntitiesWithGlobalId.indexOf(object.__typename) !== -1) {
              console.warn("Missing global id for entity: ", object);
            }

            return defaultDataIdFromObject({ ...object, id: undefined });
          }
        }
      }),
      defaultOptions: propsRef.current.defaultOptions
    });

    const adminClient = new ApolloClient({
      link: logoutLink.concat(
        concat(
          authLink,
          new HttpLink({
            uri: environmentSettings[propsRef.current.environment].apiUrl + "/graphql-admin"
          })
        )
      ),
      cache: new InMemoryCache(),
      defaultOptions: propsRef.current.defaultOptions
    });

    const admin2FragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: admin2FragmentTypes
    });
    const admin2Client = new ApolloClient({
      link: logoutLink.concat(
        concat(
          authLink,
          new HttpLink({
            uri: environmentSettings[propsRef.current.environment].apiUrl + "/admin"
          })
        )
      ),
      cache: new InMemoryCache({ fragmentMatcher: admin2FragmentMatcher }),
      defaultOptions: propsRef.current.defaultOptions
    });

    return { adminClient, permissionClient, admin2Client };
  });

  return apis;
};

// The environment property may not change after initial mount
export const ApiProvider = (props: ApiProviderProps) => {
  const apis = useApis(props);
  
  return props.children(apis);
};

const permissionEntitiesWithGlobalId = [
  "Subject",
  "Role",
  "Resource",
  "ResourceType",
  "RoleAssignment"
];
