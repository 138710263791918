import * as React from "react";
import { ImportUserResult, getImportedActionMessages } from "./types";
import classNames from "classnames";
import { FieldCell } from "./FieldCell";
import * as gqlTypes from "../../types/graphql";
import { environmentSettings } from "@ist-group-private-scope/web-skolid";
import { settings } from "../../settings";
import {
  FormattedDateTime,
  ActivationCode,
  IssueResultActivationCode
} from "@ist-group-private-scope/skolid-client-components";
import { AppContext } from "../../types";
import { Trans} from "react-i18next";
import i18next from 'i18next';

export interface ImportResultTableProps {
  importResults: { entries: ImportUserResult[]; activationCodesExpiresAt: string | null };
  issueMethod: gqlTypes.IssueMethod;
  appContext: AppContext;
}

const skolidUrl = environmentSettings[settings.skolidEnvironment].url;

// Styles
const displayNoneStyle: React.CSSProperties = { display: "none" };
const activationPrintCardStyle: React.CSSProperties = {
  marginTop: 12,
  marginBottom: 12,
  pageBreakInside: "avoid"
};

export const ImportResultTable = (props: ImportResultTableProps) => (
  <div>
    {props.issueMethod === gqlTypes.IssueMethod.CODE ? (
      <div className="p-content">
        <h4><Trans i18nKey="import.result.table.activationInstructions" /></h4>
        <p>
          <Trans i18nKey="import.result.table.distributeActivationCodes" values={{skolidUrlActivateCode:skolidUrl + i18next.t("import.result.table.activateCode")}}>
            Dela ut aktiveringskoderna och be användarna att gå till <a target="_blank" href={skolidUrl + i18next.t("import.result.table.activateCode")} rel="noopener noreferrer"> {skolidUrl + i18next.t("import.result.table.activateCode")}</a> för att aktivera sina konton. 
          </Trans>
        </p>
        <p>
          <Trans i18nKey="import.result.table.activationCodeValidFor" />{" "}
          <strong>
            <FormattedDateTime date={new Date(props.importResults.activationCodesExpiresAt!)} />
          </strong>
        </p>

        <div>
          <div id="print-import-users-div" style={displayNoneStyle}>
            {props.importResults.entries
              .filter(x => x.errors.length === 0)
              .map((userResult, index) => (
                <div key={index} style={activationPrintCardStyle}>
                  <IssueResultActivationCode
                    activationCode={userResult.activationCode}
                    activationCodeExpiresAt={userResult.activationCodeExpiresAt}
                    user={{
                      firstName: userResult.originalTestResult.importUser.firstName.normalizedValue,
                      lastName: userResult.originalTestResult.importUser.lastName.normalizedValue,
                      emails: userResult.originalTestResult.originalImportUser.email
                        ? [{ value: userResult.originalTestResult.originalImportUser.email }]
                        : [],
                      nationalId:
                        userResult.originalTestResult.importUser.nationalId.normalizedValue,
                      tfNumber: null,
                      username: null,
                      organization: props.appContext.organization,
                      id: "",
                      phoneNumbers: userResult.originalTestResult.importUser.phoneNumber
                        .normalizedValue
                        ? [{ canBeUsedForSmsCodes: true }]
                        : []
                    }}
                    skolidUrl={skolidUrl}
                  />
                </div>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => {
              const el = document.getElementById("print-import-users-div");
              PrintElem(el!);
            }}
          >
            <Trans i18nKey="import.result.table.print" />
          </button>
        </div>
      </div>
    ) : null}

    <table className="table table-sm table-hover mt-3 table-truncate table-responsive-sm">
      <thead>
        <tr>
          <th style={{ width: 130 }}><Trans i18nKey="import.result.table.results" /></th>
          <th style={{ width: 130 }}><Trans i18nKey="import.result.table.socialSecurityNumber" /></th>
          <th><Trans i18nKey="import.result.table.firstName" /></th>
          <th className="d-none d-md-table-cell"><Trans i18nKey="import.result.table.surname" /></th>
          <th className="d-none d-md-table-cell"><Trans i18nKey="import.result.table.email" /></th>
          <th className="d-none d-lg-table-cell"><Trans i18nKey="import.result.table.mobileNumber" />r</th>
          {props.issueMethod === gqlTypes.IssueMethod.CODE ? <th><Trans i18nKey="import.result.table.activationCode" /></th> : null}
        </tr>
      </thead>
      <tbody>
        {props.importResults.entries.map((userResult, index) => {
          const userTestResult = userResult.originalTestResult;
          const user = userResult.originalTestResult.originalImportUser;

          return (
            <tr
              key={user.importIndex}
              className={classNames({
                "table-danger": userResult && userResult.errors.length > 0
              })}
            >
              <td>
                {userResult.errors.length > 0 ? (
                  <strong className="text-danger" title={i18next.t("import.result.table.failed")}>
                    <Trans i18nKey="import.result.table.failed" />
                  </strong>
                ) : (
                  getImportedActionMessages(userTestResult.action)
                )}
              </td>
              <FieldCell
                field={userTestResult.importUser.nationalId}
                originalValue={user.nationalId}
              />
              <FieldCell
                field={userTestResult.importUser.firstName}
                originalValue={user.firstName}
              />
              <FieldCell
                className="d-none d-md-table-cell"
                field={userTestResult.importUser.lastName}
                originalValue={user.lastName}
              />
              <FieldCell
                className="d-none d-md-table-cell"
                field={userTestResult.importUser.email}
                originalValue={user.email}
                showOriginal
              />
              <FieldCell
                className="d-none d-lg-table-cell"
                field={userTestResult.importUser.phoneNumber}
                originalValue={user.phone}
              />
              {props.issueMethod === gqlTypes.IssueMethod.CODE ? (
                <td>
                  <ActivationCode activationCode={userResult.activationCode} />
                </td>
              ) : null}
            </tr>
          );
        })}
      </tbody>
      <caption className="text-center">
        {(() => {
          const importResults = props.importResults!;
          const createCount = importResults.entries.filter(
            x =>
              x.originalTestResult.action === gqlTypes.ImportUserAction.CREATE &&
              x.errors.length === 0
          ).length;
          const updateCount = importResults.entries.filter(
            x =>
              x.originalTestResult.action === gqlTypes.ImportUserAction.UPDATE &&
              x.errors.length === 0
          ).length;
          const errorCount = importResults.entries.filter(x => x.errors.length > 0).length;

          return (
            <>
              <Trans i18nKey="import.result.table.accountInformation" 
              values={{createCount:createCount, updateCount:updateCount, errorCount:errorCount}}/>
            </>
          );
        })()}
      </caption>
    </table>
  </div>
);

function PrintElem(elem: HTMLElement) {
  const mywindow = window.open("", i18next.t("import.result.table.print"), "")!;

  mywindow.document.write("<html><head><title>SkolID</title>");
  mywindow.document.write("<style>body { font-family: 'Arial' }</style>");
  mywindow.document.write("</head><body>");
  mywindow.document.write(elem.innerHTML);
  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  (mywindow as any).print();
  mywindow.close();

  return true;
}
