import * as React from "react";
import { User } from "oidc-client";
import { AppContextOrganization } from "../types";
import i18next from "i18next";

export interface SelectOrganizationProps {
  organisations: AppContextOrganization[];
  onOrganizationSelect: (orgId: string) => void;
}

export class SelectOrganization extends React.PureComponent<SelectOrganizationProps> {
  public render() {
    const props = this.props;

    const organizationChoises = props.organisations.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
        <div className="box box-narrow m-content">
          <div className="box-header">{i18next.t("choose.organization.selectOrganization")}</div>
          <div className="box-body">
            <p>{i18next.t("choose.organization.chooseOrganization")}</p>
            <select
              className="custom-select"
              onChange={ev => {
                if (ev.currentTarget.value) {
                  props.onOrganizationSelect(ev.currentTarget.value);
                }
              }}
            >
              <option>{i18next.t("choose.organization.selectOrganization")}</option>
              {organizationChoises.map(org => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
