import { FormattedDateTime } from "@ist-group-private-scope/skolid-client-components";
import { environmentSettings } from "@ist-group-private-scope/web-skolid";
import classNames from "classnames";
import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import { useRouteMatch } from "react-router";
import { Modal, ModalHeader } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { useAppContext } from "../App/GlobalContext";
import { useGlobalProgressEffect } from "../Common/GlobalProgress";
import { NationalIdOrTfNumber } from "../Common/NationalIdOrOrg";
import { Spinner } from "../Common/Spinner";
import { isGroupAdmin } from "../permissions";
import * as routes from "../routes";
import { settings } from "../settings";
import {
  FetchGroup,
  FetchGroupVariables,
  FetchGroup_group_members,
  GenerateIssueUsersContract,
  GenerateIssueUsersContractVariables,
  GroupIssueUser,
  GroupMemberRole,
  IssueMethod,
  IssueToGroup,
  IssueToGroupVariables
} from "../types/graphql";
import { UpdatePaginationStateActions, useDirectEffect, usePagination } from "../Utils/hooks";
import { ErrorModal } from "./Components/ErrorModal";
import { QrAddModal } from "./Components/QrAddIssueList";
import { Trans} from "react-i18next";
import i18next from 'i18next';

type GroupMembers = FetchGroup_group_members;

enum IssueView {
  Users,
  Guardians,
  Admins
}

const maxAllowedPending = 15;

interface ActivationRequest {
  activationRequestId: string;
  targetUserId: string;
}

export const GroupIssueDetailsScreen = () => {
  const appContext = useAppContext();
  const match = useRouteMatch<routes.GroupIssueParams>()!;
  const groupId = match.params.groupId;

  const [view, setView] = React.useState<IssueView>(IssueView.Users);
  const [includeSubGroups] = React.useState(true);
  const [pendingActivationCount, setPendingActivationCount] = React.useState(0);

  const activationUrl = environmentSettings[settings.skolidEnvironment].url + "/qr";
  const url = new URL(activationUrl);
  const hostnameAndPathname = url.hostname + url.pathname;

  const group = useQuery(
    gql`
      query FetchGroupName($organizationId: String!, $groupId: String!) {
        group(organizationId: $organizationId, id: $groupId) {
          id
          name
        }
      }
    `,
    {
      variables: {
        groupId,
        organizationId: appContext.organization.id
      }
    }
  );

  return (
    <div className="flex-fill">
      <h1>{i18next.t("group.issue.details.screen.issueTo")} {group && group.data && group.data.group && group.data.group.name}</h1>
      <div className="box">
        <p className="box-body mb-0">
          <Trans i18nKey="group.issue.details.screen.userCanReceiveAccountsViaSiteOrApp" >
            Användarna kan ta emot konton via  <a href={activationUrl}> {{hostnameAndPathname}} </a>  eller SkolID-appen på Android eller IPhone.
          </Trans>
        </p>

        <ViewSelect
          selectedView={view}
          onChange={newView => setView(newView)}
          disabled={pendingActivationCount > 0}
        />

        {/* <div className="box-body">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={!includeSubGroups}
                onChange={ev => setIncludeSubGroups(!ev.target.checked)}
                disabled={pendingActivationCount > 0}
              />
              Visa inte användare från undergrupper
            </label>
          </div>
        </div> */}

        {
          // NOTE: below we toggle the key to reset the internal state of the TabView component.
          // This is a work-around for the issue: https://github.com/apollographql/react-apollo/issues/2202
        }
        <TabView
          view={view}
          key={view}
          includeSubGroups={includeSubGroups}
          groupId={groupId}
          onPendingActivationCountChanged={setPendingActivationCount}
        />
      </div>
    </div>
  );
};

export const TabView = (props: {
  view: IssueView;
  groupId: string;
  includeSubGroups: boolean;
  onPendingActivationCountChanged: (count: number) => void;
}) => {
  const appContext = useAppContext();
  const organization = appContext.organization;

  const [pendingActivationRequests, setPendingActivationRequests] = React.useState<
    ActivationRequest[]
  >([]);
  const [qrAddError, setQrAddError] = React.useState("");
  const [issueError, setIssueError] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState<GroupIssueUser | null>(null);
  const selectUser = (user: GroupIssueUser | null) => {
    setSelectedUser(user);
    setQrAddError("");
  };
  const [issuing, setIssuing] = React.useState(false);
  const [showForcedIssueDialog, setShowForcedIssueDialog] = React.useState(false);

  const pendingActivationCount = pendingActivationRequests.length;
  useDirectEffect(pendingActivationCount, prev => {
    if (pendingActivationCount >= maxAllowedPending) {
      setShowForcedIssueDialog(true);
    }

    if (prev === 0 && pendingActivationCount > 0) {
      props.onPendingActivationCountChanged(pendingActivationCount);
    } else if (pendingActivationCount === 0) {
      props.onPendingActivationCountChanged(pendingActivationCount);
    }
  });

  const pagination = usePagination([props.includeSubGroups]);

  const groupQuery = useQuery<FetchGroup, FetchGroupVariables>(fetchGroupGql, {
    variables: {
      groupId: props.groupId,
      organizationId: organization.id,
      includeSubGroups: props.includeSubGroups,
      includeRoles:
        props.view === IssueView.Admins
          ? [GroupMemberRole.ADMIN, GroupMemberRole.ISSUER]
          : [GroupMemberRole.USER],
      onlyWithRelations: props.view === IssueView.Guardians,
      ...pagination.state
    },
    fetchPolicy: "cache-and-network"
  });

  const issue = useIssueToRequests();
  const handleIssueClick = async () => {
    setIssuing(true);

    try {
      await issue(pendingActivationRequests);
      setPendingActivationRequests([]);
    } catch (error) {
      console.error("Failed to issue users to activation requests", error);
      setIssueError(i18next.t("group.issue.details.screen.failedToIssueUser"));
    } finally {
      setIssuing(false);
      setShowForcedIssueDialog(false);
    }
  };

  useGlobalProgressEffect(!!(groupQuery.loading && groupQuery.data));

  if (groupQuery.error || (!groupQuery.loading && !(groupQuery.data && groupQuery.data.group))) {
    return (
      <div className="box-body">
        <div className="alert alert-danger">{i18next.t("group.issue.details.screen.failedToFetchGroup")}</div>
      </div>
    );
  }

  if (!groupQuery.data || !groupQuery.data.group) {
    return (
      <div className="box-body text-center">
        <Spinner />
      </div>
    );
  }

  const pendingActivationsByUserId = _.keyBy(pendingActivationRequests, x => x.targetUserId);
  const disabled = issuing;
  return (
    <>
      {groupQuery.data.group.members.edges.length === 0 ? (
        <div className="box-body text-center">{i18next.t("group.issue.details.screen.thereAreNoUsers")}</div>
      ) : props.view !== IssueView.Guardians ? (
        <IssueGroupTable
          paginationActions={pagination.actions}
          pendingActivationsByUserId={pendingActivationsByUserId}
          members={groupQuery.data.group.members}
          onAdd={pendingActivationRequests.length < maxAllowedPending ? selectUser : null}
          onRemove={target =>
            setPendingActivationRequests(p => p.filter(x => x.targetUserId !== target.id))
          }
          disabled={disabled}
        />
      ) : (
        <GuardiansTable
          paginationActions={pagination.actions}
          members={groupQuery.data.group.members}
          onAdd={pendingActivationRequests.length < maxAllowedPending ? selectUser : null}
          onRemove={target =>
            setPendingActivationRequests(p => p.filter(x => x.targetUserId !== target.id))
          }
          pendingActivationsByUserId={pendingActivationsByUserId}
          disabled={disabled}
        />
      )}

      <QrAddModal
        target={selectedUser || undefined}
        error={qrAddError}
        onCancel={() => selectUser(null)}
        onAdd={(activationRequestId, target) => {
          if (pendingActivationRequests.some(x => x.activationRequestId === activationRequestId)) {
            setQrAddError("QR-koden har redan skannats.");
            return;
          }

          selectUser(null);
          setPendingActivationRequests(p => [
            ...p,
            { activationRequestId, targetUserId: target.id }
          ]);
        }}
      />
      <IssueFooter
        numberOfActivationRequests={pendingActivationRequests.length}
        onIssue={handleIssueClick}
      />

      <ErrorModal
        show={!!issueError}
        errorTitle="Misslyckades"
        errorMessage={issueError}
        onClose={() => setIssueError("")}
      />
      <ForcedIssueModal
        show={showForcedIssueDialog}
        onClose={() => setShowForcedIssueDialog(false)}
        onIssue={handleIssueClick}
        issuing={issuing}
      />
    </>
  );
};

const footerHeight = 50;
const IssueFooter = (props: { numberOfActivationRequests: number; onIssue: () => void }) => {
  if (props.numberOfActivationRequests === 0) {
    return null;
  }

  return (
    <div
      style={{
        left: 0,
        right: 0,
        bottom: 0,
        height: footerHeight,
        position: "fixed",
        boxShadow: "0px 3px 7px 2px black"
      }}
      className="bg-light mb-0 d-flex align-items-center"
    >
      <div className="d-flex align-items-center justify-content-between container">
        <div>{props.numberOfActivationRequests} {i18next.t("group.issue.details.screen.usersToIssue")}</div>
        <button className="btn btn-primary" onClick={props.onIssue}>
          {i18next.t("group.issue.details.screen.issue")}
        </button>
      </div>
    </div>
  );
};

const ForcedIssueModal = (props: {
  show?: boolean;
  onClose: () => void;
  onIssue: () => void;
  issuing?: boolean;
}) => {
  return (
    <Modal centered isOpen={props.show}>
      <ModalHeader>{i18next.t("group.issue.details.screen.timeToIssue")}</ModalHeader>
      <ModalBody>
        <div>{i18next.t("group.issue.details.screen.needToIssueNewAddedUser")}</div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button className="btn btn-secondary" disabled={props.issuing} onClick={props.onClose}>
          {i18next.t("group.issue.details.screen.close")}
        </button>
        {props.issuing ? <Spinner /> : null}
        <button className="btn btn-primary" onClick={props.onIssue} disabled={props.issuing}>
          {i18next.t("group.issue.details.screen.issueNow")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

const startOfToday = new Date(new Date().setHours(0));

const IssueGroupTable = (props: {
  members: GroupMembers;
  paginationActions: UpdatePaginationStateActions;
  onAdd: ((target: GroupIssueUser) => void) | null;
  onRemove: (target: GroupIssueUser) => void;
  pendingActivationsByUserId: { [key: string]: any | undefined };
  disabled: boolean;
}) => {
  const edges = props.members.edges;
  const { country, organization } = useAppContext();

  return (
    <div className="my-content">
      <table className="table table-sm table-hover mb-0">
        <thead>
          <tr>
            <th className="text-left">{i18next.t("group.issue.details.screen.name")}</th>
            <th style={{ width: 135 }}>{i18next.t("group.issue.details.screen.socialSecurityNumber")}</th>
            <th className="d-none d-md-table-cell">{i18next.t("group.issue.details.screen.lastIssued")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {edges.map(({ user }, index) => {
            const pendingUser = !!props.pendingActivationsByUserId[user.id];
            const lastIssued = user.lastIssued && new Date(user.lastIssued);
            return (
              <tr
                key={index}
                className={classNames(
                  pendingUser ? "table-primary" : lastIssued > startOfToday ? "table-success" : null
                )}
              >
                <td>
                  {user.firstName} {user.lastName}
                </td>
                <td className="truncate">
                  <NationalIdOrTfNumber
                    country={country}
                    nationalId={user.nationalId}
                    tfNumber={user.tfNumber}
                  />
                </td>
                <td className="d-none d-md-table-cell">
                  <FormattedDateTime date={lastIssued} relative />
                </td>
                <td className="text-right">
                  <ToggleUserButton
                    disabled={props.disabled}
                    isAdded={pendingUser}
                    onAdd={props.onAdd ? () => props.onAdd!(user) : null}
                    onRemove={() => props.onRemove!(user)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>

        <TableNextPreviousCaption
          pageInfo={props.members.pageInfo}
          onAfter={props.paginationActions.after}
          onBefore={props.paginationActions.before}
        ></TableNextPreviousCaption>
      </table>
    </div>
  );
};

const ToggleUserButton = (props: {
  isAdded: boolean;
  onAdd: (() => void) | null;
  onRemove: () => void;
  disabled: boolean;
}) => {
  return !props.isAdded ? (
    <button
      className="btn btn-primary btn-sm truncate"
      disabled={!props.onAdd || props.disabled}
      onClick={props.onAdd!}
    >
      <i className="fa fa-plus" />
    </button>
  ) : (
    <button
      className="btn btn-secondary btn-sm truncate"
      onClick={props.onRemove}
      disabled={props.disabled}
    >
      <i className="fa fa-minus" />
    </button>
  );
};

const GuardiansTable = (props: {
  paginationActions: UpdatePaginationStateActions;
  members: GroupMembers;
  onAdd: ((target: GroupIssueUser) => void) | null;
  onRemove: (target: GroupIssueUser) => void;
  pendingActivationsByUserId: { [key: string]: any | undefined };
  disabled: boolean;
}) => {
  const { country, organization } = useAppContext();
  const edges = props.members.edges;
  return (
    <div className="my-content">
      <table className="table table-sm">
        <thead>
          <tr>
            <th>{i18next.t("group.issue.details.screen.custodyFor")}</th>
            <th>{i18next.t("group.issue.details.screen.nameAndSocialSecurityNumber")}</th>
            <th className="d-none d-md-table-cell">{i18next.t("group.issue.details.screen.lastIssued")}</th>
            <th style={{ width: 75 }} />
          </tr>
        </thead>
        <tbody>
          {edges
            .filter(x => x.relations && x.relations.length > 0)
            .map((edge, index) => (
              <React.Fragment key={index}>
                {edge.relations.map((rel, relationIndex) => {
                  const isPendingUser = props.pendingActivationsByUserId[rel.targetUser.id];
                  const lastIssued =
                    rel.targetUser.lastIssued && new Date(rel.targetUser.lastIssued);
                  const borderBottomClass = {
                    "border-bottom-0": relationIndex < edge.relations.length - 1,
                    "p-2": true
                  };
                  const rowCellClassName = classNames(
                    isPendingUser
                      ? "table-primary"
                      : lastIssued > startOfToday
                      ? "table-success"
                      : null,
                    borderBottomClass
                  );

                  return (
                    <tr key={relationIndex}>
                      {relationIndex === 0 ? (
                        <td rowSpan={edge.relations.length} className="text-wrap">
                          {edge.user.firstName} {edge.user.lastName}
                        </td>
                      ) : null}
                      <td className={classNames(rowCellClassName)}>
                        <NationalIdOrTfNumber
                          country={country}
                          nationalId={rel.targetUser.nationalId}
                          tfNumber={rel.targetUser.tfNumber}
                        />
                        <br />
                        {rel.targetUser.firstName} {rel.targetUser.lastName}
                      </td>
                      <td className={classNames("d-none d-md-table-cell", rowCellClassName)}>
                        <FormattedDateTime date={lastIssued} relative />
                      </td>
                      <td className={classNames("text-right", rowCellClassName)}>
                        <ToggleUserButton
                          disabled={props.disabled}
                          isAdded={isPendingUser}
                          onAdd={props.onAdd ? () => props.onAdd!(rel.targetUser) : null}
                          onRemove={() => props.onRemove(rel.targetUser)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ))}
        </tbody>

        <TableNextPreviousCaption
          pageInfo={props.members.pageInfo}
          onAfter={props.paginationActions.after}
          onBefore={props.paginationActions.before}
        />
      </table>
    </div>
  );
};

const TableNextPreviousCaption = (props: {
  onBefore?: (cursor: string) => void;
  onAfter?: (cursor: string) => void;

  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    endCursor: string | null;
    startCursor: string | null;
  };
}) => (
  <caption className="text-center">
    {props.onBefore ? (
      <button
        disabled={!props.pageInfo.hasPreviousPage}
        className="btn btn-link"
        onClick={() => props.onBefore!(props.pageInfo.startCursor!)}
      >
        &lt;
      </button>
    ) : null}

    {props.onAfter ? (
      <button
        disabled={!props.pageInfo.hasNextPage}
        className="btn btn-link"
        onClick={() => props.onAfter!(props.pageInfo.endCursor!)}
      >
        &gt;
      </button>
    ) : null}
  </caption>
);

const fetchGroupGql = gql`
  query FetchGroup(
    $organizationId: String!
    $groupId: String!
    $includeRoles: [GroupMemberRole!]
    $before: String
    $after: String
    $onlyWithRelations: Boolean!
    $includeSubGroups: Boolean!
  ) {
    group(organizationId: $organizationId, id: $groupId) {
      id
      name
      members(
        roles: $includeRoles
        includeDescendants: $includeSubGroups
        before: $before
        after: $after
        first: 25
        last: 25
        onlyWithRelations: $onlyWithRelations
      ) {
        edges {
          role
          user {
            ...GroupIssueUser
          }
          relations @include(if: $onlyWithRelations) {
            targetUser {
              ...GroupIssueUser
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
      }
    }
  }

  fragment GroupIssueUser on User {
    id
    firstName
    lastName
    nationalId
    tfNumber
    username
    lastIssued
  }
`;

const issueGql = gql`
  mutation IssueToGroup(
    $requests: [IssueUsersToActivationRequestsInput!]!
    $options: IssueUsersOptionsInput!
    $signatures: [String!]!
  ) {
    issueUsersToActivationRequests(
      requests: $requests
      options: $options
      signatures: $signatures
    ) {
      users {
        id
      }
    }
  }
`;

const generateContractGql = gql`
  mutation GenerateIssueUsersContract($userIds: [String!]!, $options: IssueUsersOptionsInput!) {
    generateIssueUsersContract(userIds: $userIds, options: $options) {
      text
      data
    }
  }
`;

const ViewSelect = ({
  selectedView,
  onChange,
  disabled
}: {
  selectedView: IssueView;
  onChange: (view: IssueView) => void;
  disabled?: boolean;
}) => {
  const appContext = useAppContext();

  return (
    <ul className="nav nav-tabs pl-1 pr-0 px-sm-content">
      {[
        [IssueView.Users, i18next.t("group.issue.details.screen.pupils")],
        [IssueView.Guardians, i18next.t("group.issue.details.screen.caregiver")],
        ...(isGroupAdmin(appContext) ? ([[IssueView.Admins, i18next.t("group.issue.details.screen.issuer")] as const] as const) : [])
      ].map((view: any) => (
        <li className="nav-item" key={view[0]}>
          <a
            className={classNames(
              {
                active: selectedView === view[0],
                "font-weight-bold": selectedView === view[0],
                disabled
              },
              "nav-link",
              "px-3 px-sm-4 py-2 bg-transparent"
            )}
            onClick={ev => {
              ev.preventDefault();
              onChange(view[0]);
            }}
            href={"#" + view[0]}
          >
            {view[1]}
          </a>
        </li>
      ))}
    </ul>
  );
};

const useIssueToRequests = () => {
  const appContext = useAppContext();

  const [generateContract] = useMutation<
    GenerateIssueUsersContract,
    GenerateIssueUsersContractVariables
  >(generateContractGql);

  const [issue] = useMutation<IssueToGroup, IssueToGroupVariables>(issueGql);

  const issueToActivationRequests = async (requests: ActivationRequest[]) => {
    const signPopup = appContext.signManager.openPopup();

    try {
      const options: IssueToGroupVariables["options"] = {
        issueMethod: IssueMethod.ACTIVATION_REQUEST,
        loa: appContext.organization.issueLoa
      };

      const response = await generateContract({
        variables: {
          userIds: requests.map(x => x.targetUserId),
          options
        }
      });
      const contract = response.data!.generateIssueUsersContract!;

      const signatures = await appContext.signManager.signManyJson(
        contract.text,
        contract.data,
        signPopup
      );

      await issue({
        variables: {
          options,
          requests: requests.map(x => ({
            userId: x.targetUserId,
            activationRequestId: x.activationRequestId
          })),
          signatures
        },
        refetchQueries: ["FetchGroup"]
      });
    } finally {
      signPopup.close();
    }
  };

  return issueToActivationRequests;
};
