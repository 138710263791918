import * as React from "react";
import i18next from "i18next";

export const UnauthorizedScreen = () => (
  <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
    <div className="box narrow-box">
      <div className="box-header box-header-danger">{i18next.t("unauthorized.screen.accessDenied")}</div>
      <div className="box-body">
        <p>{i18next.t("unauthorized.screen.lacksPermissionInformation")}</p>
      </div>
    </div>
  </div>
);
