import { CommonContext } from "@ist-group-private-scope/skolid-client-components";
import { Loa } from "@ist-group-private-scope/skolid-client-components/dist/admin-gql";
import { AdminIdentities_me_adminIdentities_organization, CountryCode } from "./graphql";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export enum AppRole {
  OrganizationAdmin,
  OrganizationIssuer,
  GroupAdmin,
  GroupIssuer
}

export type AppContextOrganization = AdminIdentities_me_adminIdentities_organization;

export interface AppSession {
  loa: Loa;
  user: {
    id: string;
    name: string | null;
  };
}

export interface AppContext extends CommonContext {
  organization: AppContextOrganization;
  country: CountryCode;
  roles: AppRole[];
  login: (args?: any) => void;
  canChangeOrganization: boolean;
  session: AppSession;
}
