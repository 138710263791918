import {
  ActivationRequestQrReader,
  AsyncOperation,
  IssueByQrCode,
  issueUserToActivationRequest,
  Modal
} from "@ist-group-private-scope/skolid-client-components";
import { environmentSettings, SignConsumer, SignManager } from "@ist-group-private-scope/web-skolid";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Spinner } from "../../Common/Spinner";
import * as routes from "../../routes";
import { settings } from "../../settings";
import { AppContext } from "../../types";
import { FormUser } from "../../types/formUser";
import * as gqlTypes from "../../types/graphql";
import { issuedWithActivationCode } from "../types";
import { createIssueUser, createUser } from "./api";
import { IssueUserForm } from "./IssueUserForm";
import { IssuingUserStatus } from "./IssuingUserStatus";
import { NewUserEditForm } from "./NewUserEditForm";
import { Trans} from "react-i18next";

interface NewUserModalProps extends RouteComponentProps<any> {
  onClose: () => void;
  appContext: AppContext;
}

interface NewUserModalState {
  issueMethod: gqlTypes.IssueMethod | null;
  user: FormUser;
  step: GuideStep;
  issueOperation: AsyncOperation<gqlTypes.CreateAndIssueUser | IssueByQrCode>;
  qrCode: string | null;
}

enum GuideStep {
  UserForm,
  IssueForm,
  ScanQr,
  Issuing
}

const initState = {
  issueMethod: null,
  step: GuideStep.UserForm,
  issueOperation: { running: false },
  user: {
    id: null,
    firstName: "",
    lastName: "",
    tfNumber: "",
    username: "",
    emails: [],
    loa: gqlTypes.Loa.ONE,
    nationalId: "",
    phoneNumbers: [],
    lastActivity: null,
    addresses: [],
    externalLogins: [],
  },
  qrCode: "",
  manualQrCode: ""
};

class InnerNewUserModal extends React.PureComponent<NewUserModalProps, NewUserModalState> {
  constructor(props: NewUserModalProps) {
    super(props);
    this.state = initState;
  }

  public render() {
    const props = this.props;

    return (
      <Modal header={<Trans i18nKey="new.user.modal.issueNewAccount" />} onClose={props.onClose}>
        {closeModal => {
          switch (this.state.step) {
            case GuideStep.UserForm:
              return (
                <NewUserEditForm
                  appContext={props.appContext}
                  onClose={closeModal}
                  onUserUpdate={this.handlePartialUserUpdate}
                  client={props.appContext.client}
                  user={this.state.user}
                  onNext={() => this.setState({ step: this.state.step + 1 })}
                />
              );
            case GuideStep.IssueForm:
              return (
                <SignConsumer>
                  {signManager => (
                    <IssueUserForm
                      user={this.state.user!}
                      issueMethod={this.state.issueMethod}
                      loa={this.props.appContext.organization.issueLoa}
                      onIssueMethodChanged={n => this.setState({ issueMethod: n })}
                      issuingOperation={this.state.issueOperation}
                      onBack={() => this.setState(prevState => ({ step: prevState.step - 1 }))}
                      onIssue={
                        this.state.issueMethod === gqlTypes.IssueMethod.ACTIVATION_REQUEST
                          ? () => this.setState({ step: GuideStep.ScanQr })
                          : () => this.handleIssueUser(signManager!)
                      }
                      onCreate={() => this.handleCreate(signManager!)}
                      appContext={this.props.appContext}
                      routing={props}
                    />
                  )}
                </SignConsumer>
              );
            case GuideStep.ScanQr:
              const activationUrl = environmentSettings[settings.skolidEnvironment].url + "/qr";

              return (
                <>
                  <div className="modal-body">
                    {this.state.qrCode ? (
                      <p>
                        <Trans i18nKey="new.user.modal.qrCodeScanned" />{" "}
                      </p>
                    ) : (
                      <>
                        <p>
                          <Trans i18nKey="new.user.modal.askStartSiteOrApp" > 
                            Be användaren som ska ta emot kontot att starta SkolID appen eller navigera till <a href={activationUrl}>skolid.se/qr</a>.
                          </Trans>
                        </p>
                        <ActivationRequestQrReader
                          onActivationRequestId={id => this.setState({ qrCode: id })}
                          disabled={this.state.issueOperation.running}
                        />
                      </>
                    )}
                  </div>
                  <div className="modal-footer d-flex justify-content-between align-items-center">
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.setState({ step: GuideStep.IssueForm })}
                      disabled={this.state.issueOperation.running}
                    >
                      <Trans i18nKey="new.user.modal.back" />
                    </button>

                    {this.state.issueOperation.running ? <Spinner /> : null}

                    <button
                      className="btn btn-primary"
                      onClick={() => this.handleIssueUser(this.props.appContext.signManager)}
                      disabled={this.state.issueOperation.running || !this.state.qrCode}
                    >
                      <Trans i18nKey="new.user.modal.issue" />
                    </button>
                  </div>
                </>
              );

            case GuideStep.Issuing:
              return (
                <IssuingUserStatus
                  operation={this.state.issueOperation}
                  onDone={() => this.handleDone(closeModal)}
                  onIssueNext={() => this.handleIssueNext()}
                  onBack={() => this.setState({ step: GuideStep.UserForm })}
                />
              );

            default:
              return <div><Trans i18nKey="new.user.modal.error" /></div>;
          }
        }}
      </Modal>
    );
  }

  public componentDidUpdate(prevProps: NewUserModalProps) {
    if (prevProps.location !== this.props.location) {
      this.props.onClose();
    }
  }

  private handleDone = (closeModal: () => void) => {
    if (this.state.issueOperation.data) {
      this.props.history.push(
        routes.generateUserPath({
          userId: issuedWithActivationCode(this.state.issueOperation.data)
            ? this.state.issueOperation.data.createAndIssueUser!.user.id
            : this.state.issueOperation.data.issueUserToActivationRequest!.user.id
        })
      );
    }

    closeModal();
  };

  private handleIssueNext = () => {
    this.setState(initState);
  };

  private handlePartialUserUpdate = (partialUpdate: Partial<FormUser>) => {
    this.setState(prevState => ({
      user: { ...prevState.user, ...partialUpdate }
    }));
  };

  private handleIssueUser = async (signManager: SignManager) => {
    this.setState(prevState => ({
      issueOperation: { running: true }
    }));

    try {
      const minDelayPromise = new Promise(resolve => setTimeout(resolve, 1000));

      if (this.state.issueMethod === gqlTypes.IssueMethod.ACTIVATION_REQUEST) {
        const signPopup = signManager.openPopup();

        const createResult = await createUser(this.state.user, this.props.appContext);

        const result = await issueUserToActivationRequest(
          createResult!.id,
          this.props.appContext.organization.issueLoa,
          this.state.qrCode!,
          this.props.appContext,
          [],
          signPopup
        );

        await minDelayPromise;
        this.setState(prevState => ({
          issueOperation: { data: result },
          step: GuideStep.Issuing
        }));
      } else {
        const result = await createIssueUser(
          this.state.user,
          this.state.issueMethod!,
          this.props.appContext.organization.issueLoa,
          this.props.appContext
        );

        await minDelayPromise;
        this.setState(prevState => ({
          issueOperation: { data: result.data },
          step: GuideStep.Issuing
        }));
      }
    } catch (error) {
      this.setState(prevState => ({ issueOperation: { error }, step: GuideStep.Issuing }));
      console.log("Issue user error", error);
    }
  };

  private handleCreate = async (signManager: SignManager) => {
    this.setState(prevState => ({
      issueOperation: { running: true }
    }));

    try {
      const minDelayPromise = new Promise(resolve => setTimeout(resolve, 1000));

      const result = await createUser(this.state.user, this.props.appContext);

      await minDelayPromise;
      this.props.history.push(
        routes.generateUserPath({
          userId: result!.id
        })
      );
    } catch (error) {
      this.setState(() => ({ issueOperation: { error }, step: GuideStep.Issuing }));
      console.log("Issue user error", error);
    }
  };
}

export const NewUserModal = withRouter(InnerNewUserModal);
