import { ActivationRequestQrReader, IdentityCard } from "@ist-group-private-scope/skolid-client-components";
import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useAppContext } from "../../App/GlobalContext";
import { GroupIssueUser } from "../../types/graphql";
import { Trans} from "react-i18next";

interface QrAddModalProps {
  target?: GroupIssueUser;
  onCancel: () => void;
  onAdd: (activationRequestId: string, target: GroupIssueUser) => void;
  error: string;
}
export const QrAddModal = (props: QrAddModalProps) => {
  return <QrAddModalOpen {...props} target={props.target} />;
};

const QrAddModalOpen = (props: QrAddModalProps) => {
  const appContext = useAppContext();
  const [activationRequestId, setActivationRequestId] = React.useState<string | null>(null);

  const target = props.target;

  React.useEffect(() => {
    if (target?.id) {
      setActivationRequestId(null);
    }
  }, [target]);

  return (
    <Modal isOpen={!!target} centered style={{ marginTop: 10 }}>
      <ModalHeader toggle={props.onCancel}><Trans i18nKey="qr.add.issue.list.scanQRCode" /></ModalHeader>
      <ModalBody className="justify-content-center">
        {target ? (
          <IdentityCard
            user={{ ...target, organization: appContext.organization }}
            className="mb-content"
          />
        ) : null}

        {!activationRequestId ? (
          <ActivationRequestQrReader onActivationRequestId={setActivationRequestId} />
        ) : (
          <p><Trans i18nKey="qr.add.issue.checkIdentity" /></p>
        )}

        {props.error ? <div className="alert alert-danger">{props.error}</div> : null}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between align-items-center">
        <button className="btn btn-secondary" onClick={props.onCancel}>
          <Trans i18nKey="qr.add.issue.cancel" />
        </button>
        <button
          disabled={!activationRequestId}
          className="btn btn-primary"
          onClick={() => props.onAdd(activationRequestId!, target!)}
        >
          <Trans i18nKey="qr.add.issue.confirm" />
        </button>
      </ModalFooter>
    </Modal>
  );
};
