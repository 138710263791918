import { VelocityComponent } from "velocity-react";
import React from "react";

export class TreebeardContainer extends React.PureComponent<any, any> {
  private clickableRef: any = null;
  private velocityRef: any = null;

  public render() {
    const { style, decorators, terminal, onClick, node } = this.props;

    return (
      <div
        onClick={onClick}
        ref={ref => (this.clickableRef = ref)}
        style={{
          ...style.container,
          ...(node.active ? { background: "#dfdfdf", color: "#497b99" } : {})
        }}
      >
        {this.renderToggle(terminal)}

        <decorators.Header node={node} style={style.header} />
      </div>
    );
  }

  private renderToggle(terminal: boolean) {
    const { animations } = this.props;

    if (!animations) {
      return this.renderToggleDecorator(terminal);
    }

    return (
      <VelocityComponent
        animation={animations.toggle.animation}
        duration={animations.toggle.duration}
        ref={(ref: any) => (this.velocityRef = ref)}
      >
        {this.renderToggleDecorator(terminal)}
      </VelocityComponent>
    );
  }

  private renderToggleDecorator(terminal: boolean) {
    const { style, decorators } = this.props;

    return (
      <decorators.Toggle
        style={{
          ...style.toggle,
          base: { ...style.toggle.base, visibility: terminal ? "hidden" : undefined }
        }}
      />
    );
  }
}
