import gql from "graphql-tag";

export const ORGANIZATION_OVERVIEW_FRAGMENT = gql`
  fragment OrganizationOverview on Organization {
    id
    elevatedUsers: users(roles: [ADMIN, ISSUER]) {
      nodes {
        id
        name
        nationalId
        tfNumber
        username
        role
      }
    }
  }
`;

export const FETCH_ORGANIZATION_OVERVIEW = gql`
  query FetchOrganizationOverview($organizationId: String!) {
    organization(id: $organizationId) {
      ...OrganizationOverview
    }
  }

  ${ORGANIZATION_OVERVIEW_FRAGMENT}
`;
