// import isEmail from "isemail";
import {
  checkNationalId,
  validateAddressSync,
  validateNationalId,
  validatePhoneNumberSync,
  ValidationResult
} from "@ist-group-private-scope/skolid-client-components";
import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import * as React from "react";
import { Link } from "react-router-dom";
import * as routes from "../routes";
import { AppContext } from "../types";
import { FormUser } from "../types/formUser";
import { CheckTfNumber, CheckUsername, CountryCode } from "../types/graphql";
import { Trans} from "react-i18next";
import i18next from 'i18next';

export async function isFormUserValid(user: FormUser) {
  // Sync checks first
  const syncResult =
    !user.nationalIdValidationError &&
    !user.tfNumberValidationError &&
    !user.usernameValidationError &&
    user.addresses.every(x => !validateAddressSync(x)) &&
    user.phoneNumbers.every(x => !x.validationError && !validatePhoneNumberSync(x.value)) &&
    user.emails.every(x => !x.validationError);

  if (!syncResult) {
    return false;
  }

  const validationPromises = new Array<Promise<ValidationResult> | undefined>()
    .concat([
      user.nationalIdValidationPromise,
      user.tfNumberValidationPromise,
      user.usernameValidationPromise
    ])
    .concat(user.emails.map(x => x.validationPromise as Promise<any>))
    .concat(user.phoneNumbers.map(x => x.validationPromise))
    .filter(x => !!x);

  const results = await Promise.all(validationPromises);

  return results.every(x => !x);
}

export async function validateNationalIdAsync(
  user: FormUser,
  appContext: AppContext
): Promise<ValidationResult> {
  const res = await checkNationalId(
    user.nationalId,
    appContext.organization.id,
    user.id,
    appContext.client
  );

  if (res) {
    if (res.type === "AlreadyTaken") {
      return (
        <>
          <Trans i18nKey="validation.accountWithSocialSecurityNumber" >
            Det finns redan <Link to={routes.generateUserPath({ userId: res.byUserId })}>ett konto</Link> med personnummret.
          </Trans>
        </>
      );
    } else {
      return <Trans i18nKey="validation.failedValidateSocialSecurityNumber" />;
    }
  }
}

export function validateNationalIdSync(user: FormUser, country: CountryCode) {
  if (!!user.nationalId && !validateNationalId(user.nationalId || "", country)) {
    return <Trans i18nKey="validation.invalidSocialSecurityNumber" />;
  }

  return null;
}

export async function validateTfNumberAsync(
  value: string | null,
  userId: string | null,
  appContext: AppContext
): Promise<ValidationResult> {
  let result: ApolloQueryResult<CheckTfNumber>;
  if (!value) {
    return null;
  }

  try {
    result = await appContext.client.query<CheckTfNumber>({
      query: checkTfNumberGql,
      variables: {
        orgId: appContext.organization.id,
        value
      },
      fetchPolicy: "network-only"
    });
  } catch (error) {
    return <Trans i18nKey="validation.failedValidateTFNumber" />;
  }

  const user = result.data.userByTfNumber;
  if (result.errors) {
    return <Trans i18nKey="validation.failedValidateSocialSecurityNumber" />;
  } else if (user && user.id !== userId) {
    return (
      <>
        <Trans i18nKey="validation.existsWithTemporaryWithSocialSecurityNumber" >
          Det finns redan <Link to={routes.generateUserPath({ userId: user.id })}>ett konto</Link> med det tillfälliga personnummret.
        </Trans>
      </>
    );
  } else {
    return null;
  }
}

const checkTfNumberGql = gql`
  query CheckTfNumber($value: String!, $orgId: String!) {
    userByTfNumber(value: $value, organizationId: $orgId) {
      id
    }
  }
`;

export async function validateUsernameAsync(
  value: string | null,
  userId: string | null,
  appContext: AppContext
): Promise<ValidationResult> {
  let result: ApolloQueryResult<CheckUsername>;

  if (!value) {
    return null;
  }

  try {
    result = await appContext.client.query<CheckUsername>({
      query: checkUsernameGql,
      variables: {
        orgId: appContext.organization.id,
        value
      },
      fetchPolicy: "network-only"
    });
  } catch (error) {
    return <Trans i18nKey="validation.validation.failedToValidateUserName" />;
  }

  const user = result.data.userByUsername;
  if (result.errors) {
    return <Trans i18nKey="validation.validation.failedToValidateUserName" />;
  } else if (user && user.id !== userId) {
    return (
      <>
        <Trans i18nKey="validation.linkToAccount" >
          Det finns redan <Link to={routes.generateUserPath({ userId: user.id })}>ett konto</Link> med användarnamnet.
        </Trans>
      </>
    );
  } else {
    return null;
  }
}

const checkUsernameGql = gql`
  query CheckUsername($value: String!, $orgId: String!) {
    userByUsername(value: $value, organizationId: $orgId) {
      id
    }
  }
`;
