import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import yaml from 'js-yaml';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    ns:'skolidportal',
    backend: {
        loadPath: '/locales/{{ns}}_{{lng}}.yaml',
        parse: function(data) { return yaml.load(data) }
    },
      
    // do not load a fallback. https://www.i18next.com/principles/fallback#key-not-found
    fallbackLng: false,

    debug: true,
    detection:{
      // htmlTag lets define sv lng by default. https://github.com/i18next/i18next-browser-languageDetector
      order:['localStorage', 'htmlTag'], 
      cache:['localStorage']
    },
    
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default,
    }
  });

export default i18n;