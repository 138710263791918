import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo";
import { Link, useHistory } from "react-router-dom";
import { useAppContext } from "../App/GlobalContext";
import { LoadingScreen } from "../App/LoadingScreen";
import { useGlobalProgressEffect } from "../Common/GlobalProgress";
import { isGroupAdmin } from "../permissions";
import * as routes from "../routes";
import { AppRole } from "../types";
import {
  GroupMemberRole,
  IssueGroup,
  MeIssueGroups,
  MeIssueGroupsVariables,
  OrgIssueGroup,
  OrgIssueGroupVariables,
  OrgIssueGroup_organization_group,
  SelectGroupNavigation,
  SelectGroupNavigationVariables
} from "../types/graphql";
import i18next from 'i18next';

export const GroupIssueSelectScreen = () => {
  const appContext = useAppContext();
  const memberRoles = isGroupAdmin(appContext) ? null : [GroupMemberRole.USER];
  const queryResult = useRootIssueGroupsQuery(memberRoles);

  console.log("Query result", queryResult);

  if (queryResult.loading && !queryResult.groups) {
    return <LoadingScreen />;
  }

  if (queryResult.error || !queryResult.groups) {
    return <div className="box-body alert alert-danger">{i18next.t("group.issue.select.screen.failedToFetchGroup")}</div>;
  }

  const groups = queryResult.groups.sort((a, b) => a.name.localeCompare(b.name));

  if (groups.length === 0) {
    return <p className="text-center">{i18next.t("group.issue.select.screen.noPermissionToIssueGroups")}</p>;
  }

  return (
    <div className="flex-fill">
      <GroupIssueSelectScreenContent rootGroups={groups} memberRoles={memberRoles} />
    </div>
  );
};

type PathState = Array<{ id: string | null; name: string }> | null;

const GroupIssueSelectScreenContent = (props: {
  rootGroups: OrgIssueGroup_organization_group[];
  memberRoles: GroupMemberRole[] | null;
}) => {
  const appContext = useAppContext();
  const h = useHistory<PathState>();

  const path = h.location.state || [
    { name: i18next.t("group.issue.select.screen.start"), id: null },
    ...(props.rootGroups.length === 1 && props.rootGroups[0].children.totalCount > 0
      ? props.rootGroups
      : [])
  ];
  const setPath = (newPath: PathState) => h.push({ state: newPath });

  const parentGroupId = path[path.length - 1].id;

  const childGroups = useQuery<SelectGroupNavigation, SelectGroupNavigationVariables>(
    gql`
      query SelectGroupNavigation(
        $groupId: String!
        $orgId: String!
        $memberRoles: [GroupMemberRole!]
      ) {
        group(organizationId: $orgId, id: $groupId) {
          id
          name
          children {
            nodes {
              ...IssueGroup
            }
          }
        }
      }

      ${issueGroupFragmentGql}
    `,
    {
      variables: {
        groupId: parentGroupId!,
        orgId: appContext.organization.id,
        memberRoles: props.memberRoles
      },
      skip: !parentGroupId,
      fetchPolicy: "cache-and-network"
    }
  );

  const groups: IssueGroup[] = parentGroupId
    ? (childGroups.data && childGroups.data.group && childGroups.data.group.children.nodes) || []
    : props.rootGroups;

  useGlobalProgressEffect(childGroups.loading);

  return (
    <div className="flex-fill">
      <h1>{i18next.t("group.issue.select.screen.issueToGroup")}</h1>
      <div className="box">
        <div className="box-body pb-0">
          <h4 className="mb-0" style={{ textTransform: "none" }}>
            {path.map((x, i) => (
              <React.Fragment key={i}>
                <a
                  className="mr-2 text-nowrap"
                  href="#select"
                  onClick={ev => {
                    ev.preventDefault();
                    setPath(path.filter((xx, ii) => ii <= i));
                  }}
                >
                  {x.name}
                </a>
                {i !== path.length - 1 ? <i className="fa fa-caret-right mr-2" /> : null}
              </React.Fragment>
            ))}
          </h4>
        </div>
        <table className="table table-sm table-hover mt-3 mb-content table-truncate">
          <thead>
            <tr>
              <th>{i18next.t("group.issue.select.screen.group")}</th>
              <th className="text-right" style={{ width: 100 }}></th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => {
              return (
                <tr key={index}>
                  <td className="truncate">
                    {group.children.totalCount > 0 ? (
                      <a
                        href="#select"
                        onClick={ev => {
                          ev.preventDefault();

                          // Prevent double click
                          if (path[path.length - 1].id === group.id) {
                            return;
                          }

                          setPath([...path, { id: group.id, name: group.name }]);
                        }}
                      >
                        {group.name} <i className="fa fa-caret-right" />
                      </a>
                    ) : (
                      group.name
                    )}
                  </td>
                  <td className="text-right">
                    <Link
                      className="btn btn-primary btn-sm truncate"
                      to={routes.generateIssueGroupQrPath({ groupId: group.id })}
                    >
                      {i18next.t("group.issue.select.screen.issue")}
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </div>
    </div>
  );
};

const useRootIssueGroupsQuery = (memberRoles: GroupMemberRole[] | null) => {
  const appContext = useAppContext();
  const fetchIssueGroupsViaOrganization = [
    AppRole.OrganizationAdmin,
    AppRole.OrganizationIssuer
  ].some(r => appContext.roles.includes(r));

  const orgGroupQuery = useQuery<OrgIssueGroup, OrgIssueGroupVariables>(orgGroupGql, {
    variables: {
      organizationId: appContext.organization.id,
      memberRoles
    },
    fetchPolicy: "cache-and-network",
    skip: !fetchIssueGroupsViaOrganization
  });

  const meGroupQuery = useQuery<MeIssueGroups, MeIssueGroupsVariables>(meGroupsGql, {
    variables: {
      organizationId: appContext.organization.id,
      memberRoles
    },
    fetchPolicy: "cache-and-network",
    skip: fetchIssueGroupsViaOrganization
  });

  return fetchIssueGroupsViaOrganization
    ? {
        loading: orgGroupQuery.loading,
        groups:
          orgGroupQuery.data && orgGroupQuery.data.organization
            ? [orgGroupQuery.data.organization.group]
            : null,
        error: orgGroupQuery.error
      }
    : {
        loading: meGroupQuery.loading,
        groups:
          meGroupQuery.data && meGroupQuery.data.me
            ? meGroupQuery.data.me.groups.edges
                .filter(
                  x => [GroupMemberRole.ADMIN, GroupMemberRole.ISSUER].indexOf(x.role!) !== -1
                )
                .map(x => x.node)
            : null,
        error: meGroupQuery.error
      };
};

const issueGroupFragmentGql = gql`
  fragment IssueGroup on Group {
    id
    name
    children {
      totalCount
    }
    members(roles: $memberRoles) {
      totalCount
    }
  }
`;

export const meGroupsGql = gql`
  query MeIssueGroups($organizationId: String!, $memberRoles: [GroupMemberRole!]) {
    me {
      id
      groups(organizationId: $organizationId, onlyWithIssueAccess: true) {
        edges {
          node {
            ...IssueGroup
          }
          role
        }
      }
    }
  }

  ${issueGroupFragmentGql}
`;

export const orgGroupGql = gql`
  query OrgIssueGroup($organizationId: String!, $memberRoles: [GroupMemberRole!]) {
    organization(id: $organizationId) {
      id
      group {
        ...IssueGroup
      }
    }
  }

  ${issueGroupFragmentGql}
`;
