import * as React from "react";
import { HeaderValues } from "./parseImport";
import { Trans} from "react-i18next";
import i18next from 'i18next';

export const ImportInstructions = (props: {
  columnsConfig: HeaderValues[];
  children?: React.ReactNode;
}) => (
  <div className="card my-2">
    <div className="card-body">
      <h4 className="card-title"><Trans i18nKey="import.instructions.instructions" /></h4>
      <ExampleTable columnsConfig={props.columnsConfig} />
      <button
        className="btn btn-primary btn-sm"
        data-clipboard-text={props.columnsConfig.map(header => i18next.t(header.name)).join("\t")}
      >
        <Trans i18nKey="import.instructions.copyTheTableHeader" />
      </button>
      <br />
      <br />
      <ol style={{ paddingLeft: "1.2em" }}>
        <li><Trans i18nKey="import.instructions.copyTheTableHeaderWithTheButton" /></li>
        <li><Trans i18nKey="import.instructions.pastToExcelOrSimilarProgram" /></li>
        <li><Trans i18nKey="import.instructions.fillInTheTable" /></li>
        <li>
        <Trans i18nKey="import.instructions.copyAndPast" />
        </li>
      </ol>
      {props.children}
    </div>
  </div>
);

const ExampleTable = (props: { columnsConfig: HeaderValues[]}) => (
  <table className="table table-primary">
    <thead>
      <tr>
        {props.columnsConfig.map(headerName => (
          <td key={headerName.name}>
            {i18next.t(headerName.name)}
            {headerName.optional && "*"}
          </td>
        ))}
      </tr>
    </thead>
  </table>
);
