import * as React from "react";
import classNames from "classnames";

export interface TextFieldProps {
  validationErrorMessage?: React.ReactNode | null;
  loading?: boolean;
  label?: string;
  value?: string | null;
  onChange?: (value?: string) => void;
  forceShowValidationError?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

export interface TextFieldState {
  showValidationError?: boolean;
}

export class InputControl extends React.PureComponent<TextFieldProps, TextFieldState> {
  private updateShowValidationTimer?: number;

  constructor(props: TextFieldProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const props = this.props;

    const showInvalid =
      !!props.validationErrorMessage &&
      (this.state.showValidationError || props.forceShowValidationError);
    return (
      <>
        {props.label ? <label htmlFor={props.label}>{props.label}</label> : null}
        <div className="input-group">
          <input
            type="text"
            className={classNames("form-control", {
              "is-invalid": showInvalid,
              "is-loading": props.loading
            })}
            readOnly={props.readonly}
            id={props.label || undefined}
            placeholder={props.placeholder || props.label}
            autoComplete="not-enabled"
            value={props.value || ""}
            onChange={this.handleChange}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            ref={props.inputRef}
          />
          {props.children ? <div className="input-group-append">{props.children}</div> : null}
          {showInvalid ? (
            <div className="invalid-feedback">{props.validationErrorMessage}</div>
          ) : null}
        </div>
      </>
    );
  }

  public componentWillUnmount() {
    if (this.updateShowValidationTimer !== undefined) {
      clearTimeout(this.updateShowValidationTimer);
    }
  }

  private handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(e.currentTarget.value);
    }

    if (this.updateShowValidationTimer !== undefined) {
      clearTimeout(this.updateShowValidationTimer);
    }

    if (!e.currentTarget.value) {
      this.setState(() => ({ showValidationError: false }));
    } else {
      this.updateShowValidationTimer = window.setTimeout(this.updateShowValidationError, 500);
    }
  };

  private updateShowValidationError = () => {
    this.setState(() => ({ showValidationError: true }));
  };
}
