import React from "react";
import * as routes from "../../routes";
import { Link } from "react-router-dom";
import { TableCaption } from "../../Common/TableCaption";
import {
  GroupMembers_group_members,
  GroupMembers_group_members_edges,
  GroupMemberRole
} from "../../types/graphql";
import { NationalIdOrTfNumber } from "../../Common/NationalIdOrOrg";
import { useAppContext } from "../../App/GlobalContext";
import { Trans} from "react-i18next";
import i18next from 'i18next';

const roleNames = {
  [GroupMemberRole.ADMIN]: <Trans i18nKey="members.table.administrator" />,
  [GroupMemberRole.ISSUER]: <Trans i18nKey="members.table.issuer" />,
  [GroupMemberRole.USER]: ""
};

export const MembersTable = (props: {
  membersConnection: GroupMembers_group_members;
  selectedMemberEdges: { [key: string]: boolean };
  onToggledMemberEdge: (edge: GroupMembers_group_members_edges) => void;
  onToggleAll: (selected: boolean) => void;
  onAfter: (cursor: string) => void;
  onBefore: (cursor: string) => void;
  className?: string;
  showIssueAction?: boolean;
  onQrIssue: (member: GroupMembers_group_members_edges) => void;
}) => {
  const members = props.membersConnection.edges;
  const { country } = useAppContext();

  const allSelected = members.every(x => props.selectedMemberEdges[calcGroupMemberEdgeId(x)]);
  return (
    <table className={"table table-sm table-hover " + (props.className || "")}>
      <thead>
        <tr>
          <th style={{ width: 50 }}>
            <input
              type="checkbox"
              checked={allSelected}
              onChange={() => props.onToggleAll(!allSelected)}
            />
          </th>
          <th className="d-sm-none"><Trans i18nKey="members.table.person" /></th>
          <th className="d-none d-sm-table-cell"><Trans i18nKey="members.table.socialSecurityNumber" /></th>
          <th className="d-none d-sm-table-cell"><Trans i18nKey="members.table.name" /></th>
          <th className="d-none d-md-table-cell"><Trans i18nKey="members.table.group" /></th>
          <th className="d-none d-md-table-cell"><Trans i18nKey="members.table.groupRole" /></th>
          <th />
        </tr>
      </thead>
      <tbody>
        {members.map(member => {
          const edgeId = calcGroupMemberEdgeId(member);
          return (
            <tr key={edgeId}>
              <td>
                <input
                  type="checkbox"
                  checked={!!props.selectedMemberEdges![edgeId]}
                  onChange={() => props.onToggledMemberEdge(member)}
                />
              </td>
              <th className="d-sm-none truncate">
                <NationalIdOrTfNumber {...member.user} country={country} />
                <br />
                <Link to={routes.generateUserPath({ userId: member.user.id })}>
                  {member.user.name}
                </Link>
              </th>
              <td className="d-none d-sm-table-cell">
                <NationalIdOrTfNumber {...member.user} country={country} />
              </td>
              <td className="d-none d-sm-table-cell">
                <Link to={routes.generateUserPath({ userId: member.user.id })}>
                  {member.user.name}
                </Link>
              </td>
              <td className="d-none d-md-table-cell">{member.group.name}</td>
              <td className="d-none d-md-table-cell">{roleNames[member.role]}</td>
              <td className="text-right">
                {props.showIssueAction ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => props.onQrIssue(member)}
                  >
                    <Trans i18nKey="members.table.issue" />
                  </button>
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
      <TableCaption
        connectionData={props.membersConnection}
        onAfter={props.onAfter}
        onBefore={props.onBefore}
      />
    </table>
  );
};

export function calcGroupMemberEdgeId(edge: GroupMembers_group_members_edges) {
  return `UserId:${edge.user.id},GroupId:${edge.group.id},Role:${edge.role}`;
}
