import { Loa } from "../types/graphql";

export const loas = {
  [Loa.ZERO]: {
    name: "loa.low"
  },
  [Loa.ONE]: {
    name: "loa.base",
  },
  [Loa.TWO]: {
    name: "loa.high",
  },
  [Loa.THREE]: {
    name: "loa.veryHigh",
  },
  [Loa.MAX]: {
    name: "loa.veryHigh",
  },
};