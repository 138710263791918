import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { UserFragmentGql } from "../fragments";
import * as gqlTypes from "../types/graphql";

const fetchUserGql = gql`
  query FetchUser($id: String!) {
    user(id: $id) {
      ...UserFragment
    }
  }

  ${UserFragmentGql}
`;

export const useUserQuery = (userId: string) => {
  return useQuery<gqlTypes.FetchUser, gqlTypes.FetchUserVariables>(fetchUserGql, {
    variables: {
      id: userId
    },
    errorPolicy: "all"
  });
};
