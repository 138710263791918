import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

const GlobalProgressContext = React.createContext({
  increase: () => {},
  decrease: () => {}
});

const GlobalProgressCounterContext = React.createContext(0);

export const useGlobalProgressEffect = (spin: boolean) => {
  const spinContext = React.useContext(GlobalProgressContext);
  React.useEffect(() => {
    if (spin) {
      spinContext.increase();
      return () => spinContext.decrease();
    } else {
      return () => null;
    }
  }, [spin, spinContext]);
};

const progressStyle = {
  position: "fixed",
  left: 0,
  top: 0,
  right: 0,
  boxShadow: "2px 2px 2px 2px black",
  zIndex: 100
} as const;

export const GlobalProgressControl = () => {
  const spinCount = React.useContext(GlobalProgressCounterContext);

  return spinCount > 0 ? (
    <LinearProgress
      variant="query"
      style={progressStyle}
      classes={{ barColorPrimary: "bg-primary", colorPrimary: "bg-info" }}
    />
  ) : null;
};

export const GlobalProgress = (props: { hide?: boolean }) => {
  useGlobalProgressEffect(!props.hide);
  return null;
};

export const GlobalProgressContextProvider = (props: { children: React.ReactNode }) => {
  const [spinCount, setSpinCount] = React.useState(0);
  const [contextValue] = React.useState(() => ({
    increase: () => setSpinCount(x => x + 1),
    decrease: () => setSpinCount(x => x - 1)
  }));

  return (
    <GlobalProgressCounterContext.Provider value={spinCount}>
      <GlobalProgressContext.Provider value={contextValue}>
        {props.children}
      </GlobalProgressContext.Provider>
    </GlobalProgressCounterContext.Provider>
  );
};
