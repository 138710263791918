import {
  AddressControl,
  createValidator,
  DateInput,
  EmailControl,
  FormUserEmail,
  PhoneNumberControl
} from "@ist-group-private-scope/skolid-client-components";
import * as React from "react";
import { InputControl } from "../../Common/InputControl";
import { debounceTimeInMs } from "../../constants";
import { generateUserPath } from "../../routes";
import { AppContext } from "../../types";
import { FormUser } from "../../types/formUser";
import { AddressType, EmailUse } from "../../types/graphql";
import { loas } from "../../Utils/loa";
import {
  validateNationalIdAsync,
  validateNationalIdSync,
  validateTfNumberAsync,
  validateUsernameAsync
} from "../../Utils/validation";
import { Trans} from "react-i18next";
import i18next from "i18next";

export interface UserFormProps {
  user: FormUser;
  existingUserId?: string;
  forceShowValidationErrors?: boolean;
  disabled?: boolean;
  onUpdate?: (partialUpdate: Partial<FormUser>) => void;
  appContext: AppContext;
  readonly?: boolean;
}

export class UserForm extends React.PureComponent<UserFormProps, { hasMounted?: boolean }> {
  private nationalIdValidator = createValidator(debounceTimeInMs);

  constructor(props: UserFormProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const props = this.props;
    const user = props.user;
    const disabled = props.disabled;
    const readonly = props.readonly;
    const commonProps = {
      forceShowValidationError: props.forceShowValidationErrors,
      disabled,
      autoFocus: this.state.hasMounted,
      readonly
    };

    return (
      <>
        {props.existingUserId ? (
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="loa"><Trans i18nKey="user.form.levelOfTrust" /></label>
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                value={i18next.t(loas[this.props.user.loa].name) as string}
                id="loa"
              />
            </div>

            <div className="form-group col">
              <label htmlFor="lastActivity"><Trans i18nKey="user.form.lastUsed" /></label>
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                value={
                  props.user.lastActivity
                    ? new Date(props.user.lastActivity).toLocaleDateString()
                    : "-"
                }
                id="lastActivity"
              />
            </div>
          </div>
        ) : null}

        <div className="form-row">
          <div className="form-group col-md">
            <InputControl
              label={i18next.t("user.form.socialSecurityNumber")}
              loading={!!user.nationalIdValidationPromise}
              value={user.nationalId}
              onChange={this.updateNationalId}
              forceShowValidationError={props.forceShowValidationErrors}
              validationErrorMessage={user.nationalIdValidationError}
              disabled={disabled}
              readonly={readonly}
            />
          </div>

          {props.user.tfNumber || !readonly ? (
            <div className="form-group col-md">
              <label><Trans i18nKey="user.form.temporarySocialSecurityNumber" /></label>
              <TfNumberForm
                appContext={props.appContext}
                disabled={disabled}
                forceShowValidationErrors={props.forceShowValidationErrors}
                update={this.updateUser}
                user={props.user}
                readonly={readonly}
              />
            </div>
          ) : null}
        </div>

        <div className="form-row">
          <div className="form-group col">
            <InputControl
              label={i18next.t("user.form.firstName")}
              value={user.firstName}
              onChange={val =>
                this.updateUser({
                  firstName: val
                })
              }
              forceShowValidationError={this.props.forceShowValidationErrors}
              validationErrorMessage={!user.firstName ? i18next.t("user.form.firstNameIsMandatory") : undefined}
              disabled={disabled}
              readonly={readonly}
            />
          </div>
          <div className="form-group col">
            <InputControl
              label={i18next.t("user.form.surname")}
              value={user.lastName}
              onChange={val =>
                this.updateUser({
                  lastName: val
                })
              }
              forceShowValidationError={this.props.forceShowValidationErrors}
              validationErrorMessage={!user.lastName ? i18next.t("user.form.surnameIsMandatory") : undefined}
              disabled={disabled}
              readonly={readonly}
            />
          </div>
        </div>

        {props.user.username || !readonly ? (
          <div className="form-group">
            <label><Trans i18nKey="user.form.userName" /></label>
            <UsernameForm
              appContext={props.appContext}
              disabled={disabled}
              forceShowValidationErrors={props.forceShowValidationErrors}
              update={this.updateUser}
              user={props.user}
              readonly={readonly}
            />
          </div>
        ) : null} 

        {user.phoneNumbers.length > 0 ? <h3 className="my-3"><Trans i18nKey="user.form.phoneNumber" /></h3> : null}

        <div className="form-group">
          {user.phoneNumbers.map((phoneNumber, index) => (
            <PhoneNumberControl
              key={index}
              phoneNumber={phoneNumber}
              onChange={newNumber =>
                this.updateUser({
                  phoneNumbers: user.phoneNumbers.map(x => (x === phoneNumber ? newNumber : x))
                })
              }
              onRemove={() =>
                this.updateUser({
                  phoneNumbers: user.phoneNumbers.filter(x => x !== phoneNumber)
                })
              }
              client={this.props.appContext.client as any}
              className="mb-3"
              {...commonProps}
            />
          ))}
          {!readonly ? (
            <button
              type="button"
              className="btn btn-link p-0 mt-1"
              disabled={user.phoneNumbers.some(x => x.value === "") || disabled}
              onClick={() =>
                this.updateUser({ phoneNumbers: user.phoneNumbers.concat([{ value: "" }]) })
              }
            >
              <Trans i18nKey="user.form.addPhoneNumber" />
            </button>
          ) : null}
        </div>

        {user.emails.length > 0 ? (
          <>
            <h3 className="my-3"><Trans i18nKey="user.form.emailAddresses" /></h3>
          </>
        ) : null}

        <div className="form-group">
          {user.emails.map((email, index) => (
            <EmailControl
              key={index}
              userId={props.existingUserId || null}
              forceShowValidationErrors={props.forceShowValidationErrors}
              client={this.props.appContext.client}
              email={email}
              onChange={this.handleEmailChanged}
              onRemove={this.handleEmailRemoved}
              disabled={disabled}
              autoFocus={this.state.hasMounted}
              className="mb-3"
              readonly={readonly}
              organizationId={this.props.appContext.organization.id}
              onGenerateLinkToUser={userId => generateUserPath({ userId })}
            />
          ))}
          {!readonly ? (
            <button
              type="button"
              className="btn btn-link p-0 mt-1"
              disabled={user.emails.some(x => x.value === "") || disabled}
              onClick={this.handleEmailAdd}
            >
              <Trans i18nKey="user.form.addEmailAddresses" />
            </button>
          ) : null}
        </div>

        {user.addresses.length > 0 ? <h3 className="mt-3"><Trans i18nKey="user.form.addresses" /></h3> : null}

        <div className="form-group">
          {user.addresses.map((address, index) => (
            <AddressControl
              key={index}
              forceShowValidationErrors={props.forceShowValidationErrors}
              address={address}
              onChange={newAddress =>
                this.updateUser({
                  addresses: user.addresses.map(x =>
                    x === address
                      ? newAddress
                      : { ...x, primary: newAddress.primary ? false : x.primary }
                  )
                })
              }
              onRemove={() =>
                this.updateUser({ addresses: user.addresses.filter(x => x !== address) })
              }
              disabled={disabled}
              className="mb-3"
              readonly={readonly}
            />
          ))}
          {!readonly ? (
            <button
              type="button"
              className="btn btn-link p-0 mt-1"
              disabled={disabled}
              onClick={this.handleAddressAdd}
            >
              <Trans i18nKey="user.form.addAddresses" />
            </button>
          ) : null}
        </div>

        {user.externalLogins.length > 0 ? <h3 className="mt-3"><Trans i18nKey="user.form.socialLogins" /></h3> : null}

        {user.externalLogins.map((extLogin, index) => (
          <div className="form-group" key={index}>
            <InputControl readonly value={extLogin.loginProvider}></InputControl>
          </div>
        ))}
      </>
    );
  }

  public componentDidMount() {
    this.setState({ hasMounted: true });
    if (!this.props.existingUserId) {
      this.validateNationalId();
    }
  }

  public componentDidUpdate(prevProps: UserFormProps) {
    if (prevProps.user.nationalId !== this.props.user.nationalId) {
      this.validateNationalId();
    }
  }

  private validateNationalId() {
    this.nationalIdValidator(
      () => validateNationalIdSync(this.props.user, this.props.appContext.country),
      () => validateNationalIdAsync(this.props.user, this.props.appContext),
      (promise, result) =>
        this.updateUser({
          nationalIdValidationError: result,
          nationalIdValidationPromise: promise
        })
    );
  }

  private updateNationalId = (val?: string) => {
    this.updateUser({
      nationalId: val || null
    });
  };

  private updateUser = (partialUpdate: Partial<FormUser>) => {
    if (this.props.onUpdate) {
      this.props.onUpdate(partialUpdate);
    }
  };

  private handleEmailChanged = (email: FormUserEmail, old: FormUserEmail) => {
    const user = this.props.user;
    this.updateUser({
      emails: user.emails.map(x => (x === old ? email : x))
    });
  };

  private handleEmailRemoved = (email: FormUserEmail) => {
    const user = this.props.user;
    this.updateUser({
      emails: user.emails.filter(x => email !== x)
    });
  };

  private handleEmailAdd = () => {
    const user = this.props.user;
    this.updateUser({
      emails: user.emails.concat([
        {
          value: "",
          confirmed: false,
          new: true,
          use: EmailUse.PRIVATE
        }
      ])
    });
  };

  private handleAddressAdd = () => {
    const user = this.props.user;
    this.updateUser({
      addresses: user.addresses.concat([
        {
          id: null,
          streetAddress: "",
          city: "",
          postalCode: "",
          type: AddressType.HOME,
          primary: user.addresses.length === 0,
          apartmentNumber: null,
          co: null,
          country: null
        }
      ])
    });
  };
}

interface PartialUserFormProps {
  appContext: AppContext;
  user: FormUser;
  update: (partial: Partial<FormUser>) => void;
  forceShowValidationErrors?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}

class TfNumberForm extends React.PureComponent<PartialUserFormProps> {
  private validator = createValidator(debounceTimeInMs);

  public render() {
    const user = this.props.user;
    const props = this.props;
    const disabled = props.disabled;

    return (
      <InputControl
        placeholder={i18next.t("user.form.temporarySocialSecurityNumber")}
        value={user.tfNumber}
        onChange={this.updateValue}
        forceShowValidationError={props.forceShowValidationErrors}
        validationErrorMessage={user.tfNumberValidationError}
        disabled={disabled}
        loading={!!user.tfNumberValidationPromise}
        readonly={props.readonly}
      ></InputControl>
    );
  }

  public componentDidUpdate(prevProps: PartialUserFormProps) {
    if (prevProps.user.tfNumber !== this.props.user.tfNumber) {
      this.validate();
    }
  }

  private validate() {
    this.validator(
      () => null,
      () =>
        validateTfNumberAsync(
          this.props.user.tfNumber,
          this.props.user.id ?? null,
          this.props.appContext
        ),
      (promise, result) =>
        this.props.update({
          tfNumberValidationError: result,
          tfNumberValidationPromise: promise
        })
    );
  }

  private updateValue = (value: string | null | undefined) => {
    this.props.update({ tfNumber: value || "" });
  };
}

class UsernameForm extends React.PureComponent<PartialUserFormProps> {
  private validator = createValidator(debounceTimeInMs);

  public render() {
    const user = this.props.user;
    const props = this.props;
    const disabled = props.disabled;

    return (
      <InputControl
        placeholder={i18next.t("user.form.userName")}
        value={user.username}
        onChange={this.updateValue}
        forceShowValidationError={props.forceShowValidationErrors}
        validationErrorMessage={user.usernameValidationError}
        disabled={disabled}
        loading={!!user.usernameValidationPromise}
        readonly={props.readonly}
      ></InputControl>
    );
  }

  public componentDidUpdate(prevProps: PartialUserFormProps) {
    if (prevProps.user.username !== this.props.user.username) {
      this.validate();
    }
  }

  private validate() {
    this.validator(
      () => null,
      () =>
        validateUsernameAsync(
          this.props.user.username,
          this.props.user.id ?? null,
          this.props.appContext
        ),
      (promise, result) =>
        this.props.update({
          usernameValidationError: result,
          usernameValidationPromise: promise
        })
    );
  }

  private updateValue = (value: string | null | undefined) => {
    this.props.update({ username: value || "" });
  };
}
