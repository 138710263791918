const generatePath = (route: string, params: any) =>
  Object.keys(params).reduce((prev, key) => prev.replace(":" + key, params[key]), route);

export const home = "/";

export const helpRoute = "/help";

export const minimalRoute = "/minimal";
export const minimalUserRoute = "/minimal/user/:userId";
export interface MinimalUserRouteParams {
  userId: string;
}
export const generateMinimalUserPath = (params: MinimalUserRouteParams) =>
  generatePath(minimalUserRoute, params);

export const userLookupRoute = "/user-lookup";
export const userRoute = "/user/:userId";

export interface UserRouteParams {
  userId: string;
}
export const generateUserPath = (params: UserRouteParams) => generatePath(userRoute, params);

export const importRoute = "/import";

export const loggedOutRoute = "/logged-out";
export const loggedOutManualUrl = `${window.location.origin}/#${loggedOutRoute}`;
export const loggedOutAutomaticUrl = `${window.location.origin}/#${loggedOutRoute}?automatic`;

export const organizationAdminRoute = "/organization-admin";

// Group issue
export const groupIssueRoute = "/group-issue";
export const groupIssueQrRoute = "/group-issue/:groupId/qr";
export interface GroupIssueParams {
  groupId: string;
}

export const generateIssueGroupQrPath = (params: GroupIssueParams) =>
  generatePath(groupIssueQrRoute, params);

// Groups
export const groupAdminRoute = "/group-admin";
