import {
  AssignOrganizationRoleModal,
  ConfirmModal,
  NationalId,
  getRoleDisplayNames
} from "@ist-group-private-scope/skolid-client-components";
import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult, useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import { useAppContext } from "../App/GlobalContext";
import { Spinner } from "../Common/Spinner";
import { generateUserPath } from "../routes";
import {
  FetchOrganizationOverview,
  FetchOrganizationOverviewVariables,
  OrganizationRole,
  RemoveRole,
  RemoveRoleVariables
} from "../types/graphql";
import { FETCH_ORGANIZATION_OVERVIEW } from "./queries";
import { Trans} from "react-i18next";
import i18next from 'i18next';

export const OrganizationScreen = () => {
  const appContext = useAppContext();
  const [showAddMember, setShowAddMember] = React.useState(false);

  const variables = {
    organizationId: appContext.organization.id
  };

  return (
    <div className="flex-fill">
      <h1><Trans i18nKey="group.admin.screen.administerOrganisation" /></h1>
      <div className="box">
        <div className="box-body">
          <Query<FetchOrganizationOverview, FetchOrganizationOverviewVariables>
            query={FETCH_ORGANIZATION_OVERVIEW}
            variables={variables}
          >
            {result => (
              <>
                <div className="text-left">
                  <button className="btn btn-primary" onClick={() => setShowAddMember(true)}>
                    <Trans i18nKey="organisation.screen.assignRole" />
                  </button>
                </div>

                <UsersTable result={result} />
              </>
            )}
          </Query>
        </div>
      </div>

      <AssignOrganizationRoleModal
        organizationId={appContext.organization.id}
        onClose={() => setShowAddMember(false)}
        show={showAddMember}
        context={appContext}
        refetchQueries={[{ query: FETCH_ORGANIZATION_OVERVIEW, variables }]}
      />
    </div>
  );
};

const UsersTable = (props: {
  result: QueryResult<FetchOrganizationOverview, FetchOrganizationOverviewVariables>;
}) => {
  const result = props.result;

  return (
    <>
      {result.data &&
      result.data.organization &&
      result.data.organization.elevatedUsers.nodes.length > 0 ? (
        <table className="table table-hover table-fixed mb-0 mt-3">
          <thead>
            <tr>
              <th className="truncate"><Trans i18nKey="organisation.screen.name" /></th>
              <th className="truncate"><Trans i18nKey="organisation.screen.socialSecurityNumber" /></th>
              <th className="truncate"><Trans i18nKey="organisation.screen.role" /></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {result.data && result.data.organization
              ? result.data.organization.elevatedUsers.nodes.map(user => (
                  <UserRow key={user.id} user={user} />
                ))
              : null}
          </tbody>
        </table>
      ) : null}

      {result.loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : null}

      {result.error ? <div><Trans i18nKey="organisation.screen.errorOccured" /></div> : null}
    </>
  );
};

const removeRoleGql = gql`
  mutation RemoveRole($userId: String!) {
    updateUser(userId: $userId, user: { role: USER }) {
      id
    }
  }
`;

const UserRow = (props: {
  user: { id: string; nationalId: string | null; role: OrganizationRole; name: string | null };
}) => {
  const [removeRole, mutation] = useMutation<RemoveRole, RemoveRoleVariables>(removeRoleGql, {
    variables: { userId: props.user.id },
    refetchQueries: ["FetchOrganizationOverview"],
    awaitRefetchQueries: true
  });

  const [showConfirmRemoveRole, setShowConfirmRemoveRole] = React.useState(false);

  const appContext = useAppContext();
  const user = props.user;
  return (
    <tr>
      <td className="truncate" title={user.name || undefined}>
        <Link to={generateUserPath({ userId: user.id })}> {user.name ?? <Trans i18nKey="organisation.screen.nameMissing" />}</Link>
      </td>
      <td className="truncate" title={user.nationalId || ""}>
        <NationalId nationalId={user.nationalId} country={appContext.country} />
      </td>
      <td className="truncate" title={user.role || undefined}>
        {getRoleDisplayNames(user.role) || user.role}
      </td>
      <td className="text-right">
        <button className="btn btn-secondary btn-sm" onClick={() => setShowConfirmRemoveRole(true)}>
          <Trans i18nKey="organisation.screen.removeRole" />
        </button>
        <ConfirmModal
          isOpen={showConfirmRemoveRole}
          onToggle={() => setShowConfirmRemoveRole(p => !p)}
          onConfirm={async () => {
            await removeRole();
            setShowConfirmRemoveRole(false);
          }}
          header={i18next.t("organisation.screen.removeRole")}
          confirm={i18next.t("organisation.screen.remove")}
          type="danger"
        >
          <Trans i18nKey="organisation.screen.doYouWantRemoveRoleFrom" /> {user.name}?
        </ConfirmModal>
      </td>
    </tr>
  );
};
