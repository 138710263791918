import * as React from "react";
import { ImportUserTestResult, ImportUser, getImportActionMessages } from "./types";
import classNames from "classnames";
import { FieldCell } from "./FieldCell";
import * as gqlTypes from "../../types/graphql";
import { Trans} from "react-i18next";

export interface ImportTestTableProps {
  importResults: ImportUserTestResult[];
  importUsers: ImportUser[];
}

export const ImportTestTable = (props: ImportTestTableProps) => (
  <table className="table table-hover table-sm table-truncate table-responsive-sm mb-content">
    <thead>
      <tr>
        <th style={{ width: 120 }}><Trans i18nKey="import.test.table.measure" /></th>
        <th style={{ width: 130 }}><Trans i18nKey="import.test.table.socialSecurityNumber"/></th>
        <th><Trans i18nKey="import.test.table.firstName" /></th>
        <th><Trans i18nKey="import.test.table.surname" /></th>
        <th className="d-none d-md-table-cell"><Trans i18nKey="import.test.table.email" /></th>
        <th className="d-none d-lg-table-cell"><Trans i18nKey="import.test.table.mobileNumber" /></th>
      </tr>
    </thead>
    <tbody>
      {props.importResults.map(userTestResult => {
        const user = userTestResult.importUser;

        return (
          <tr
            key={userTestResult.originalImportUser.importIndex}
            className={classNames({
              "table-danger": !user.valid
            })}
          >
            <td>
              {!user.valid ? (
                <strong className="text-danger"><Trans i18nKey="import.test.table.invalid" /></strong>
              ) : (
                getImportActionMessages(userTestResult.action)
              )}
            </td>
            <FieldCell
              field={user.nationalId}
              originalValue={userTestResult.originalImportUser.nationalId}
            />
            <FieldCell
              field={user.firstName}
              originalValue={userTestResult.originalImportUser.firstName}
            />
            <FieldCell
              field={user.lastName}
              originalValue={userTestResult.originalImportUser.lastName}
            />
            <FieldCell
              className="d-none d-md-table-cell"
              field={user.email}
              originalValue={userTestResult.originalImportUser.email}
              showOriginal
            />
            <FieldCell
              className="d-none d-lg-table-cell"
              field={user.phoneNumber}
              originalValue={userTestResult.originalImportUser.phone}
            />
          </tr>
        );
      })}
    </tbody>
    <caption className="text-center">
      {(() => {
        const importTestResults = props.importResults!;
        const createCount = importTestResults.filter(
          x => x.action === gqlTypes.ImportUserAction.CREATE
        ).length;
        const updateCount = importTestResults.filter(
          x => x.action === gqlTypes.ImportUserAction.UPDATE
        ).length;
        const errorCount = importTestResults.filter(x => !x.importUser.valid).length;

        return (
          <>
            <Trans i18nKey="import.test.table.totalAccounts" values={{count:props.importUsers.length}} />{" "}
            <Trans i18nKey="import.test.table.created" count={createCount} />,{" "}
            <Trans i18nKey="import.test.table.updated" count={updateCount} />{" "}
            <Trans i18nKey="import.test.table.incorrect" count={errorCount} />
          </>
        );
      })()}
    </caption>
  </table>
);
