import { NationalId } from "@ist-group-private-scope/skolid-client-components";
import gql from "graphql-tag";
import * as React from "react";
import { QueryResult, useQuery } from "react-apollo";
import { Link, RouteComponentProps } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { isOrganizationIssuer } from "../permissions";
import * as routes from "../routes";
import { AppContext } from "../types";
import { LookupUser, LookupUserVariables } from "../types/graphql";
import { useSearchString } from "../Utils/hooks";
import { NewUserModal } from "./NewUser/NewUserModal";
import { Trans} from "react-i18next";
import i18next from 'i18next';

const lookupGql = gql`
  query LookupUser($key: String!, $orgId: String!) {
    usersByKey(key: $key, organizationId: $orgId) {
      id
      firstName
      lastName
      nationalId
      tfNumber
      username
      emails {
        value
        use
        confirmed
      }
      organization {
        id
        name
        country
      }
      issuableByUser
    }
  }
`;

export interface UserLookupScreenProps extends RouteComponentProps<any> {
  appContext: AppContext;
}

interface UserLookupScreenState {
  showNewUserModal?: boolean;
}

export const UserLookupScreen = (props: UserLookupScreenProps) => {
  const appContext = props.appContext;
  const [state, setState] = React.useState<UserLookupScreenState>({});
  const {
    setSearchString,
    debouncedSearchString: debouncedSearch,
    searchString
  } = useSearchString();

  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(ev.currentTarget.value);
  };

  const lookupResult = useQuery<LookupUser, LookupUserVariables>(lookupGql, {
    fetchPolicy: "cache-and-network",
    skip: !debouncedSearch,
    variables: { key: debouncedSearch!, orgId: appContext.organization.id }
  });

  return (
    <>
      <div className="flex-fill">
        <div className="row align-items-center mb-2">
          <h1 className="col-sm-3 m-0 mb-2"><Trans i18nKey="user.lookup.screen.user" /></h1>
          <div className="col-sm-9">
            <div className="text-sm-right">
              {isOrganizationIssuer(appContext) ? (
                <>
                  <button
                    className="btn btn-primary ml-1 mt-1"
                    onClick={() => setState({ showNewUserModal: true })}
                  >
                    <Trans i18nKey="user.lookup.screen.issueNewUser" />
                  </button>
                  &nbsp;&nbsp;
                  <Link className="btn btn-primary ml-1 mt-1" to={routes.importRoute}>
                    <Trans i18nKey="user.lookup.screen.importAndIssue" />
                  </Link>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="box mb-3">
          <div className="box-body">
            <form
              onSubmit={ev => {
                ev.preventDefault();
                lookupResult.refetch();
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder={i18next.t("user.lookup.screen.socialSecurityNumberOrEmail")}
                value={searchString}
                onChange={handleSearchInputChange}
              />
            </form>
          </div>
        </div>

        {searchString ? <LookupResult appContext={appContext} searchResult={lookupResult} /> : null}
      </div>
      {state.showNewUserModal ? (
        <NewUserModal
          appContext={appContext}
          onClose={() => setState({ showNewUserModal: false })}
        />
      ) : null}
    </>
  );
};

const LookupResult = (props: {
  searchResult: QueryResult<LookupUser, LookupUserVariables>;
  appContext: AppContext;
}) => {
  const result = props.searchResult;

  if (result.error) {
    return <div className="box-body alert alert-danger"><Trans i18nKey="user.lookup.screen.anErrorOcurred" /></div>;
  }

  if (result.loading || !result.data) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  const users = result.data.usersByKey;
  if (users.length === 0) {
    return <div className="text-center text-lg"><Trans i18nKey="user.lookup.screen.noUserFound" /></div>;
  }

  return (
    <>
      {users.map(user => (
        <UserCard key={user.id} user={user} appContext={props.appContext} />
      ))}
    </>
  );
};

const UserCard = ({
  user,
  appContext
}: {
  user: NonNullable<LookupUser["usersByKey"][0]>;
  appContext: AppContext;
}) => {
  const confirmedEmails = user.emails.filter(x => x.confirmed);
  return (
    <>
      <div className="flex-fill" style={{ marginTop: 30, marginBottom: 30 }}>
        <h3>
          {user.firstName} {user.lastName}
        </h3>
        <div className="box box-body">
          <div className="row align-items-center">
            <div className="col-md my-1">
              <strong>
                {user.nationalId
                  ? <Trans i18nKey="user.lookup.screen.socialSecurityNumber" />
                  : user.tfNumber
                  ? <Trans i18nKey="user.lookup.screen.temporatySocialSecurityNumber" />
                  : user.username
                  ? <Trans i18nKey="user.lookup.screen.username" />
                  : null}
              </strong>
              <br />
              {user.nationalId ? (
                <NationalId
                  nationalId={user.nationalId}
                  country={appContext.organization.country}
                />
              ) : user.tfNumber ? (
                user.tfNumber
              ) : user.username ? (
                user.username
              ) : null}
            </div>
            <div className="col-md my-1">
              <strong><Trans i18nKey="user.lookup.screen.email" /></strong>
              <br />
              {confirmedEmails.length > 0 ? confirmedEmails[0].value : "-"}
            </div>
            <div className="col-md my-1 text-md-right">
              {user.issuableByUser ? (
                <Link
                  className="btn btn-secondary"
                  to={routes.generateUserPath({ userId: user.id })}
                >
                  <Trans i18nKey="user.lookup.screen.viewUsers" />
                </Link>
              ) : (
                <div className="alert alert-warning m-0 text-center">
                  <Trans i18nKey="user.lookup.screen.doNotHavePermissionToIssue" />{" "}
                  <span className="text-nowrap">
                    {user.firstName} {user.lastName}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
