import { Treebeard, decorators } from "react-treebeard";
import { TreebeardContainer } from "./TreebeardContainer";
import { GroupsInOrg_organization_group } from "../../types/graphql";
import React from "react";
import { GroupAdminDetails } from "./GroupAdminDetails";
import { Group } from "../types";
import { AppContext } from "../../types";
import i18next from 'i18next';

export interface GroupsContentProps {
  groups: GroupsInOrg_organization_group[];
  onGroupsChanged: () => Promise<any>;
  appContext: AppContext;
}

export interface GroupsContentState {
  treeOptions: TreebeardOptions;
}

export class GroupsContent extends React.PureComponent<GroupsContentProps, GroupsContentState> {
  public state: GroupsContentState;
  constructor(props: GroupsContentProps) {
    super(props);
    const singleGroup = props.groups.length === 1 ? props.groups[0] : null;
    this.state = {
      treeOptions: {
        expandedGroupIds: singleGroup ? { [singleGroup.id]: true } : { null: true },
        selectedGroupId: singleGroup ? singleGroup.id : null
      }
    };
  }
  public render() {
    const props = this.props;
    const { groups } = props;
    const selectedGroup = this.state.treeOptions.selectedGroupId
      ? findGroup(this.state.treeOptions.selectedGroupId, groups)
      : null;
    const isSelectedGroupRoot = !!selectedGroup && groups.some(x => x.id === selectedGroup.id);
    return (
      <div className="flex-fill">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-none d-lg-block">
              <h1 className="invisible">{i18next.t("group.content.push")}</h1>
            </div>
            <div className="box">
              <div className="box-body">
                <h4>{i18next.t("group.content.groups")}</h4>
                {/* <GroupTree groups={groups} expandedGroupIds={this.state.treeOptions.expandedGroupIds} selectedGroupId={this.state.treeOptions.selectedGroupId} on /> */}
                <Treebeard
                  data={CalcTreebeardNodes(groups, this.state.treeOptions)}
                  onToggle={this.handleToggleNode}
                  style={treebeardStyle}
                  decorators={{
                    ...decorators,
                    Container: TreebeardContainer
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            {selectedGroup ? (
              <GroupAdminDetails
                appContext={props.appContext}
                group={selectedGroup}
                isRootGroup={isSelectedGroupRoot}
                onSelectGroup={groupId =>
                  this.setState({
                    treeOptions: { ...this.state.treeOptions, selectedGroupId: groupId }
                  })
                }
              />
            ) : (
              <h1>{i18next.t("group.content.selectGroup")}</h1>
            )}
          </div>
        </div>
      </div>
    );
  }
  private handleToggleNode = (node: TreebeardNode) => {
    this.setState(state => ({
      treeOptions: {
        selectedGroupId: node.id,
        expandedGroupIds: {
          ...state.treeOptions.expandedGroupIds,
          [node.id as string]: !(
            state.treeOptions.expandedGroupIds[node.id] &&
            state.treeOptions.selectedGroupId === node.id
          )
        }
      }
    }));
  };
}

export function findGroup(groupId: string, groups: Group[]): Group | null {
  for (let i = 0; i < groups.length; i++) {
    if (groups[i].id === groupId) {
      return groups[i];
    }

    // Children are not present on "query leaf nodes"
    if (groups[i].children) {
      const foundGroup = findGroup(groupId, groups[i].children.nodes);
      if (foundGroup) {
        return foundGroup;
      }
    }
  }

  return null;
}

interface TreebeardOptions {
  selectedGroupId?: string | null;
  expandedGroupIds: { [key: string]: boolean };
}

export function CalcTreebeardNodes(
  groups: GroupsInOrg_organization_group[],
  options: TreebeardOptions
): TreebeardNode[] {
  return groups.map(group => ({
    id: group.id,
    active: group.id === options.selectedGroupId,
    name: group.name,
    toggled: options.expandedGroupIds[group.id],
    children:
      // The query only fetches groups until a finite depth (limitation in Graphql) thus we need to guard here if depth is reached
      group.children && group.children.nodes && group.children.nodes.length > 0
        ? CalcTreebeardNodes(group.children.nodes, options)
        : null
  }));
}

export interface TreebeardNode {
  id: string;
  active: boolean;
  name: string;
  toggled: boolean;
  children: null | TreebeardNode[];
}

export const treebeardStyle = {
  tree: {
    base: {
      listStyle: "none",
      backgroundColor: "transparent",
      margin: 0,
      padding: 0,
      color: "black",
      fontSize: "14px"
    },
    node: {
      container: {
        display: "flex",
        alignItems: "center",
        height: 24
      },
      base: {
        position: "relative"
      },
      link: {
        cursor: "pointer",
        padding: "0px 15px",
        display: "flex",
        alignItems: "center"
      },
      toggle: {
        base: {
          display: "flex",
          height: "8px",
          width: "8px",
          alignItems: "center",
          margin: "0 10px 0 6px"
        },
        wrapper: {},
        height: 8,
        width: 8,
        arrow: {
          fill: "black",
          strokeWidth: 0
        }
      },
      header: {
        base: {
          cursor: "pointer",
          overflow: "hidden"
        },
        connector: {},
        title: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      },
      subtree: {
        listStyle: "none",
        paddingLeft: "14px"
      },
      loading: {
        color: "#E2C089"
      }
    }
  }
};
