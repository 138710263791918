import {
  SessionProvider,
  SessionState,
  SignContext,
  SignManager,
  SignProvider,
  useImpersonationAccessToken,
  environmentSettings
} from "@ist-group-private-scope/web-skolid";
import ApolloClient from "apollo-client";
import gql from "graphql-tag";
import * as oidc from "oidc-client";
import * as React from "react";
import { useQuery } from "react-apollo";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { GlobalProgressContextProvider, GlobalProgressControl } from "../Common/GlobalProgress";
import { GroupIssueScreen } from "../Groups/GroupIssueScreen";
import { GroupsAdminScreen } from "../Groups/GroupsAdminScreen";
import { OrganizationScreen } from "../Organization/OrganizationScreen";
import { isGroupIssuer, isIssuer, isOrganizationAdmin, isOrganizationIssuer } from "../permissions";
import * as routes from "../routes";
import { settings } from "../settings";
import { AppContext, AppContextOrganization, AppRole, AppSession } from "../types";
import { AdminIdentities, GroupMemberRole, Loa, OrganizationRole } from "../types/graphql";
import { UserScreen } from "../Users/Edit/UserScreen";
import { ImportView } from "../Users/Import/ImportView";
import { UserLookupScreen } from "../Users/UserLookupScreen";
import { useApis } from "./ApiProvider";
import { SelectOrganization } from "./ChooseOrganization";
import { ClientProvider } from "./ClientProvider";
import { ErrorScreen } from "./ErrorScreen";
import { Footer } from "./Footer";
import { ReactAppContext } from "./GlobalContext";
import { Header } from "./Header";
import { HelpScreen } from "./HelpScreen";
import { InsufficientLoaScreen } from "./InsufficientLoaScreen";
import { LoadingScreen } from "./LoadingScreen";
import { NoUserScreen } from "./LoggedOutScreen";
import { MinimalApp } from "./MinimalApp";
import { NoOrganizationScreen } from "./NoOrganizationScreen";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const App = () => {
  const history = useHistory();
  const { t , i18n} = useTranslation();

  const handleUserUpdate = (newUser: oidc.User | null) => {
    if (newUser) {
      Sentry.setUser({
        id: newUser.profile.sub,
        username: newUser.profile.name
      });
    } else {
      Sentry.configureScope(scope => scope.setUser(null));
      sessionStorage.clear();
    }
  };
  return (
    <GlobalProgressContextProvider>
      <SessionProvider
        clientId={settings.clientId}
        environment={settings.skolidEnvironment}
        localSessionTimeout={settings.localSessionTimeoutInSeconds}
        scope="openid profile roles skolid-admin.full-access"
        onUserUpdated={handleUserUpdate}
        // The state used by the session provider is the raw "history.state" which doesn't map 1:1 with react routers state
        navigate={loc => history.replace({ ...loc, state: loc.state?.state })}
      >
        {sessionState => (
          <div className="app">
            {sessionState.loading ? (
              <LoadingScreen />
            ) : (
              <Switch>
                <Route path="/">
                  {sessionState.user ? (
                    <AppWithSession sessionState={sessionState} />
                  ) : (
                    <AppWithoutSession sessionState={sessionState} />
                  )}
                </Route>
              </Switch>
            )}
            <Footer baseUrl={environmentSettings[settings.skolidEnvironment].url}/>
          </div>
        )}
      </SessionProvider>
    </GlobalProgressContextProvider>
  );
};

interface AppIdentity {
  org: AppContextOrganization;
  roles: AppRole[];
  id: string;
  name: string | null;
}

const AppWithSession = ({ sessionState }: { sessionState: SessionState }) => {
  const { adminClient } = useApis({
    accessToken: sessionState.user!.access_token,
    environment: settings.skolidEnvironment
  });

  const [selectedOrganizationId, selectOrganisationId] = React.useState<string | null>(null);

  const result = useQuery<AdminIdentities>(adminIdentitiesGql, { client: adminClient });
  const adminIdentities = result.data?.me?.adminIdentities;

  // Calculate available organisations
  const identitiesByOrganization = React.useMemo(() => {
    const orgs: {
      [key: string]: AppIdentity;
    } = {};

    adminIdentities?.forEach(identity => {
      orgs[identity.organization.id] = {
        org: identity.organization,
        id: identity.id,
        name: identity.name,
        roles: [
          identity.role === OrganizationRole.ADMIN ? AppRole.OrganizationAdmin : null,
          identity.role === OrganizationRole.ISSUER ? AppRole.OrganizationIssuer : null,
          identity.groupRole === GroupMemberRole.ADMIN ? AppRole.GroupAdmin : null,
          identity.groupRole === GroupMemberRole.ISSUER ? AppRole.GroupIssuer : null
        ].filter((x): x is AppRole => x !== null)
      };
    });

    return orgs;
  }, [adminIdentities]);

  const selectedIdentity = selectedOrganizationId
    ? identitiesByOrganization[selectedOrganizationId]
    : null;

  const availableIdentitiesArray = React.useMemo(() => Object.values(identitiesByOrganization), [
    identitiesByOrganization
  ]);

  // Auto select organisation if there is only one
  React.useLayoutEffect(() => {
    if (!selectedIdentity && availableIdentitiesArray.length === 1) {
      selectOrganisationId(availableIdentitiesArray[0].org.id);
    }
  }, [availableIdentitiesArray, selectOrganisationId, selectedIdentity]);

  // Remember selected org
  React.useEffect(() => {
    if (selectedOrganizationId) {
      sessionStorage.setItem("selected-org", selectedOrganizationId || "");
    }
  }, [selectedOrganizationId]);

  // Restore remembered selected org
  React.useLayoutEffect(
    () => {
      if (!selectedOrganizationId) {
        selectOrganisationId(sessionStorage.getItem("selected-org"));
      }
    },
    // eslint-disable-next-line
    []
  );

  const useImpersonation =
    availableIdentitiesArray.length > 1 ||
    (selectedIdentity && selectedIdentity.id !== sessionState.user!.profile.sub);
    
  const {
    accessToken: impersonationAccessToken,
    error: impersonationError,
    loading: impersonationLoading
  } = useImpersonationAccessToken({
    accessToken: sessionState.user!.access_token,
    subjectId: selectedIdentity?.id ?? null,
    skip: !useImpersonation,
    clientId: settings.clientId,
    environment: settings.skolidEnvironment
  });

  const accessToken = useImpersonation ? impersonationAccessToken : sessionState.user!.access_token;

  if (result.loading || (useImpersonation && impersonationLoading)) {
    return <LoadingScreen />;
  }

  if (result.error || impersonationError) {
    return <ErrorScreen />;
  }

  if (!selectedIdentity) {
    if (availableIdentitiesArray.length === 0) {
      return <NoOrganizationScreen />;
    }

    return (
      <SelectOrganization
        organisations={availableIdentitiesArray.map(x => x.org)}
        onOrganizationSelect={selectOrganisationId}
      />
    );
  }

  return (
    <ClientProvider
      accessToken={accessToken}
      onNotAuthenticated={() => {
        sessionState.logout();
      }}
    >
      {adminClient => (
        <SignProvider environment={settings.skolidEnvironment} user={sessionState.user}>
          <AppContextProvider
            client={adminClient}
            identity={selectedIdentity}
            login={sessionState.login}
            canChangeOrganization={availableIdentitiesArray.length > 1}
            logout={sessionState.logout}
            onChangeOrganization={selectOrganisationId}
            session={{
              loa: loaReferenceMap[sessionState.user != undefined ? sessionState.user.profile.acr as keyof typeof loaReferenceMap : ''],
              user: {
                id: selectedIdentity.id,
                name: selectedIdentity.name
              }
            }}
          />
        </SignProvider>
      )}
    </ClientProvider>
  );
};

const loaReferenceMap: { [key: string]: Loa } = {
  "https://skolid.se/loa0": Loa.ZERO,
  "https://skolid.se/loa1": Loa.ONE,
  "https://skolid.se/loa2": Loa.TWO,
  "https://skolid.se/loa3": Loa.THREE
};

const AppContextProvider = ({
  client,
  login,
  identity,
  canChangeOrganization,
  onChangeOrganization,
  logout,
  session
}: {
  client: ApolloClient<any>;
  identity: AppIdentity;
  login: (args?: any) => void;
  logout: () => void;
  canChangeOrganization: boolean;
  onChangeOrganization: (id: string | null) => void;
  session: AppSession;
}) => {
  const signManager = React.useContext<SignManager | null>(SignContext);

  const appContext: AppContext = {
    client,
    country: identity.org.country,
    locale: "sv",
    login: login,
    organization: identity.org,
    signManager: signManager!,
    roles: identity.roles,
    canChangeOrganization: canChangeOrganization,
    session: session
  };

  return (
    <ReactAppContext.Provider value={appContext}>
      <GlobalProgressControl />
      <Header
        onChangeOrganization={() => {
          onChangeOrganization(null);
        }}
        onLogout={logout}
        session={session}
        appContext={appContext}
      />
      <AppContentContainer>
        <AppContent appContext={appContext} />
      </AppContentContainer>
    </ReactAppContext.Provider>
  );
};

const AppContentContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="container main px-2 p-sm-3">{children}</div>;
};

const AppWithoutSession = ({ sessionState }: { sessionState: SessionState }) => {
  return (
    <>
      <Header />
      <AppContentContainer>
        <AppWithoutSessionContent sessionState={sessionState} />
      </AppContentContainer>
    </>
  );
};

const AppWithoutSessionContent = ({ sessionState }: { sessionState: SessionState }) => {
  const location = useLocation();
  const minimal = location.pathname.startsWith(routes.minimalRoute);

  React.useEffect(() => {
    if (minimal && !sessionState.loading) {
      sessionState.login();
    }
  }, [minimal, sessionState.loading, sessionState]);

  if (sessionState.error) {
    return <ErrorScreen />;
  }

  if (sessionState.loading) {
    return <LoadingScreen />;
  }

  if (minimal) {
    return null;
  }

  return (
    <NoUserScreen onLogin={sessionState.login} loggedOutReason={sessionState.loggedOutReason} />
  );
};

const AppContent = (props: { appContext: AppContext }) => {
  const appContext = props.appContext;
  const organization = appContext.organization;

  if (
    organization.issueLoa &&
    organization.issueLoa === Loa.THREE &&
    appContext.session.loa !== Loa.THREE
  ) {
    return <InsufficientLoaScreen appContext={appContext} />;
  }

  return (
    <Switch>
      <Route path={routes.minimalRoute} component={MinimalApp}></Route>

      {isIssuer(appContext) ? (
        <Route
          exact
          path={routes.userRoute}
          render={p => <UserScreen appContext={appContext} {...p} />}
        />
      ) : null}

      {isOrganizationIssuer(appContext) ? (
        <Route
          exact
          path={routes.importRoute}
          render={p => <ImportView appContext={appContext} {...p} />}
        />
      ) : null}

      {isIssuer(appContext) ? (
        <Route
          exact
          path={routes.userLookupRoute}
          render={p => <UserLookupScreen appContext={appContext} {...p} />}
        />
      ) : null}

      {isOrganizationAdmin(appContext) ? (
        <Route exact path={routes.organizationAdminRoute} component={OrganizationScreen} />
      ) : null}

      {isGroupIssuer(appContext) ? (
        <Route path={routes.groupIssueRoute} component={GroupIssueScreen} />
      ) : null}

      {isOrganizationAdmin(appContext) ? (
        <Route
          path={routes.groupAdminRoute}
          render={p => <GroupsAdminScreen appContext={appContext} {...p} />}
        />
      ) : null}

      <Route exact path={routes.helpRoute} component={HelpScreen} />

      <Route
        path="/"
        render={() => {
          if (isIssuer(appContext)) {
            return <Redirect to={routes.userLookupRoute} />;
          } else {
            return <p>{i18next.t("app.youLackAuthority")}</p>;
          }
        }}
      />
    </Switch>
  );
};

const adminIdentitiesGql = gql`
  query AdminIdentities {
    me {
      adminIdentities {
        id
        name
        groupRole
        role
        organization {
          id
          name
          issueLoa
          country
        }
      }
    }
  }
`;
