import { AppContext, AppRole } from "./types";

export function isIssuer(appContext: AppContext) {
  return [
    AppRole.OrganizationAdmin,
    AppRole.OrganizationIssuer,
    AppRole.GroupIssuer,
    AppRole.GroupAdmin
  ].some(x => appContext.roles.includes(x));
}

export function isGroupAdmin(appContext: AppContext) {
  return [AppRole.OrganizationAdmin, AppRole.GroupAdmin].some(x => appContext.roles.includes(x));
}

export function isOrganizationAdmin(appContext: AppContext) {
  return [AppRole.OrganizationAdmin].some(x => appContext.roles.includes(x));
}

export function isOrganizationIssuer(appContext: AppContext) {
  return [AppRole.OrganizationAdmin, AppRole.OrganizationIssuer].some(x =>
    appContext.roles.includes(x)
  );
}

export function isGroupIssuer(appContext: AppContext) {
  return [
    AppRole.OrganizationAdmin,
    AppRole.OrganizationIssuer,
    AppRole.GroupAdmin,
    AppRole.GroupIssuer
  ].some(x => appContext.roles.includes(x));
}
