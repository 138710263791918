import gql from "graphql-tag";

export const groupsForAdministratorFragmentGql = gql`
  fragment GroupsForAdministration on Group {
    ...GroupForAdministration
    children {
      nodes {
        ...GroupForAdministration
        children {
          nodes {
            ...GroupForAdministration
            children {
              nodes {
                ...GroupForAdministration
                children {
                  nodes {
                    ...GroupForAdministration
                    children {
                      nodes {
                        ...GroupForAdministration
                        children {
                          nodes {
                            ...GroupForAdministration
                            children {
                              nodes {
                                ...GroupForAdministration
                                children {
                                  nodes {
                                    ...GroupForAdministration
                                    children {
                                      nodes {
                                        ...GroupForAdministration
                                        children {
                                          nodes {
                                            ...GroupForAdministration
                                            children {
                                              nodes {
                                                ...GroupForAdministration
                                                children {
                                                  nodes {
                                                    ...GroupForAdministration
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment GroupForAdministration on Group {
    id
    name
    regularUsers: members(roles: [USER]) {
      totalCount
    }
  }
`;

export const groupMembersConnectionFragmentGql = gql`
  fragment GroupMemberUsers on GroupMembersConnection {
    edges {
      group {
        id
        name
      }
      role
      user {
        id
        name
        nationalId
        tfNumber
        username
      }
    }
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;
