/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminIdentities
// ====================================================

export interface AdminIdentities_me_adminIdentities_organization {
  __typename: "AdminIdentityOrganizationGraphType";
  id: string;
  name: string;
  issueLoa: Loa;
  country: CountryCode;
}

export interface AdminIdentities_me_adminIdentities {
  __typename: "AdminIdentityGraphType";
  id: string;
  name: string | null;
  groupRole: GroupMemberRole | null;
  role: OrganizationRole;
  organization: AdminIdentities_me_adminIdentities_organization;
}

export interface AdminIdentities_me {
  __typename: "User";
  adminIdentities: AdminIdentities_me_adminIdentities[];
}

export interface AdminIdentities {
  me: AdminIdentities_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchUser
// ====================================================

export interface FetchUser_user_organization {
  __typename: "Organization";
  id: string;
  name: string;
  country: CountryCode;
}

export interface FetchUser_user_emails {
  __typename: "Email";
  value: string;
  createdAt: any;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface FetchUser_user_phoneNumbers {
  __typename: "PhoneNumber";
  id: string;
  value: string;
  canBeUsedForSmsCodes: boolean;
  use: PhoneNumberUse;
  createdAt: any;
}

export interface FetchUser_user_addresses {
  __typename: "UserAddress";
  id: string;
  streetAddress: string;
  postalCode: string;
  country: string | null;
  city: string;
  apartmentNumber: string | null;
  co: string | null;
  type: AddressType;
  primary: boolean;
  createdAt: any;
}

export interface FetchUser_user_externalLogins {
  __typename: "ExternalLogin";
  loginProvider: string;
}

export interface FetchUser_user {
  __typename: "User";
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  loa: Loa;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  organization: FetchUser_user_organization;
  role: OrganizationRole;
  emails: FetchUser_user_emails[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: FetchUser_user_phoneNumbers[];
  blocked: boolean;
  locked: boolean;
  lastActivity: any | null;
  addresses: FetchUser_user_addresses[];
  externalLogins: FetchUser_user_externalLogins[];
  issuableByUser: boolean;
}

export interface FetchUser {
  user: FetchUser_user | null;
}

export interface FetchUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddGroupMembers
// ====================================================

export interface AddGroupMembers_addGroupMembers {
  __typename: "AddGroupMembersResult";
  dummy: boolean | null;
}

export interface AddGroupMembers {
  /**
   * Adds users to groups.
   */
  addGroupMembers: AddGroupMembers_addGroupMembers | null;
}

export interface AddGroupMembersVariables {
  organizationId: string;
  userEdges: GroupMemberEdgeInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupMembers
// ====================================================

export interface GroupMembers_group_members_edges_group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GroupMembers_group_members_edges_user {
  __typename: "User";
  id: string;
  name: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
}

export interface GroupMembers_group_members_edges {
  __typename: "GroupMemberEdge";
  group: GroupMembers_group_members_edges_group;
  role: GroupMemberRole;
  user: GroupMembers_group_members_edges_user;
}

export interface GroupMembers_group_members_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  startCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface GroupMembers_group_members {
  __typename: "GroupMembersConnection";
  edges: GroupMembers_group_members_edges[];
  totalCount: number;
  pageInfo: GroupMembers_group_members_pageInfo;
}

export interface GroupMembers_group {
  __typename: "Group";
  id: string;
  members: GroupMembers_group_members;
}

export interface GroupMembers {
  group: GroupMembers_group | null;
}

export interface GroupMembersVariables {
  organizationId: string;
  groupId: string;
  includeDescendants: boolean;
  roles?: GroupMemberRole[] | null;
  filter?: string | null;
  after?: string | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGroupMembers
// ====================================================

export interface RemoveGroupMembers_removeGroupMembers {
  __typename: "RemoveGroupMembersResult";
  dummy: boolean | null;
}

export interface RemoveGroupMembers {
  /**
   * Remove users from groups.
   */
  removeGroupMembers: RemoveGroupMembers_removeGroupMembers | null;
}

export interface RemoveGroupMembersVariables {
  organizationId: string;
  edges: GroupMemberEdgeIdentifierInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGroupName
// ====================================================

export interface FetchGroupName_group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface FetchGroupName {
  group: FetchGroupName_group | null;
}

export interface FetchGroupNameVariables {
  organizationId: string;
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGroup
// ====================================================

export interface FetchGroup_group_members_edges_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  lastIssued: any | null;
}

export interface FetchGroup_group_members_edges_relations_targetUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  lastIssued: any | null;
}

export interface FetchGroup_group_members_edges_relations {
  __typename: "GroupMemberRelationGraphType";
  targetUser: FetchGroup_group_members_edges_relations_targetUser;
}

export interface FetchGroup_group_members_edges {
  __typename: "GroupMemberEdge";
  role: GroupMemberRole;
  user: FetchGroup_group_members_edges_user;
  relations: FetchGroup_group_members_edges_relations[];
}

export interface FetchGroup_group_members_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  endCursor: string | null;
  startCursor: string | null;
}

export interface FetchGroup_group_members {
  __typename: "GroupMembersConnection";
  edges: FetchGroup_group_members_edges[];
  pageInfo: FetchGroup_group_members_pageInfo;
}

export interface FetchGroup_group {
  __typename: "Group";
  id: string;
  name: string;
  members: FetchGroup_group_members;
}

export interface FetchGroup {
  group: FetchGroup_group | null;
}

export interface FetchGroupVariables {
  organizationId: string;
  groupId: string;
  includeRoles?: GroupMemberRole[] | null;
  before?: string | null;
  after?: string | null;
  onlyWithRelations: boolean;
  includeSubGroups: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IssueToGroup
// ====================================================

export interface IssueToGroup_issueUsersToActivationRequests_users {
  __typename: "User";
  id: string;
}

export interface IssueToGroup_issueUsersToActivationRequests {
  __typename: "IssueUsersToActivationRequestsResult";
  users: IssueToGroup_issueUsersToActivationRequests_users[];
}

export interface IssueToGroup {
  /**
   * Issues an existing user to a person.
   */
  issueUsersToActivationRequests: IssueToGroup_issueUsersToActivationRequests | null;
}

export interface IssueToGroupVariables {
  requests: IssueUsersToActivationRequestsInput[];
  options: IssueUsersOptionsInput;
  signatures: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateIssueUsersContract
// ====================================================

export interface GenerateIssueUsersContract_generateIssueUsersContract {
  __typename: "MultiContract";
  text: string;
  data: string[];
}

export interface GenerateIssueUsersContract {
  /**
   * Generates contracts for issuing users.
   */
  generateIssueUsersContract: GenerateIssueUsersContract_generateIssueUsersContract | null;
}

export interface GenerateIssueUsersContractVariables {
  userIds: string[];
  options: IssueUsersOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectGroupNavigation
// ====================================================

export interface SelectGroupNavigation_group_children_nodes_children {
  __typename: "GroupsConnection";
  totalCount: number;
}

export interface SelectGroupNavigation_group_children_nodes_members {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface SelectGroupNavigation_group_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: SelectGroupNavigation_group_children_nodes_children;
  members: SelectGroupNavigation_group_children_nodes_members;
}

export interface SelectGroupNavigation_group_children {
  __typename: "GroupsConnection";
  nodes: SelectGroupNavigation_group_children_nodes[];
}

export interface SelectGroupNavigation_group {
  __typename: "Group";
  id: string;
  name: string;
  children: SelectGroupNavigation_group_children;
}

export interface SelectGroupNavigation {
  group: SelectGroupNavigation_group | null;
}

export interface SelectGroupNavigationVariables {
  groupId: string;
  orgId: string;
  memberRoles?: GroupMemberRole[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeIssueGroups
// ====================================================

export interface MeIssueGroups_me_groups_edges_node_children {
  __typename: "GroupsConnection";
  totalCount: number;
}

export interface MeIssueGroups_me_groups_edges_node_members {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface MeIssueGroups_me_groups_edges_node {
  __typename: "Group";
  id: string;
  name: string;
  children: MeIssueGroups_me_groups_edges_node_children;
  members: MeIssueGroups_me_groups_edges_node_members;
}

export interface MeIssueGroups_me_groups_edges {
  __typename: "UserGroupEdge";
  node: MeIssueGroups_me_groups_edges_node;
  role: GroupMemberRole;
}

export interface MeIssueGroups_me_groups {
  __typename: "UserGroupsConnection";
  edges: MeIssueGroups_me_groups_edges[];
}

export interface MeIssueGroups_me {
  __typename: "User";
  id: string;
  groups: MeIssueGroups_me_groups;
}

export interface MeIssueGroups {
  me: MeIssueGroups_me | null;
}

export interface MeIssueGroupsVariables {
  organizationId: string;
  memberRoles?: GroupMemberRole[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgIssueGroup
// ====================================================

export interface OrgIssueGroup_organization_group_children {
  __typename: "GroupsConnection";
  totalCount: number;
}

export interface OrgIssueGroup_organization_group_members {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface OrgIssueGroup_organization_group {
  __typename: "Group";
  id: string;
  name: string;
  children: OrgIssueGroup_organization_group_children;
  members: OrgIssueGroup_organization_group_members;
}

export interface OrgIssueGroup_organization {
  __typename: "Organization";
  id: string;
  group: OrgIssueGroup_organization_group;
}

export interface OrgIssueGroup {
  organization: OrgIssueGroup_organization | null;
}

export interface OrgIssueGroupVariables {
  organizationId: string;
  memberRoles?: GroupMemberRole[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsForUser
// ====================================================

export interface GroupsForUser_user_groups_edges_node_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_children_nodes_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children_nodes_children;
}

export interface GroupsForUser_user_groups_edges_node_children {
  __typename: "GroupsConnection";
  nodes: GroupsForUser_user_groups_edges_node_children_nodes[];
}

export interface GroupsForUser_user_groups_edges_node {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForUser_user_groups_edges_node_regularUsers;
  children: GroupsForUser_user_groups_edges_node_children;
}

export interface GroupsForUser_user_groups_edges {
  __typename: "UserGroupEdge";
  node: GroupsForUser_user_groups_edges_node;
}

export interface GroupsForUser_user_groups {
  __typename: "UserGroupsConnection";
  edges: GroupsForUser_user_groups_edges[];
}

export interface GroupsForUser_user {
  __typename: "User";
  groups: GroupsForUser_user_groups;
}

export interface GroupsForUser {
  user: GroupsForUser_user | null;
}

export interface GroupsForUserVariables {
  organizationId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsInOrg
// ====================================================

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes_children_nodes[];
}

export interface GroupsInOrg_organization_group_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children_nodes_children;
}

export interface GroupsInOrg_organization_group_children {
  __typename: "GroupsConnection";
  nodes: GroupsInOrg_organization_group_children_nodes[];
}

export interface GroupsInOrg_organization_group {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsInOrg_organization_group_children;
}

export interface GroupsInOrg_organization {
  __typename: "Organization";
  id: string;
  group: GroupsInOrg_organization_group;
}

export interface GroupsInOrg {
  organization: GroupsInOrg_organization | null;
}

export interface GroupsInOrgVariables {
  organizationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveRole
// ====================================================

export interface RemoveRole_updateUser {
  __typename: "User";
  id: string;
}

export interface RemoveRole {
  /**
   * Updates a user. This operation supports partial updates.
   */
  updateUser: RemoveRole_updateUser | null;
}

export interface RemoveRoleVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchOrganizationOverview
// ====================================================

export interface FetchOrganizationOverview_organization_elevatedUsers_nodes {
  __typename: "User";
  id: string;
  name: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  role: OrganizationRole;
}

export interface FetchOrganizationOverview_organization_elevatedUsers {
  __typename: "UserConnection";
  nodes: FetchOrganizationOverview_organization_elevatedUsers_nodes[];
}

export interface FetchOrganizationOverview_organization {
  __typename: "Organization";
  id: string;
  elevatedUsers: FetchOrganizationOverview_organization_elevatedUsers;
}

export interface FetchOrganizationOverview {
  organization: FetchOrganizationOverview_organization | null;
}

export interface FetchOrganizationOverviewVariables {
  organizationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser_organization {
  __typename: "Organization";
  id: string;
  name: string;
  country: CountryCode;
}

export interface UpdateUser_updateUser_emails {
  __typename: "Email";
  value: string;
  createdAt: any;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface UpdateUser_updateUser_phoneNumbers {
  __typename: "PhoneNumber";
  id: string;
  value: string;
  canBeUsedForSmsCodes: boolean;
  use: PhoneNumberUse;
  createdAt: any;
}

export interface UpdateUser_updateUser_addresses {
  __typename: "UserAddress";
  id: string;
  streetAddress: string;
  postalCode: string;
  country: string | null;
  city: string;
  apartmentNumber: string | null;
  co: string | null;
  type: AddressType;
  primary: boolean;
  createdAt: any;
}

export interface UpdateUser_updateUser_externalLogins {
  __typename: "ExternalLogin";
  loginProvider: string;
}

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  loa: Loa;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  organization: UpdateUser_updateUser_organization;
  role: OrganizationRole;
  emails: UpdateUser_updateUser_emails[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: UpdateUser_updateUser_phoneNumbers[];
  blocked: boolean;
  locked: boolean;
  lastActivity: any | null;
  addresses: UpdateUser_updateUser_addresses[];
  externalLogins: UpdateUser_updateUser_externalLogins[];
  issuableByUser: boolean;
}

export interface UpdateUser {
  /**
   * Updates a user. This operation supports partial updates.
   */
  updateUser: UpdateUser_updateUser | null;
}

export interface UpdateUserVariables {
  userId: string;
  user: UpdateUserInput;
  options: UpdateUserOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlockUser
// ====================================================

export interface BlockUser_blockUser_organization {
  __typename: "Organization";
  id: string;
  name: string;
  country: CountryCode;
}

export interface BlockUser_blockUser_emails {
  __typename: "Email";
  value: string;
  createdAt: any;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface BlockUser_blockUser_phoneNumbers {
  __typename: "PhoneNumber";
  id: string;
  value: string;
  canBeUsedForSmsCodes: boolean;
  use: PhoneNumberUse;
  createdAt: any;
}

export interface BlockUser_blockUser_addresses {
  __typename: "UserAddress";
  id: string;
  streetAddress: string;
  postalCode: string;
  country: string | null;
  city: string;
  apartmentNumber: string | null;
  co: string | null;
  type: AddressType;
  primary: boolean;
  createdAt: any;
}

export interface BlockUser_blockUser_externalLogins {
  __typename: "ExternalLogin";
  loginProvider: string;
}

export interface BlockUser_blockUser {
  __typename: "User";
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  loa: Loa;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  organization: BlockUser_blockUser_organization;
  role: OrganizationRole;
  emails: BlockUser_blockUser_emails[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: BlockUser_blockUser_phoneNumbers[];
  blocked: boolean;
  locked: boolean;
  lastActivity: any | null;
  addresses: BlockUser_blockUser_addresses[];
  externalLogins: BlockUser_blockUser_externalLogins[];
  issuableByUser: boolean;
}

export interface BlockUser {
  /**
   * Blocks a user so it may not be able to log in via SkolID login methods.
   */
  blockUser: BlockUser_blockUser | null;
}

export interface BlockUserVariables {
  userId: string;
  reason: string;
  signature: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateBlockUserContract
// ====================================================

export interface GenerateBlockUserContract_generateBlockUserContract {
  __typename: "Contract";
  data: string;
  text: string;
}

export interface GenerateBlockUserContract {
  /**
   * Generates a contract that has to be signed to block a user.
   */
  generateBlockUserContract: GenerateBlockUserContract_generateBlockUserContract | null;
}

export interface GenerateBlockUserContractVariables {
  userId: string;
  reason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserByOrganizationAdmin
// ====================================================

export interface DeleteUserByOrganizationAdmin {
  /**
   * This API intended to be used in less security clients applications by
   * organization admin or organization group admin for delete users. This can be
   * used only by client on behalf of user, but not the client itself.
   */
  deleteUserByOrganizationAdmin: boolean | null;
}

export interface DeleteUserByOrganizationAdminVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TestImportUsers
// ====================================================

export interface TestImportUsers_testImportAndIssueUsers_entries_importUser_nationalId {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface TestImportUsers_testImportAndIssueUsers_entries_importUser_email {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface TestImportUsers_testImportAndIssueUsers_entries_importUser_firstName {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface TestImportUsers_testImportAndIssueUsers_entries_importUser_lastName {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface TestImportUsers_testImportAndIssueUsers_entries_importUser_phoneNumber {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface TestImportUsers_testImportAndIssueUsers_entries_importUser {
  __typename: "ImportUser";
  valid: boolean;
  nationalId: TestImportUsers_testImportAndIssueUsers_entries_importUser_nationalId;
  email: TestImportUsers_testImportAndIssueUsers_entries_importUser_email;
  firstName: TestImportUsers_testImportAndIssueUsers_entries_importUser_firstName;
  lastName: TestImportUsers_testImportAndIssueUsers_entries_importUser_lastName;
  phoneNumber: TestImportUsers_testImportAndIssueUsers_entries_importUser_phoneNumber;
}

export interface TestImportUsers_testImportAndIssueUsers_entries {
  __typename: "ImportUserResult";
  action: ImportUserAction;
  importUser: TestImportUsers_testImportAndIssueUsers_entries_importUser;
}

export interface TestImportUsers_testImportAndIssueUsers {
  __typename: "TestImportAndIssueUsersResult";
  entries: TestImportUsers_testImportAndIssueUsers_entries[];
}

export interface TestImportUsers {
  /**
   * Validates and tests import users. The validation makes sure that the input
   * data confirms to the data requirements, eventual errors is indicated in the
   * response. The testing makes sure that the input data does not conflict with
   * any of the existing data, the expected outcome of a real operation is
   * indicated in the response.
   */
  testImportAndIssueUsers: TestImportUsers_testImportAndIssueUsers;
}

export interface TestImportUsersVariables {
  organizationId: string;
  users: ImportUserInput[];
  options: ImportAndIssueUsersOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportAndIssueUsers
// ====================================================

export interface ImportAndIssueUsers_importAndIssueUsers_entries {
  __typename: "ImportAndIssueUserResult";
  errors: string[];
  activationCode: string | null;
  activationCodeExpiresAt: any | null;
}

export interface ImportAndIssueUsers_importAndIssueUsers {
  __typename: "ImportAndIssueUsersResult";
  entries: ImportAndIssueUsers_importAndIssueUsers_entries[];
  activationCodesExpiresAt: any | null;
}

export interface ImportAndIssueUsers {
  /**
   * Imports and issues users. Users which already exists will be partially updated and issued again.
   */
  importAndIssueUsers: ImportAndIssueUsers_importAndIssueUsers;
}

export interface ImportAndIssueUsersVariables {
  organizationId: string;
  users: ImportUserInput[];
  options: ImportAndIssueUsersOptionsInput;
  signatures: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateImportAndIssueUsersContract
// ====================================================

export interface GenerateImportAndIssueUsersContract_generateImportAndIssueUsersContract {
  __typename: "MultiContract";
  text: string;
  data: string[];
}

export interface GenerateImportAndIssueUsersContract {
  /**
   * Generates contracts for importing and issuing users.
   */
  generateImportAndIssueUsersContract: GenerateImportAndIssueUsersContract_generateImportAndIssueUsersContract;
}

export interface GenerateImportAndIssueUsersContractVariables {
  organizationId: string;
  users: ImportUserInput[];
  options: ImportAndIssueUsersOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateCreateAndIssueUserContract
// ====================================================

export interface GenerateCreateAndIssueUserContract_generateCreateAndIssueUserContract {
  __typename: "Contract";
  text: string;
  data: string;
}

export interface GenerateCreateAndIssueUserContract {
  generateCreateAndIssueUserContract: GenerateCreateAndIssueUserContract_generateCreateAndIssueUserContract;
}

export interface GenerateCreateAndIssueUserContractVariables {
  organizationId: string;
  user: CreateUserInput;
  options: CreateAndIssueUserOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAndIssueUser
// ====================================================

export interface CreateAndIssueUser_createAndIssueUser_user_organization {
  __typename: "Organization";
  id: string;
  name: string;
  country: CountryCode;
}

export interface CreateAndIssueUser_createAndIssueUser_user_emails {
  __typename: "Email";
  value: string;
  createdAt: any;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface CreateAndIssueUser_createAndIssueUser_user_phoneNumbers {
  __typename: "PhoneNumber";
  id: string;
  value: string;
  canBeUsedForSmsCodes: boolean;
  use: PhoneNumberUse;
  createdAt: any;
}

export interface CreateAndIssueUser_createAndIssueUser_user_addresses {
  __typename: "UserAddress";
  id: string;
  streetAddress: string;
  postalCode: string;
  country: string | null;
  city: string;
  apartmentNumber: string | null;
  co: string | null;
  type: AddressType;
  primary: boolean;
  createdAt: any;
}

export interface CreateAndIssueUser_createAndIssueUser_user_externalLogins {
  __typename: "ExternalLogin";
  loginProvider: string;
}

export interface CreateAndIssueUser_createAndIssueUser_user {
  __typename: "User";
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  loa: Loa;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  organization: CreateAndIssueUser_createAndIssueUser_user_organization;
  role: OrganizationRole;
  emails: CreateAndIssueUser_createAndIssueUser_user_emails[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: CreateAndIssueUser_createAndIssueUser_user_phoneNumbers[];
  blocked: boolean;
  locked: boolean;
  lastActivity: any | null;
  addresses: CreateAndIssueUser_createAndIssueUser_user_addresses[];
  externalLogins: CreateAndIssueUser_createAndIssueUser_user_externalLogins[];
  issuableByUser: boolean;
}

export interface CreateAndIssueUser_createAndIssueUser {
  __typename: "IssueUserResult";
  user: CreateAndIssueUser_createAndIssueUser_user;
  activationCode: string | null;
  activationCodeExpiresAt: any | null;
}

export interface CreateAndIssueUser {
  /**
   * Creates a new user and issues it to a person.
   */
  createAndIssueUser: CreateAndIssueUser_createAndIssueUser | null;
}

export interface CreateAndIssueUserVariables {
  organizationId: string;
  user: CreateUserInput;
  options: CreateAndIssueUserOptionsInput;
  signature: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser {
  __typename: "User";
  id: string;
}

export interface CreateUser {
  /**
   * Creates a new user.
   */
  createUser: CreateUser_createUser | null;
}

export interface CreateUserVariables {
  organizationId: string;
  user: CreateUserInput;
  options: CreateUserOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LookupUser
// ====================================================

export interface LookupUser_usersByKey_emails {
  __typename: "Email";
  value: string;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface LookupUser_usersByKey_organization {
  __typename: "Organization";
  id: string;
  name: string;
  country: CountryCode;
}

export interface LookupUser_usersByKey {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  emails: LookupUser_usersByKey_emails[];
  organization: LookupUser_usersByKey_organization;
  issuableByUser: boolean;
}

export interface LookupUser {
  /**
   * Find all matching users in the given organization. NOTE that even if an
   * organizationId is given several users may be matched if several user fields
   * match (for instance both username and email).
   */
  usersByKey: LookupUser_usersByKey[];
}

export interface LookupUserVariables {
  key: string;
  orgId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckTfNumber
// ====================================================

export interface CheckTfNumber_userByTfNumber {
  __typename: "User";
  id: string;
}

export interface CheckTfNumber {
  userByTfNumber: CheckTfNumber_userByTfNumber | null;
}

export interface CheckTfNumberVariables {
  value: string;
  orgId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckUsername
// ====================================================

export interface CheckUsername_userByUsername {
  __typename: "User";
  id: string;
}

export interface CheckUsername {
  userByUsername: CheckUsername_userByUsername | null;
}

export interface CheckUsernameVariables {
  value: string;
  orgId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment_organization {
  __typename: "Organization";
  id: string;
  name: string;
  country: CountryCode;
}

export interface UserFragment_emails {
  __typename: "Email";
  value: string;
  createdAt: any;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface UserFragment_phoneNumbers {
  __typename: "PhoneNumber";
  id: string;
  value: string;
  canBeUsedForSmsCodes: boolean;
  use: PhoneNumberUse;
  createdAt: any;
}

export interface UserFragment_addresses {
  __typename: "UserAddress";
  id: string;
  streetAddress: string;
  postalCode: string;
  country: string | null;
  city: string;
  apartmentNumber: string | null;
  co: string | null;
  type: AddressType;
  primary: boolean;
  createdAt: any;
}

export interface UserFragment_externalLogins {
  __typename: "ExternalLogin";
  loginProvider: string;
}

export interface UserFragment {
  __typename: "User";
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  loa: Loa;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  organization: UserFragment_organization;
  role: OrganizationRole;
  emails: UserFragment_emails[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: UserFragment_phoneNumbers[];
  blocked: boolean;
  locked: boolean;
  lastActivity: any | null;
  addresses: UserFragment_addresses[];
  externalLogins: UserFragment_externalLogins[];
  issuableByUser: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmailFragment
// ====================================================

export interface EmailFragment {
  __typename: "Email";
  value: string;
  createdAt: any;
  use: EmailUse | null;
  confirmed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressFragment
// ====================================================

export interface AddressFragment {
  __typename: "UserAddress";
  id: string;
  streetAddress: string;
  postalCode: string;
  country: string | null;
  city: string;
  apartmentNumber: string | null;
  co: string | null;
  type: AddressType;
  primary: boolean;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PhoneNumberFragment
// ====================================================

export interface PhoneNumberFragment {
  __typename: "PhoneNumber";
  id: string;
  value: string;
  canBeUsedForSmsCodes: boolean;
  use: PhoneNumberUse;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupsForAdministration
// ====================================================

export interface GroupsForAdministration_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children_nodes_children;
}

export interface GroupsForAdministration_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes_children_nodes[];
}

export interface GroupsForAdministration_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_children_nodes_regularUsers;
  children: GroupsForAdministration_children_nodes_children;
}

export interface GroupsForAdministration_children {
  __typename: "GroupsConnection";
  nodes: GroupsForAdministration_children_nodes[];
}

export interface GroupsForAdministration {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupsForAdministration_regularUsers;
  children: GroupsForAdministration_children;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupForAdministration
// ====================================================

export interface GroupForAdministration_regularUsers {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface GroupForAdministration {
  __typename: "Group";
  id: string;
  name: string;
  regularUsers: GroupForAdministration_regularUsers;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupMemberUsers
// ====================================================

export interface GroupMemberUsers_edges_group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GroupMemberUsers_edges_user {
  __typename: "User";
  id: string;
  name: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
}

export interface GroupMemberUsers_edges {
  __typename: "GroupMemberEdge";
  group: GroupMemberUsers_edges_group;
  role: GroupMemberRole;
  user: GroupMemberUsers_edges_user;
}

export interface GroupMemberUsers_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  startCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface GroupMemberUsers {
  __typename: "GroupMembersConnection";
  edges: GroupMemberUsers_edges[];
  totalCount: number;
  pageInfo: GroupMemberUsers_pageInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupIssueUser
// ====================================================

export interface GroupIssueUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  lastIssued: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IssueGroup
// ====================================================

export interface IssueGroup_children {
  __typename: "GroupsConnection";
  totalCount: number;
}

export interface IssueGroup_members {
  __typename: "GroupMembersConnection";
  totalCount: number;
}

export interface IssueGroup {
  __typename: "Group";
  id: string;
  name: string;
  children: IssueGroup_children;
  members: IssueGroup_members;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupsForOrgAdmin
// ====================================================

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children_nodes_children;
}

export interface GroupsForOrgAdmin_children_nodes_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes_children_nodes[];
}

export interface GroupsForOrgAdmin_children_nodes {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children_nodes_children;
}

export interface GroupsForOrgAdmin_children {
  __typename: "GroupsConnection";
  nodes: GroupsForOrgAdmin_children_nodes[];
}

export interface GroupsForOrgAdmin {
  __typename: "Group";
  id: string;
  name: string;
  children: GroupsForOrgAdmin_children;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupForOrgAdmin
// ====================================================

export interface GroupForOrgAdmin {
  __typename: "Group";
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationOverview
// ====================================================

export interface OrganizationOverview_elevatedUsers_nodes {
  __typename: "User";
  id: string;
  name: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  role: OrganizationRole;
}

export interface OrganizationOverview_elevatedUsers {
  __typename: "UserConnection";
  nodes: OrganizationOverview_elevatedUsers_nodes[];
}

export interface OrganizationOverview {
  __typename: "Organization";
  id: string;
  elevatedUsers: OrganizationOverview_elevatedUsers;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImportUserFieldFragment
// ====================================================

export interface ImportUserFieldFragment {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImportUserFragment
// ====================================================

export interface ImportUserFragment_nationalId {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserFragment_email {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserFragment_firstName {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserFragment_lastName {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserFragment_phoneNumber {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserFragment {
  __typename: "ImportUser";
  valid: boolean;
  nationalId: ImportUserFragment_nationalId;
  email: ImportUserFragment_email;
  firstName: ImportUserFragment_firstName;
  lastName: ImportUserFragment_lastName;
  phoneNumber: ImportUserFragment_phoneNumber;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImportUserResultFragment
// ====================================================

export interface ImportUserResultFragment_importUser_nationalId {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserResultFragment_importUser_email {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserResultFragment_importUser_firstName {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserResultFragment_importUser_lastName {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserResultFragment_importUser_phoneNumber {
  __typename: "ImportUserField";
  normalizedValue: string | null;
  status: ImportUserFieldStatus;
}

export interface ImportUserResultFragment_importUser {
  __typename: "ImportUser";
  valid: boolean;
  nationalId: ImportUserResultFragment_importUser_nationalId;
  email: ImportUserResultFragment_importUser_email;
  firstName: ImportUserResultFragment_importUser_firstName;
  lastName: ImportUserResultFragment_importUser_lastName;
  phoneNumber: ImportUserResultFragment_importUser_phoneNumber;
}

export interface ImportUserResultFragment {
  __typename: "ImportUserResult";
  action: ImportUserAction;
  importUser: ImportUserResultFragment_importUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImportAndIssueUserResultFragment
// ====================================================

export interface ImportAndIssueUserResultFragment {
  __typename: "ImportAndIssueUserResult";
  errors: string[];
  activationCode: string | null;
  activationCodeExpiresAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressType {
  HOME = "HOME",
  OFFICIAL = "OFFICIAL",
  OTHER = "OTHER",
}

export enum CountryCode {
  DE = "DE",
  DK = "DK",
  NO = "NO",
  SE = "SE",
}

export enum EmailUse {
  PRIVATE = "PRIVATE",
  PROFESSIONAL = "PROFESSIONAL",
  SKOLID_INTERNAL = "SKOLID_INTERNAL",
}

export enum GroupMemberRole {
  ADMIN = "ADMIN",
  ISSUER = "ISSUER",
  USER = "USER",
}

export enum ImportUserAction {
  CREATE = "CREATE",
  ERROR = "ERROR",
  IGNORED = "IGNORED",
  NOT_MODIFIED = "NOT_MODIFIED",
  UPDATE = "UPDATE",
}

export enum ImportUserFieldStatus {
  CONFLICT = "CONFLICT",
  INVALID = "INVALID",
  MISSING = "MISSING",
  VALID = "VALID",
}

export enum IssueMethod {
  ACTIVATION_REQUEST = "ACTIVATION_REQUEST",
  CODE = "CODE",
  EMAIL = "EMAIL",
  EMAIL_SMS = "EMAIL_SMS",
}

export enum Loa {
  MAX = "MAX",
  ONE = "ONE",
  THREE = "THREE",
  TWO = "TWO",
  ZERO = "ZERO",
}

export enum OrganizationRole {
  ADMIN = "ADMIN",
  ISSUER = "ISSUER",
  USER = "USER",
}

export enum PhoneNumberUse {
  ONLY_SMS_CODES = "ONLY_SMS_CODES",
  PRIVATE = "PRIVATE",
  PROFESSIONAL = "PROFESSIONAL",
}

export interface AddressInput {
  id?: string | null;
  primary?: boolean | null;
  streetAddress: string;
  city: string;
  postalCode: string;
  apartmentNumber?: string | null;
  co?: string | null;
  country?: string | null;
  type: AddressType;
}

/**
 * Indicates the LOA, issue method and through which organization the user is being issued.
 */
export interface CreateAndIssueUserOptionsInput {
  method: IssueMethod;
  loa: Loa;
  silent?: boolean | null;
}

export interface CreateUserInput {
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  tfNumber?: string | null;
  username?: string | null;
  role?: OrganizationRole | null;
  loa?: Loa | null;
  phoneNumber?: CreateUserPhoneNumberInput | null;
  password?: string | null;
  securityLogData?: string | null;
  emails?: EmailInput[] | null;
  phoneNumbers?: PhoneNumberInput[] | null;
  addresses?: AddressInput[] | null;
  expiresAt?: any | null;
}

export interface CreateUserOptionsInput {
  sendWelcomeEmail?: boolean | null;
  silent?: boolean | null;
}

export interface CreateUserPhoneNumberInput {
  value: string;
  confirmed: boolean;
}

export interface EmailInput {
  use?: EmailUse | null;
  value: string;
  confirmed: boolean;
}

export interface GroupMemberEdgeIdentifierInput {
  userId: string;
  groupId: string;
  role: GroupMemberRole;
}

export interface GroupMemberEdgeInput {
  userId: string;
  groupId: string;
  role: GroupMemberRole;
  expiresAt?: any | null;
  confirmedAt?: any | null;
}

/**
 * Indicates the LOA, issue method and through which organization and country the users are being issued.
 */
export interface ImportAndIssueUsersOptionsInput {
  issueMethod: IssueMethod;
  loa: Loa;
}

export interface ImportUserEmailInput {
  value?: string | null;
}

/**
 * User to be imported.
 */
export interface ImportUserInput {
  nationalId?: string | null;
  tfNumber?: string | null;
  nationalIdOrTfNumber?: string | null;
  username?: string | null;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  email?: string | null;
  emails?: ImportUserEmailInput[] | null;
  password?: string | null;
  expiresAt?: any | null;
}

/**
 * Indicates the LOA, issue method and through which organization the users are being issued.
 */
export interface IssueUsersOptionsInput {
  issueMethod: IssueMethod;
  loa: Loa;
}

export interface IssueUsersToActivationRequestsInput {
  userId: string;
  activationRequestId: string;
}

export interface PhoneNumberInput {
  id?: string | null;
  value: string;
  use?: PhoneNumberUse | null;
  canBeUsedForSmsCodes?: boolean | null;
}

export interface UpdateUserInput {
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  tfNumber?: string | null;
  username?: string | null;
  phoneNumbers?: PhoneNumberInput[] | null;
  emails?: EmailInput[] | null;
  password?: string | null;
  role?: OrganizationRole | null;
  addresses?: AddressInput[] | null;
  isSuperAdmin?: boolean | null;
  expiresAt?: any | null;
}

/**
 * Options for the user update operation.
 */
export interface UpdateUserOptionsInput {
  silent?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export interface QueryApiResourceArgs {
  name: string;
}
export interface QueryClientArgs {
  id: string;
}
export interface QueryClientsArgs {
  ids: ((string)[]) ;
}
export interface QueryExportClientsArgs {
  ids: ((string)[]) ;
}
export interface QueryGroupArgs {
  organizationId: string;
  id: string;
}
export interface QueryIdentityResourceArgs {
  name: string;
}
export interface QueryOrganizationArgs {
  id: string;
}
export interface QuerySearchClientsArgs {
  filter: string| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}
export interface QuerySearchUsersArgs {
  organizationId: string| null;
  filter: string| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}
export interface QueryTestUserMergeArgs {
  userId1: string;
  userId2: string;
}
export interface QueryUserArgs {
  id: string;
}
export interface QueryUserByEmailArgs {
  value: string;
  organizationId: string;
}
export interface QueryUserByNationalIdArgs {
  value: string;
  organizationId: string;
}
export interface QueryUserByTfNumberArgs {
  value: string;
  organizationId: string;
}
export interface QueryUserByUsernameArgs {
  value: string;
  organizationId: string;
}
export interface QueryUsersArgs {
  ids: ((string)[]) | null;
}
export interface QueryUsersByKeyArgs {
  key: string;
  organizationId: string;
}
export interface QueryValidatePhoneNumberArgs {
  number: string;
  country: CountryCode| null;
}

export interface ApiResource {
  claims: ((string)[]) ;
  description?: string| null;
  displayName?: string| null;
  enabled: boolean;
  name: string;
  scopes: ((ApiScope)[]) ;
  secrets: ((ApiResourceSecret)[]) ;
}

export interface ApiScope {
  claims: ((string)[]) ;
  description?: string| null;
  displayName?: string| null;
  emphasize: boolean;
  name: string;
  required: boolean;
  showInDiscoveryDocument: boolean;
}

export interface ApiResourceSecret {
  id: number;
}

export interface Client {
  allowedGrantTypes: ((string| null)[]) ;
  allowedIdps: ((Idp)[]) ;
  allowedScopes: ((string)[]) ;
  allowRememberConsent: boolean;
  assumeSkolidExtensionOnChromeOs: boolean;
  autoRedirectAfterLogout: boolean;
  clientId: string;
  clientSecrets: ((ClientSecret| null)[]) ;
  clientUri?: string| null;
  consentLifetime?: number| null;
  contact?: string| null;
  description?: string| null;
  disableBankIdLogin: boolean;
  disableFrejaLogin: boolean;
  disableGoogleLogin: boolean;
  disableIdportenLogin: boolean;
  disableMicrosoftLogin: boolean;
  disableSkolidLogin: boolean;
  displayName?: string| null;
  enabled: boolean;
  id: string;
  includeNotBeforeClaim: boolean;
  loaRequirement: Loa;
  oidcSettings: ClientOidcSettings;
  organization?: Organization| null;
  postLogoutRedirectUris: ((string| null)[]) ;
  properties: ((ClientProperty)[]) ;
  protocolType: string;
  redirectUris: ((string| null)[]) ;
  requireConsent: boolean;
  samlSettings: ClientSamlSettings;
  sessionMaxAge?: number| null;
  userOrganizations?: ((Organization)[]) | null;
  useTfNumberAsNationalIdFallback: boolean;
}

export interface Idp {
  id: string;
}

export interface ClientSecret {
  createdAt: DateTimeScalar;
  id: number;
  value?: string| null;
}

export type DateTimeScalar<T = any> = T;

export interface ClientOidcSettings {
  absoluteRefreshTokenLifetime: number;
  accessTokenLifetime: number;
  accessTokenType?: AccessTokenType| null;
  allowAccessTokensViaBrowser: boolean;
  allowedCorsOrigins: ((string)[]) ;
  allowedGrantTypes: ((string)[]) ;
  allowOfflineAccess: boolean;
  allowPlainTextPkce: boolean;
  alwaysIncludeUserClaimsInIdToken: boolean;
  authorizationCodeLifetime: number;
  autoRedirectAfterLogout: boolean;
  backChannelLogoutSessionRequired: boolean;
  backChannelLogoutUri?: string| null;
  clientSecrets: ((ClientSecret)[]) ;
  frontChannelLogoutSessionRequired: boolean;
  frontChannelLogoutUri?: string| null;
  identityTokenLifetime: number;
  includeNotBeforeClaim: boolean;
  pairWiseSubjectSalt?: string| null;
  postLogoutRedirectUris: ((string)[]) ;
  redirectUris: ((string)[]) ;
  refreshTokenExpiration?: TokenExpiration| null;
  refreshTokenUsage?: TokenUsage| null;
  requireClientSecret: boolean;
  requirePkce: boolean;
  slidingRefreshTokenLifetime: number;
  updateAccessTokenClaimsOnRefresh: boolean;
}

export enum AccessTokenType {
  JWT = "JWT",
  REFERENCE = "REFERENCE",
}

export enum TokenExpiration {
  SLIDING = "SLIDING",
  ABSOLUTE = "ABSOLUTE",
}

export enum TokenUsage {
  RE_USE = "RE_USE",
  ONE_TIME_ONLY = "ONE_TIME_ONLY",
}

export interface Organization {
  clients: ((Client)[]) ;
  country: CountryCode;
  group: Group;
  id: string;
  issueLoa: Loa;
  name: string;
  users: UserConnection;
}
export interface OrganizationUsersArgs {
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
  roles: ((OrganizationRole| null)[]) | null;
}

export interface Group {
  children: GroupsConnection;
  createdAt: DateTimeScalar;
  expiresAt?: DateTimeScalar| null;
  id: string;
  members: GroupMembersConnection;
  name: string;
  organization: Organization;
  parent?: Group| null;
}
export interface GroupMembersArgs {
  filter: string| null;
  roles: ((GroupMemberRole)[]) | null;
  includeDescendants: boolean| null;
  onlyWithRelations: boolean| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}

export interface GroupsConnection {
  nodes: ((Group)[]) ;
  totalCount: number;
}

export interface GroupMembersConnection {
  edges: ((GroupMemberEdge)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface GroupMemberEdge {
  confirmedAt?: DateTimeScalar| null;
  group: Group;
  relations: ((GroupMemberRelationGraphType)[]) ;
  role: GroupMemberRole;
  user: User;
}

export interface GroupMemberRelationGraphType {
  description?: string| null;
  targetUser: User;
}

export interface User {
  addresses: ((UserAddress)[]) ;
  adminIdentities: ((AdminIdentityGraphType)[]) ;
  auditLog: AuditLogConnection;
  biometricKeys: ((BiometricKey)[]) ;
  blocked: boolean;
  devices: ((Device)[]) ;
  emails: ((Email)[]) ;
  expiresAt?: DateTimeScalar| null;
  externalLogins: ((ExternalLogin)[]) ;
  firstName?: string| null;
  forceTwoFactor: boolean;
  groups: UserGroupsConnection;
  hasPassword: boolean;
  icloudTokens: ((ICloudToken)[]) ;
  id: string;
  images: ((UserPhoto)[]) ;
  incomingRelations: IncomingUserRelationConnection;
  issuableByUser: boolean;
  isSuperAdmin: boolean;
  lastActivity?: DateTimeScalar| null;
  lastIssued?: DateTimeScalar| null;
  lastName?: string| null;
  loa: Loa;
  locked: boolean;
  mergedIds: ((MergedId)[]) ;
  name?: string| null;
  nationalId?: string| null;
  organization: Organization;
  phoneNumber: PhoneNumber;
  phoneNumbers: ((PhoneNumber)[]) ;
  relations: UserRelationConnection;
  role: OrganizationRole;
  tfNumber?: string| null;
  username?: string| null;
}
export interface UserAuditLogArgs {
  filter: string| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}
export interface UserGroupsArgs {
  organizationId: string| null;
  onlyWithIssueAccess: boolean| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}
export interface UserIssuableByUserArgs {
  userId: string| null;
}

export interface UserAddress {
  apartmentNumber?: string| null;
  city: string;
  co?: string| null;
  country?: string| null;
  createdAt: DateTimeScalar;
  id: string;
  postalCode: string;
  primary: boolean;
  streetAddress: string;
  type: AddressType;
}

export interface AdminIdentityGraphType {
  groupRole?: GroupMemberRole| null;
  id: string;
  name?: string| null;
  organization: AdminIdentityOrganizationGraphType;
  role: OrganizationRole;
}

export interface AdminIdentityOrganizationGraphType {
  country: CountryCode;
  id: string;
  issueLoa: Loa;
  name: string;
}

export interface AuditLogConnection {
  edges: ((AuditLogEntryEdge)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface AuditLogEntryEdge {
  cursor: string;
  node: AuditLogEntry;
}

export interface AuditLogEntry {
  activity: AuditLogActivity;
  actorName?: string| null;
  actorUserId?: string| null;
  additionalData?: string| null;
  correlationId?: string| null;
  interface: string;
  operation?: string| null;
  timestamp: DateTimeScalar;
}

export enum AuditLogActivity {
  USER_IMPORTED = "USER_IMPORTED",
  USER_AUTO_CREATED = "USER_AUTO_CREATED",
  USER_CREATED_BY_ADMIN = "USER_CREATED_BY_ADMIN",
  PASSWORD_SET = "PASSWORD_SET",
  PASSWORD_CHANGED = "PASSWORD_CHANGED",
  EXTERNAL_LOGIN_REMOVED = "EXTERNAL_LOGIN_REMOVED",
  EXTERNAL_LOGIN_ADDED = "EXTERNAL_LOGIN_ADDED",
  EMAIL_CONFIRMED = "EMAIL_CONFIRMED",
  EMAIL_UPDATED = "EMAIL_UPDATED",
  USERNAME_REQUESTED = "USERNAME_REQUESTED",
  PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED",
  PHONE_NUMBER_ADDED = "PHONE_NUMBER_ADDED",
  PHONE_NUMBER_REMOVED = "PHONE_NUMBER_REMOVED",
  UNSUCCESSFUL_U_2_F_LOGIN = "UNSUCCESSFUL_U_2_F_LOGIN",
  USER_CREATED_VIA_API = "USER_CREATED_VIA_API",
  PASSWORD_RESET = "PASSWORD_RESET",
  SELF_REGISTRATION = "SELF_REGISTRATION",
  EMAIL_REMOVED = "EMAIL_REMOVED",
  EMAIL_ADDED = "EMAIL_ADDED",
  DEVICE_ADDED = "DEVICE_ADDED",
  DEVICE_REMOVED = "DEVICE_REMOVED",
  I_CLOUD_TOKEN_ADDED = "I_CLOUD_TOKEN_ADDED",
  I_CLOUD_TOKEN_REMOVED = "I_CLOUD_TOKEN_REMOVED",
  RELATION_ADDED = "RELATION_ADDED",
  USER_CREATED = "USER_CREATED",
  USER_UPDATED = "USER_UPDATED",
  RELATION_REMOVED = "RELATION_REMOVED",
  UNLOCKED = "UNLOCKED",
  ACTIVATED = "ACTIVATED",
  SET_PRIMARY_EMAIL = "SET_PRIMARY_EMAIL",
  ACCOUNTS_MERGED = "ACCOUNTS_MERGED",
  BIOMETRIC_KEY_ADDED = "BIOMETRIC_KEY_ADDED",
  BIOMETRIC_KEY_REMOVED = "BIOMETRIC_KEY_REMOVED",
  BLOCKED = "BLOCKED",
  ISSUED = "ISSUED",
  SIGN_IN_TICKET_CREATED = "SIGN_IN_TICKET_CREATED",
  ADDRESS_ADDED = "ADDRESS_ADDED",
  ADDRESS_REMOVED = "ADDRESS_REMOVED",
  USER_DELETED = "USER_DELETED",
  AUTHENTICATOR_ADDED = "AUTHENTICATOR_ADDED",
  AUTHENTICATOR_REMOVED = "AUTHENTICATOR_REMOVED",
  CREDENTIALS_REVOKED = "CREDENTIALS_REVOKED",
  FIDO_AUTHENTICATOR_ADDED = "FIDO_AUTHENTICATOR_ADDED",
  FIDO_AUTHENTICATOR_REMOVED = "FIDO_AUTHENTICATOR_REMOVED",
  CLIENT_CERTIFICATE_ADDED = "CLIENT_CERTIFICATE_ADDED",
  CLIENT_CERTIFICATE_REMOVED = "CLIENT_CERTIFICATE_REMOVED",
}

export interface PageInfo {
  endCursor?: string| null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string| null;
}

export interface BiometricKey {
  biometricId: string;
  displayName: string;
  expireAt: DateTimeScalar;
  issuedAt: DateTimeScalar;
  lastUsed?: DateTimeScalar| null;
  method: string;
}

export interface Device {
  deviceId: string;
  lastUsed?: DateTimeScalar| null;
  loa?: Loa| null;
  name: string;
  registeredAt: DateTimeScalar;
}

export interface Email {
  confirmed: boolean;
  createdAt: DateTimeScalar;
  isConfirmed: boolean;
  isPrimary: boolean;
  primary: boolean;
  use?: EmailUse| null;
  value: string;
}

export interface ExternalLogin {
  loginProvider: string;
  providerDisplayName?: string| null;
  providerKey: string;
}

export interface UserGroupsConnection {
  edges: ((UserGroupEdge)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface UserGroupEdge {
  node: Group;
  role: GroupMemberRole;
}

export interface ICloudToken {
  registeredAt: DateTimeScalar;
}

export interface UserPhoto {
  size: number;
  url: string;
}

export interface IncomingUserRelationConnection {
  edges: ((UserRelationEdge)[]) ;
}

export interface UserRelationEdge {
  fromNode?: User| null;
  node?: User| null;
  role?: UserRelationRole| null;
  summary?: string| null;
}

export enum UserRelationRole {
  PARENT = "PARENT",
  CHILD = "CHILD",
  GENERIC = "GENERIC",
  PARTNER = "PARTNER",
}

export interface MergedId {
  createdAt: DateTimeScalar;
  mergedId: string;
}

export interface PhoneNumber {
  canBeUsedForSmsCodes: boolean;
  createdAt: DateTimeScalar;
  id: string;
  use: PhoneNumberUse;
  value: string;
}

export interface UserRelationConnection {
  edges: ((UserRelationEdge)[]) ;
}

export interface UserConnection {
  nodes: ((User)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface ClientProperty {
  key: string;
  value: string;
}

export interface ClientSamlSettings {
  assertionConsumerServiceBinding: string;
  assertionConsumerServiceUrl?: string| null;
  certificates: ((ClientSamlCertificate)[]) ;
  digestAlgorithm: string;
  entityId?: string| null;
  localSigningCertificateThumbprintHint?: string| null;
  mappingRules: ((ClientSamlMappingRule)[]) ;
  nameIdFormat: string;
  signAssertion: boolean;
  signatureAlgorithm: string;
  signSamlResponse: boolean;
  singleLogoutServiceBinding: string;
  singleLogoutServiceResponseUrl?: string| null;
  singleLogoutServiceUrl?: string| null;
  validAssertionConsumerServiceUrls: ((string)[]) ;
  wantAuthnRequestSigned: boolean;
}

export interface ClientSamlCertificate {
  data?: string| null;
  use?: ClientSamlCertificateUse| null;
}

export enum ClientSamlCertificateUse {
  ANY = "ANY",
  ENCRYPTION = "ENCRYPTION",
  SIGNATURE = "SIGNATURE",
}

export interface ClientSamlMappingRule {
  targetAttribute?: string| null;
  type: SamlClientMappingRuleType;
  value?: string| null;
}

export enum SamlClientMappingRuleType {
  CONSTANT = "CONSTANT",
  RENAME = "RENAME",
  CLEAR = "CLEAR",
  COPY = "COPY",
}

export interface IdentityResource {
  claims: ((string)[]) ;
  description?: string| null;
  displayName?: string| null;
  emphasize: boolean;
  enabled: boolean;
  name: string;
  required: boolean;
  showInDiscoveryDocument: boolean;
}

export interface SearchClientsConnection {
  edges: ((SearchClientsEdge)[]) ;
  nodes: ((Client)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface SearchClientsEdge {
  cursor: string;
  node: Client;
}

export interface SearchUsersConnection {
  edges: ((SearchUsersEdge)[]) ;
  nodes: ((User)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface SearchUsersEdge {
  cursor: string;
  node: User;
}

export interface TestUserMergeResult {
  messages: ((UserMergeMessage)[]) ;
}

export interface UserMergeMessage {
  code?: string| null;
  severity?: UserMergeMessageSeverity| null;
}

export enum UserMergeMessageSeverity {
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export interface MutationAddAllowedClientScopesArgs {
  clientId: string;
  scopes: ((string)[]) ;
}
export interface MutationAddEmailsArgs {
  userId: string;
  emails: ((EmailInput)[]) ;
  options: AddEmailsOptionsInput;
}
export interface MutationAddGroupMembersArgs {
  organizationId: string;
  edges: ((GroupMemberEdgeInput)[]) ;
}
export interface MutationBlockUserArgs {
  userId: string;
  reason: string;
  signature: string;
}
export interface MutationConfirmEmailArgs {
  userId: string;
  email: string;
}
export interface MutationCreateAndIssueUserArgs {
  organizationId: string;
  user: CreateUserInput;
  options: CreateAndIssueUserOptionsInput;
  signature: string;
}
export interface MutationCreateApiResourceArgs {
  input: CreateApiResourceInput;
}
export interface MutationCreateClientArgs {
  input: CreateClientInput;
}
export interface MutationCreateClientSecretArgs {
  clientId: string;
}
export interface MutationCreateIdentityResourceArgs {
  input: CreateIdentityResourceInput;
}
export interface MutationCreateOrganizationArgs {
  input: CreateOrganizationInput;
}
export interface MutationCreateUserArgs {
  organizationId: string;
  user: CreateUserInput;
  options: CreateUserOptionsInput;
}
export interface MutationCreateUsersArgs {
  organizationId: string;
  users: ((CreateUserInput)[]) ;
  options: CreateUsersOptionsInput;
}
export interface MutationDeleteApiResourceArgs {
  name: string;
}
export interface MutationDeleteClientArgs {
  id: string;
}
export interface MutationDeleteIdentityResourceArgs {
  name: string;
}
export interface MutationDeleteOrganizationArgs {
  id: string;
}
export interface MutationDeleteUserArgs {
  id: string;
}
export interface MutationDeleteUserByOrganizationAdminArgs {
  id: string;
}
export interface MutationGenerateBlockUserContractArgs {
  userId: string;
  reason: string;
}
export interface MutationGenerateCreateAndIssueUserContractArgs {
  organizationId: string;
  user: CreateUserInput;
  options: CreateAndIssueUserOptionsInput;
}
export interface MutationGenerateImportAndIssueUsersContractArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
  options: ImportAndIssueUsersOptionsInput;
}
export interface MutationGenerateIssueUserContractArgs {
  userId: string;
  options: IssueUserOptionsInput;
}
export interface MutationGenerateIssueUsersContractArgs {
  userIds: ((string)[]) ;
  options: IssueUsersOptionsInput;
}
export interface MutationGenerateSignInTicketArgs {
  userId: string;
}
export interface MutationImportAndIssueUsersArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
  options: ImportAndIssueUsersOptionsInput;
  signatures: ((string)[]) | null;
}
export interface MutationImportClientFromSamlMetadataArgs {
  metadata: string;
  organizationId: string| null;
}
export interface MutationImportClientsArgs {
  data: string;
  options: ImportClientsOptionsInput;
}
export interface MutationImportGroupsArgs {
  organizationId: string;
  groups: ((GroupImportInput)[]) ;
}
export interface MutationImportUsersArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
}
export interface MutationIssueUserArgs {
  userId: string;
  options: IssueUserOptionsInput;
  signature: string;
}
export interface MutationIssueUsersArgs {
  userIds: ((string)[]) ;
  options: IssueUsersOptionsInput;
  signatures: ((string)[]) | null;
}
export interface MutationIssueUsersToActivationRequestsArgs {
  requests: ((IssueUsersToActivationRequestsInput)[]) ;
  options: IssueUsersOptionsInput;
  signatures: ((string)[]) ;
}
export interface MutationIssueUserToActivationRequestArgs {
  userId: string;
  activationRequestId: string;
  options: IssueUserOptionsInput;
  signature: string;
}
export interface MutationMergeUsersArgs {
  userId1: string;
  userId2: string;
}
export interface MutationRemoveClientSecretArgs {
  clientId: string;
  secretId: number;
}
export interface MutationRemoveEmailsArgs {
  userId: string;
  emails: ((string)[]) ;
}
export interface MutationRemoveGroupMembersArgs {
  organizationId: string;
  edges: ((GroupMemberEdgeIdentifierInput)[]) ;
}
export interface MutationTestImportAndIssueUsersArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
  options: ImportAndIssueUsersOptionsInput;
}
export interface MutationUnlockUserArgs {
  id: string;
}
export interface MutationUpdateApiResourceArgs {
  name: string;
  input: UpdateApiResourceInput;
}
export interface MutationUpdateClientArgs {
  id: string;
  input: UpdateClientInput;
}
export interface MutationUpdateIdentityResourceArgs {
  name: string;
  input: UpdateIdentityResourceInput;
}
export interface MutationUpdateOrganizationArgs {
  id: string;
  input: UpdateOrganizationInput;
}
export interface MutationUpdateUserArgs {
  userId: string;
  user: UpdateUserInput;
  options: UpdateUserOptionsInput| null;
}

export interface AddAllowedClientScopesResult {
  dummy?: boolean| null;
}

export interface EmailInput {
  use?: EmailUse| null;
  value: string;
  confirmed: boolean;
}

export interface AddEmailsOptionsInput {
  silent?: boolean| null;
}

export interface GroupMemberEdgeInput {
  userId: string;
  groupId: string;
  role: GroupMemberRole;
  expiresAt?: DateTimeScalar| null;
  confirmedAt?: DateTimeScalar| null;
}

export interface AddGroupMembersResult {
  addedMembers: ((GroupMemberEdge)[]) ;
  dummy?: boolean| null;
}

export interface CreateUserInput {
  firstName?: string| null;
  lastName?: string| null;
  nationalId?: string| null;
  tfNumber?: string| null;
  username?: string| null;
  role?: OrganizationRole| null;
  loa?: Loa| null;
  phoneNumber?: CreateUserPhoneNumberInput| null;
  password?: string| null;
  securityLogData?: string| null;
  emails?: ((EmailInput)[]) | null;
  phoneNumbers?: ((PhoneNumberInput)[]) | null;
  addresses?: ((AddressInput)[]) | null;
  expiresAt?: DateTimeScalar| null;
}

export interface CreateUserPhoneNumberInput {
  value: string;
  confirmed: boolean;
}

export interface PhoneNumberInput {
  id?: string| null;
  value: string;
  use?: PhoneNumberUse| null;
  canBeUsedForSmsCodes?: boolean| null;
}

export interface AddressInput {
  id?: string| null;
  primary?: boolean| null;
  streetAddress: string;
  city: string;
  postalCode: string;
  apartmentNumber?: string| null;
  co?: string| null;
  country?: string| null;
  type: AddressType;
}

export interface CreateAndIssueUserOptionsInput {
  method: IssueMethod;
  loa: Loa;
  silent?: boolean| null;
}

export interface IssueUserResult {
  activationCode?: string| null;
  activationCodeExpiresAt?: DateTimeScalar| null;
  user: User;
}

export interface CreateApiResourceInput {
  name: string;
  displayName: string;
  description?: string| null;
  enabled?: boolean| null;
  claims?: ((string)[]) | null;
  scopes: ((CreateApiResourceScopeInput)[]) ;
  secrets?: ((CreateApiResourceSecretInput)[]) | null;
}

export interface CreateApiResourceScopeInput {
  name: string;
  displayName: string;
  description?: string| null;
  claims: ((string)[]) ;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface CreateApiResourceSecretInput {
  value: string;
}

export interface CreateClientInput {
  clientId?: string| null;
  displayName: string;
  frontChannelLogoutUri?: string| null;
  allowedGrantTypes?: ((string)[]) | null;
  redirectUris?: ((string)[]) | null;
  postLogoutRedirectUris?: ((string)[]) | null;
  autoRedirectAfterLogout?: boolean| null;
  includeNotBeforeClaim?: boolean| null;
  description?: string| null;
  clientUri?: string| null;
  enabled?: boolean| null;
  protocolType?: string| null;
  allowedScopes?: ((string)[]) | null;
  sessionMaxAge?: number| null;
  requireConsent?: boolean| null;
  allowRememberConsent?: boolean| null;
  consentLifetime?: number| null;
  organizationId?: string| null;
  userOrganizationIds?: ((string)[]) | null;
  contact?: string| null;
  properties?: ((CreateClientPropertyInput)[]) | null;
  allowedIdps?: ((string)[]) | null;
  loaRequirement?: Loa| null;
  disableBankIdLogin?: boolean| null;
  disableIdportenLogin?: boolean| null;
  disableSkolidLogin?: boolean| null;
  disableFrejaLogin?: boolean| null;
  disableMicrosoftLogin?: boolean| null;
  disableGoogleLogin?: boolean| null;
  useTfNumberAsNationalIdFallback?: boolean| null;
  assumeSkolidExtensionOnChromeOs?: boolean| null;
  oidcSettings?: CreateClientOidcSettingsInput| null;
  samlSettings?: CreateClientSamlSettingsInput| null;
}

export interface CreateClientPropertyInput {
  key: string;
  value: string;
}

export interface CreateClientOidcSettingsInput {
  allowedGrantTypes?: ((string)[]) | null;
  redirectUris?: ((string)[]) | null;
  postLogoutRedirectUris?: ((string)[]) | null;
  autoRedirectAfterLogout?: boolean| null;
  includeNotBeforeClaim?: boolean| null;
  requireClientSecret?: boolean| null;
  alwaysIncludeUserClaimsInIdToken?: boolean| null;
  requirePkce?: boolean| null;
  allowPlainTextPkce?: boolean| null;
  allowAccessTokensViaBrowser?: boolean| null;
  frontChannelLogoutUri?: string| null;
  frontChannelLogoutSessionRequired?: boolean| null;
  backChannelLogoutUri?: string| null;
  backChannelLogoutSessionRequired?: boolean| null;
  allowOfflineAccess?: boolean| null;
  accessTokenLifetime?: number| null;
  identityTokenLifetime?: number| null;
  authorizationCodeLifetime?: number| null;
  absoluteRefreshTokenLifetime?: number| null;
  refreshTokenUsage?: TokenUsage| null;
  updateAccessTokenClaimsOnRefresh?: boolean| null;
  refreshTokenExpiration?: TokenExpiration| null;
  accessTokenType?: AccessTokenType| null;
  slidingRefreshTokenLifetime?: number| null;
  pairWiseSubjectSalt?: string| null;
  allowedCorsOrigins?: ((string)[]) | null;
  clientSecrets?: ((ClientSecretInput)[]) | null;
}

export interface ClientSecretInput {
  id?: number| null;
  value?: string| null;
}

export interface CreateClientSamlSettingsInput {
  entityId?: string| null;
  assertionConsumerServiceUrl?: string| null;
  assertionConsumerServiceBinding?: string| null;
  signAssertion?: boolean| null;
  signSamlResponse?: boolean| null;
  wantAuthnRequestSigned?: boolean| null;
  validAssertionConsumerServiceUrls?: ((string)[]) | null;
  certificates?: ((ClientSamlCertificateInput)[]) | null;
  mappingRules?: ((ClientSamlMappingRuleInput)[]) | null;
  singleLogoutServiceUrl?: string| null;
  singleLogoutServiceResponseUrl?: string| null;
  singleLogoutServiceBinding?: string| null;
  nameIdFormat?: string| null;
  signatureAlgorithm?: string| null;
  digestAlgorithm?: string| null;
  localSigningCertificateThumbprintHint?: string| null;
}

export interface ClientSamlCertificateInput {
  data?: string| null;
  use?: ClientSamlCertificateUse| null;
}

export interface ClientSamlMappingRuleInput {
  type: SamlClientMappingRuleType;
  targetAttribute?: string| null;
  value?: string| null;
}

export interface CreateClientSecretResponseType {
  errorMessage?: string| null;
  secret?: ClientSecret| null;
}

export interface CreateIdentityResourceInput {
  name: string;
  displayName: string;
  description?: string| null;
  claims: ((string)[]) ;
  enabled?: boolean| null;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface CreateOrganizationInput {
  id?: string| null;
  name: string;
  issueLoa?: Loa| null;
  country?: CountryCode| null;
}

export interface CreateUserOptionsInput {
  sendWelcomeEmail?: boolean| null;
  silent?: boolean| null;
}

export interface CreateUsersOptionsInput {
  silent?: boolean| null;
}

export interface CreateUsersResult {
  dummy?: boolean| null;
  results: ((CreateUserResult)[]) ;
}

export interface CreateUserResult {
  error?: BusinessError| null;
  user?: User| null;
}

export interface BusinessError {
  code: string;
  message: string;
}

export interface Contract {
  data: string;
  text: string;
}

export interface ImportUserInput {
  nationalId?: string| null;
  tfNumber?: string| null;
  nationalIdOrTfNumber?: string| null;
  username?: string| null;
  firstName: string;
  lastName: string;
  phoneNumber?: string| null;
  email?: string| null;
  emails?: ((ImportUserEmailInput)[]) | null;
  password?: string| null;
  expiresAt?: DateTimeScalar| null;
}

export interface ImportUserEmailInput {
  value?: string| null;
}

export interface ImportAndIssueUsersOptionsInput {
  issueMethod: IssueMethod;
  loa: Loa;
}

export interface MultiContract {
  data: ((string)[]) ;
  text: string;
}

export interface IssueUserOptionsInput {
  method: IssueMethod;
  loa: Loa;
}

export interface IssueUsersOptionsInput {
  issueMethod: IssueMethod;
  loa: Loa;
}

export interface ImportAndIssueUsersResult {
  activationCodesExpiresAt?: DateTimeScalar| null;
  dummy?: boolean| null;
  entries: ((ImportAndIssueUserResult)[]) ;
}

export interface ImportAndIssueUserResult {
  activationCode?: string| null;
  activationCodeExpiresAt?: DateTimeScalar| null;
  errors: ((string)[]) ;
  importAction: ImportUserAction;
  importUser: ImportUser;
  user?: User| null;
}

export interface ImportUser {
  email: ImportUserField;
  firstName: ImportUserField;
  lastName: ImportUserField;
  nationalId: ImportUserField;
  password: ImportUserPasswordField;
  phoneNumber: ImportUserField;
  tfNumber: ImportUserField;
  valid: boolean;
}

export interface ImportUserField {
  normalizedValue?: string| null;
  originalValue?: string| null;
  status: ImportUserFieldStatus;
}

export interface ImportUserPasswordField {
  errors: ((string)[]) ;
  status: ImportUserPasswordFieldStatus;
}

export enum ImportUserPasswordFieldStatus {
  NO_IMPORT_PASSWORD = "NO_IMPORT_PASSWORD",
  PASSWORD_IMPORT_NOT_ALLOWED = "PASSWORD_IMPORT_NOT_ALLOWED",
  BAD_PASSWORD = "BAD_PASSWORD",
  VALID = "VALID",
}

export interface ImportClientsOptionsInput {
  generateNewSecrets?: boolean| null;
}

export interface ClientImportResult {
  importedApiResources: ((ApiResource)[]) ;
  importedClients: ((Client)[]) ;
}

export interface GroupImportInput {
  children?: ((GroupChildImportInput)[]) | null;
  members?: ((GroupImportMemberInput)[]) | null;
  expiresAt?: DateTimeScalar| null;
  name?: string| null;
  id: string;
  parentId?: string| null;
}

export interface GroupChildImportInput {
  children?: ((GroupChildImportInput)[]) | null;
  members?: ((GroupImportMemberInput)[]) | null;
  expiresAt?: DateTimeScalar| null;
  name: string;
  id?: string| null;
}

export interface GroupImportMemberInput {
  user?: ImportUserInput| null;
  role: GroupMemberRole;
  expiresAt?: DateTimeScalar| null;
  responsibles?: ((ImportUserInput| null)[]) | null;
}

export interface ImportGroupsResult {
  dummy?: boolean| null;
  importedGroups: ((Group)[]) ;
}

export interface ImportUsersResult {
  dummy?: boolean| null;
  entries: ((ImportUserResult)[]) ;
}

export interface ImportUserResult {
  action: ImportUserAction;
  errors: ((string)[]) ;
  importUser: ImportUser;
  user?: User| null;
}

export interface IssueUsersResult {
  activationCodesExpiresAt?: DateTimeScalar| null;
  entries: ((ImportAndIssueUserResult)[]) ;
}

export interface IssueUsersToActivationRequestsInput {
  userId: string;
  activationRequestId: string;
}

export interface IssueUsersToActivationRequestsResult {
  users: ((User)[]) ;
}

export interface IssueUserToActivationRequestResult {
  user: User;
}

export interface GroupMemberEdgeIdentifierInput {
  userId: string;
  groupId: string;
  role: GroupMemberRole;
}

export interface RemoveGroupMembersResult {
  dummy?: boolean| null;
  removedMembers: ((GroupMemberEdge)[]) ;
}

export interface TestImportAndIssueUsersResult {
  dummy?: boolean| null;
  entries: ((ImportUserResult)[]) ;
}

export interface UpdateApiResourceInput {
  displayName?: string| null;
  description?: string| null;
  enabled?: boolean| null;
  claims?: ((string)[]) | null;
  scopes?: ((UpdateApiResourceScopeInput)[]) | null;
  secrets?: ((UpdateApiResourceSecretInput)[]) | null;
}

export interface UpdateApiResourceScopeInput {
  name: string;
  displayName?: string| null;
  description?: string| null;
  claims?: ((string)[]) | null;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface UpdateApiResourceSecretInput {
  id?: number| null;
  value?: string| null;
}

export interface UpdateClientInput {
  displayName?: string| null;
  description?: string| null;
  clientUri?: string| null;
  enabled?: boolean| null;
  protocolType?: string| null;
  allowedScopes?: ((string)[]) | null;
  sessionMaxAge?: number| null;
  requireConsent?: boolean| null;
  allowRememberConsent?: boolean| null;
  consentLifetime?: number| null;
  organizationId?: string| null;
  userOrganizationIds?: ((string)[]) | null;
  contact?: string| null;
  properties?: ((CreateClientPropertyInput)[]) | null;
  allowedIdps?: ((string)[]) | null;
  loaRequirement?: Loa| null;
  disableBankIdLogin?: boolean| null;
  disableIdportenLogin?: boolean| null;
  disableSkolidLogin?: boolean| null;
  disableFrejaLogin?: boolean| null;
  disableMicrosoftLogin?: boolean| null;
  disableGoogleLogin?: boolean| null;
  useTfNumberAsNationalIdFallback?: boolean| null;
  assumeSkolidExtensionOnChromeOs?: boolean| null;
  oidcSettings?: UpdateClientOidcSettingsInput| null;
  samlSettings?: UpdateClientSamlSettingsInput| null;
}

export interface UpdateClientOidcSettingsInput {
  allowedGrantTypes?: ((string)[]) | null;
  redirectUris?: ((string)[]) | null;
  postLogoutRedirectUris?: ((string)[]) | null;
  autoRedirectAfterLogout?: boolean| null;
  includeNotBeforeClaim?: boolean| null;
  requireClientSecret?: boolean| null;
  alwaysIncludeUserClaimsInIdToken?: boolean| null;
  requirePkce?: boolean| null;
  allowPlainTextPkce?: boolean| null;
  allowAccessTokensViaBrowser?: boolean| null;
  frontChannelLogoutUri?: string| null;
  frontChannelLogoutSessionRequired?: boolean| null;
  backChannelLogoutUri?: string| null;
  backChannelLogoutSessionRequired?: boolean| null;
  allowOfflineAccess?: boolean| null;
  accessTokenLifetime?: number| null;
  identityTokenLifetime?: number| null;
  authorizationCodeLifetime?: number| null;
  absoluteRefreshTokenLifetime?: number| null;
  refreshTokenUsage?: TokenUsage| null;
  updateAccessTokenClaimsOnRefresh?: boolean| null;
  refreshTokenExpiration?: TokenExpiration| null;
  accessTokenType?: AccessTokenType| null;
  slidingRefreshTokenLifetime?: number| null;
  pairWiseSubjectSalt?: string| null;
  allowedCorsOrigins?: ((string)[]) | null;
  clientSecrets?: ((ClientSecretInput)[]) | null;
}

export interface UpdateClientSamlSettingsInput {
  entityId?: string| null;
  assertionConsumerServiceUrl?: string| null;
  assertionConsumerServiceBinding?: string| null;
  signAssertion?: boolean| null;
  signSamlResponse?: boolean| null;
  wantAuthnRequestSigned?: boolean| null;
  validAssertionConsumerServiceUrls?: ((string)[]) | null;
  certificates?: ((ClientSamlCertificateInput)[]) | null;
  mappingRules?: ((ClientSamlMappingRuleInput)[]) | null;
  singleLogoutServiceUrl?: string| null;
  singleLogoutServiceResponseUrl?: string| null;
  singleLogoutServiceBinding?: string| null;
  nameIdFormat?: string| null;
  signatureAlgorithm?: string| null;
  digestAlgorithm?: string| null;
  localSigningCertificateThumbprintHint?: string| null;
}

export interface UpdateIdentityResourceInput {
  displayName?: string| null;
  description?: string| null;
  claims?: ((string)[]) | null;
  enabled?: boolean| null;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface UpdateOrganizationInput {
  name?: string| null;
  issueLoa?: Loa| null;
}

export interface UpdateUserInput {
  firstName?: string| null;
  lastName?: string| null;
  nationalId?: string| null;
  tfNumber?: string| null;
  username?: string| null;
  phoneNumbers?: ((PhoneNumberInput)[]) | null;
  emails?: ((EmailInput)[]) | null;
  password?: string| null;
  role?: OrganizationRole| null;
  addresses?: ((AddressInput)[]) | null;
  isSuperAdmin?: boolean| null;
  expiresAt?: DateTimeScalar| null;
}

export interface UpdateUserOptionsInput {
  silent?: boolean| null;
}

export type DateScalar<T = any> = T;

export type DateTimeOffsetScalar<T = any> = T;

export type DecimalScalar<T = any> = T;

export type MillisecondsScalar<T = any> = T;

export type SecondsScalar<T = any> = T;

