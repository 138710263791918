import { Settings } from "./types/settings";

// Default settings
const defaultSettings: Settings = {
  clientId: "portal",
  localSessionTimeoutInSeconds: 60 * 15,
  skolidEnvironment: "prod",
  demoFeatures: false
};

// Override settings per host
const hostSettings: { [key: string]: Partial<Settings> } = {
  "localhost:3000": {
    skolidEnvironment: "local",
    demoFeatures: true
  },
  
  "localhost:3001": {
    skolidEnvironment: "local",
    demoFeatures: true
  },

  "admin-dev.skolid.se": {
    skolidEnvironment: "dev",
    demoFeatures: true
  },

  "admin-dev.schulid.de": {
    skolidEnvironment: "devde",
    demoFeatures: true
  },

  "admin-staging.skolid.se": {
    skolidEnvironment: "staging"
  },

  "admin-demo.skolid.se": {
    skolidEnvironment: "demo",
    demoFeatures: true
  },

  "admin-demo.skolid.de": {
    skolidEnvironment: "demode",
    demoFeatures: true
  },

  "admin.skolid.se": {
    skolidEnvironment: "prod"
  },

  "admin.schulid.de": {
    skolidEnvironment: "prodde"
  },

  "admin-extacc.skolid.se": {
    skolidEnvironment: "extacc"
  },

  "skolid-extacc-portal.azurewebsites.net": {
    skolidEnvironment: "extacc"
  },
};

export const settings = {
  ...defaultSettings,
  ...(hostSettings[window.location && window.location.host] || {})
};
