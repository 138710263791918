import ApolloClient from "apollo-client";
import * as React from "react";
import { AppContext } from "../../types";
import { FormUser } from "../../types/formUser";
import { isFormUserValid } from "../../Utils/validation";
import { UserForm } from "../Common/UserForm";
import { Trans} from "react-i18next";

interface NewUserEditFormProps {
  appContext: AppContext;
  onClose: () => void;
  client: ApolloClient<any>;
  onUserUpdate: (partialUpdate: Partial<FormUser>) => void;
  onNext: () => void;
  user: FormUser;
}

interface NewUserEditFormState {
  forceShowValidationErrors?: boolean;
}

export class NewUserEditForm extends React.PureComponent<
  NewUserEditFormProps,
  NewUserEditFormState
> {
  private pendingNextClick = false;

  constructor(props: NewUserEditFormProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const props = this.props;
    return (
      <>
        <div className="modal-body">
          <UserForm
            appContext={props.appContext}
            forceShowValidationErrors={this.state.forceShowValidationErrors}
            user={props.user}
            onUpdate={props.onUserUpdate}
          />
        </div>

        <div className="modal-footer d-flex justify-content-between">
          <button className="btn btn-secondary" type="button" onClick={props.onClose}>
            <Trans i18nKey="new.user.edit.form.cancel" />
          </button>
          &nbsp;&nbsp;
          <button className="btn btn-primary" type="button" onClick={this.handleNextClick}>
            <Trans i18nKey="new.user.edit.form.next" />
          </button>
        </div>
      </>
    );
  }

  private handleNextClick = async () => {
    if (this.pendingNextClick) {
      return;
    }

    this.pendingNextClick = true;

    if (!(await isFormUserValid(this.props.user))) {
      this.setState({ forceShowValidationErrors: true });
    } else {
      this.props.onNext();
    }

    this.pendingNextClick = false;
  };
}
